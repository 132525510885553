import { omit } from "lodash";

export const FETCH_QUICK_ACTION_LIBRARIES = 'FETCH_QUICK_ACTION_LIBRARIES';
export const ADD_QUICK_ACTION_LIBRARIES = 'ADD_QUICK_ACTION_LIBRARIES';
export const DELETE_QUICK_ACTION_LIBRARIES = 'DELETE_QUICK_ACTION_LIBRARIES';
export const UPDATE_QUICK_ACTION_LIBRARIES = 'UPDATE_QUICK_ACTION_LIBRARIES';


export const getQuickActionLibraryById = (id) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk
            .getQuickActionLibrary(id)
            .then(quickActionLibrary => {
                return quickActionLibrary;
            }).catch(err => {
                console.log(err, "err");
            })
    }
}

export const fetchQuickActionLibraries = () => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({
            type: FETCH_QUICK_ACTION_LIBRARIES,
            status: "pending"
        });

        return rcsdk
            .getQuickActionLibraries()
            .where({ companyId: getState().company.company.id })
            .then(quickActionLibraries => {
                dispatch({
                    type: FETCH_QUICK_ACTION_LIBRARIES,
                    status: "success",
                    quickActionLibraries: quickActionLibraries
                });
            }).catch(err => {
                dispatch({
                    type: FETCH_QUICK_ACTION_LIBRARIES,
                    status: "error",
                    error: err
                });
            })
    }
}

export const addQuickActionLibrary = (datas) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({
            type: ADD_QUICK_ACTION_LIBRARIES,
            status: "pending"
        });

        let formattedDatas = {
            title: datas?.title || "",
            description: datas?.description || "",
            color: datas.color,
            icon: datas.icon,
            cancelBtnLabel: datas.cancelBtnLabel || "",
            submitBtnLabel: datas.submitBtnLabel || "",
            companyId: getState().company.company.id,
            actions: [
                {
                    "quickActionModelId": datas.action,
                    "integration": getState().integrations.integrations.find(el => el.id === datas.integrationId)?.key || null,
                    "action": datas.key,
                    "type": "QaAction",
                    "data": { ...datas.datas, companyId: getState().company.company.id }
                }
            ]
        };

        return rcsdk.createQuickActionLibrary(formattedDatas).then(resp => {
            dispatch({
                type: ADD_QUICK_ACTION_LIBRARIES,
                status: "success",
                quickActionLibrary: resp
            });
            return resp;
        }).catch(err => {
            dispatch({
                type: ADD_QUICK_ACTION_LIBRARIES,
                status: "error",
                error: err
            });
        });
    }
}

export const updateQuickActionLibrary = (id, datas) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({
            type: UPDATE_QUICK_ACTION_LIBRARIES,
            status: "pending"
        });

        return rcsdk.updateQuickActionLibrary(id, omit(datas, ["companyId", "updatedAt", "createdAt", "id", "isDeleted"])).then(resp => {
            dispatch({
                type: UPDATE_QUICK_ACTION_LIBRARIES,
                status: "success",
                quickActionLibrary: resp
            });
            return resp;
        }).catch(err => {
            dispatch({
                type: UPDATE_QUICK_ACTION_LIBRARIES,
                status: "error",
                error: err
            });
        });
    }
}


export const deleteQuickActionLibrary = (id) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({
            type: DELETE_QUICK_ACTION_LIBRARIES,
            status: "pending"
        });

        return rcsdk
            .deleteQuickActionLibrary(id)
            .then(resp => {
                dispatch({
                    type: DELETE_QUICK_ACTION_LIBRARIES,
                    status: "success",
                    id: id
                });
                return id;
            })
            .catch(err => {
                if (err.error?.response?.status !== 400) {
                    dispatch({
                        type: DELETE_QUICK_ACTION_LIBRARIES,
                        status: "error",
                        error: err
                    });
                } else {
                    dispatch({
                        type: DELETE_QUICK_ACTION_LIBRARIES,
                        status: "success",
                        id: id
                    });
                    return id;
                }
            });
    }
}