import {getSectionsByPageSlug} from "../../../configs/formHelperConfig";

export const FETCH_HELPTEXT = 'FETCH_HELPTEXT';

export const fetchHelpText = (pageSlug, sectionSlug) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getHelpTextBySlug(pageSlug, sectionSlug).then(helpText => {
            return dispatch({
                type: FETCH_HELPTEXT,
                pageSlug: pageSlug,
                sectionSlug: sectionSlug,
                helpText: helpText.strings
            }); 
        }).catch(e => {
        })
    }
}

export const fetchPageHelpText = (pageSlug) => {
    return (dispatch, getState, {rcsdk}) => {
        getSectionsByPageSlug(pageSlug).map(section => {
            dispatch(fetchHelpText(pageSlug, section.slug));
            return null;
        })
    }
}

export const selectHelpTextsByPageSlugAndLocal = (state, pageSlug, local) => {
    if(!state.helpTexts.helpTexts?.[pageSlug]) return null;
    return Object.entries(state.helpTexts.helpTexts?.[pageSlug]).map(([section, contents]) => {
        if(contents?.[local]){
            return {
                for: section,
                content: contents[local]?.content,
                published: contents[local]?.published
            }
        } else {
            return null
        }
    }).filter(el => el);
}