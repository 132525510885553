import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import customizer from "./customizer/"
import auth from "./auth/"
import user from "./user/"
import wp_integrations from "./wp_integrations/"
import integrations from "./integrations/"
import tickets from "./tickets/"
import ticketTags from "./ticketTags/"
import conversations from "./conversations/"
import members from "./members/"
import contacts from "./contacts/"
import contactsDrawer from "./contacts/drawer"
import agents from "./agents/"
import channels from "./channels/"
import lives from "./lives/"
import app from "./app/"
import error from "./error/"
import deck from "./deck/"
import call from "./call/"
import aircall from "./aircall/"
import notifications from "./notifications/"
import assistant from "./assistant/"
import invitations from "./invitations/"
import dashboards from "./dashboards/"
import company from "./company/"
import teams from "./teams/"
import modals from "./modals/"
import toasts from "./toasts/"
import quickActions from "./quickActions/"
import quickActionLibraries from "./quickActionLibraries/"
import collections from "./collections/"
import faqs from "./faqs/"
import webNotifications from "./notifications/webNotifications"
import helpTexts from "./helpTexts/"
import menu from "./menu/"
import collectedDatas from "./collectedDatas/"
import zendesk from "./zendesk/"
import payment from "./payment/"
import rules from "./rules/"
import exports from "./export/"
import h2h from "./h2h/"
import pageTemplates from "./pageTemplates/"
import tags from "./tags/"
import libraries from "./libraries/"

import AircallSignUpReducer from "./aircall/signup"

const rootReducer = (history) => combineReducers({
  customizer: customizer,
  auth: auth,
  user: user,
  wp_integrations: wp_integrations,
  integrations: integrations,
  tickets: tickets,
  ticketTags: ticketTags,
  conversations: conversations,
  members: members,
  contacts: contacts,
  contactsDrawer: contactsDrawer,
  agents: agents,
  channels: channels,
  lives: lives,
  app: app,
  error: error,
  deck: deck,
  call: call,
  aircall: aircall,
  aircallSignUp: AircallSignUpReducer,
  assistant: assistant,
  notifications: notifications,
  webNotifications: webNotifications,
  invitations: invitations,
  dashboards: dashboards,
  company: company,
  teams: teams,
  modals: modals,
  toasts: toasts,
  quickActions: quickActions,
  quickActionLibraries: quickActionLibraries,
  collections: collections,
  faqs: faqs,
  helpTexts: helpTexts,
  menu: menu,
  collectedDatas: collectedDatas,
  tags: tags,
  zendesk: zendesk,
  payment: payment,
  rules: rules,
  exports: exports,
  human2human: h2h,
  pageTemplates: pageTemplates,
  libraries: libraries,
  router: connectRouter(history)
})

export default rootReducer
