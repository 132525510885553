import React from 'react'
import { FormattedMessage, defineMessages } from "react-intl";

export const translations = defineMessages({
    "statusPlaceholder": { id: "exports.status.placeholder", defaultMessage: "select status" },
    "recipientsPlaceholder": { id: "exports.recipients.placeholder", defaultMessage: "add an email" },
    "frequencyPlaceholder": { id: "exports.frequency.placeholder", defaultMessage: "select frequency" },
    "active": { id: "exports.status.active", defaultMessage: "active" },
    "inactive": { id: "exports.status.inactive", defaultMessage: "inactive" },
    "view": { id: "exports.actions.view", defaultMessage: "View" },
    "creationSuccess": { id: "exports.creation.toast.success", defaultMessage: "export configuration successfully created" },
    "creationError": { id: "exports.creation.toast.error", defaultMessage: "Oups... something went wrong while creating the export configuration. Please retry" },
    "updateSuccess": { id: "exports.update.toast.success", defaultMessage: "export configuration successfully updated" },
    "updateError": { id: "exports.update.toast.error", defaultMessage: "Oups... something went wrong while updating the export configuration. Please retry" },
    "deletionSuccess": { id: "exports.delete.toast.success", defaultMessage: "export configuration successfully deleted" },
    "deletionError": { id: "exports.delete.toast.error", defaultMessage: "Oups... something went wrong while deleting the export configuration. Please retry" },
    "instantExportSuccess": { id: "exports.instant.toast.success", defaultMessage: "export successfully send" },
    "instantExportError": { id: "exports.instant.toast.error", defaultMessage: "Oups... something went wrong while sending the export. Please retry" },
});

export const frequencyOptions = [
    {
        value: "daily",
        label: <FormattedMessage id="exportsForm.frequency.daily" defaultMessage="Daily (everyday at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.daily.small" defaultMessage="Daily" />
    },
    {
        value: "weekly",
        label: <FormattedMessage id="exportsForm.frequency.weekly" defaultMessage="Weekly (every monday at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.weekly.small" defaultMessage="Weekly" />
    },
    {
        value: "monthly",
        label: <FormattedMessage id="exportsForm.frequency.monthly" defaultMessage="Monthly (every first day of the month at 7am)" />,
        labelSmall: <FormattedMessage id="exportsForm.frequency.monthly.small" defaultMessage="Monthly" />
    },
]

export const statusOptions = [
    {
        value: "active",
        label: <FormattedMessage id="exportsForm.status.active" defaultMessage="active" />,
        color: "success",
        icon: "check"
    },
    {
        value: "inactive",
        label: <FormattedMessage id="exportsForm.status.inactive" defaultMessage="inactive" />,
        color: "danger",
        icon: "circle-xmark"
    },
]