import React from "react"
import axios from "axios"
import {FormattedMessage} from "react-intl";
import { RPA_URL } from "../../../configs/constants"

export const FETCH_INTEGRATIONS_PENDING = 'FETCH_INTEGRATIONS_PENDING';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_ERROR = 'FETCH_INTEGRATIONS_ERROR';

export const FETCH_INTEGRATION_CONNECTIONS_PENDING = 'FETCH_INTEGRATION_CONNECTIONS_PENDING';
export const FETCH_INTEGRATION_CONNECTIONS_SUCCESS = 'FETCH_INTEGRATION_CONNECTIONS_SUCCESS';
export const FETCH_INTEGRATION_CONNECTIONS_ERROR = 'FETCH_INTEGRATION_CONNECTIONS_ERROR';

export const CREATE_INTEGRATION_CONNECTION = "CREATE_INTEGRATION_CONNECTION";
export const UPDATE_INTEGRATION_CONNECTION = "UPDATE_INTEGRATION_CONNECTION";
export const DELETE_INTEGRATION_CONNECTION = "DELETE_INTEGRATION_CONNECTION";

export const SET_SHOW_INTEGRATION_CTA_MODAL = "SET_SHOW_INTEGRATION_CTA_MODAL";
export const SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL = "SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL";

function fetchIntegrationsPending() {
    return {
        type: FETCH_INTEGRATIONS_PENDING
    }
}

function fetchIntegrationsSuccess(integrations) {
    return {
        type: FETCH_INTEGRATIONS_SUCCESS,
        integrations: integrations
    }
}

function fetchIntegrationsError(error) {
    return {
        type: FETCH_INTEGRATIONS_ERROR,
        error: error
    }
}

export const fetchIntegrations = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchIntegrationsPending());

        return rcsdk.getIntegrations()
        // .include([{
        //     relation: 'quickActions',
        //     scope: { 
        //         where: {
        //             or: [
        //                 {companyId: getState().company.company.id},
        //                 {companyId: "*"}
        //             ]
        //         }
        //     }
        // }])
        // .include([{relation: 'quickActions', scope: { where: {companyId: getState().company.company.id}}}])
        .include([{relation: 'quickActions'}])
        .then(response => {
            dispatch(fetchIntegrationsSuccess(response));
            return dispatch(fetchIntegrationConnections());
        }).catch(err => {
          dispatch(fetchIntegrationsError(err.message));
        })
    }
}


function fetchIntegrationConnectionsSuccess(integrationConnections) {
    return {
        type: FETCH_INTEGRATION_CONNECTIONS_SUCCESS,
        integrationConnections: integrationConnections
    }
}

function fetchIntegrationConnectionsError(error) {
    return {
        type: FETCH_INTEGRATION_CONNECTIONS_ERROR,
        error: error
    }
}

export const fetchIntegrationConnections = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchIntegrationsPending());

        return rcsdk.getIntegrationConnections()
        .then(response => {
          return dispatch(fetchIntegrationConnectionsSuccess(response));
        }).catch(err => {
          dispatch(fetchIntegrationConnectionsError(err.message));
        })
    }
}

export const getIntegrationConfigFromRPA = ({provider}) => {
    return (dispatch, getState, {rcsdk}) => {
        let INFRABASED_RPA_URL = getState().company.companySettings?.value?.infra === "old" ? "https://rpa.reecall.co" : RPA_URL;
        return axios({
            method: "GET",
            url: `${INFRABASED_RPA_URL}/integrations/${provider}`,
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        }).then(resp => {
            return resp.data.integration;
        })
    }
}

export const getIntegrationConfigByIntergrationConnectionId = (integrationConnectionId) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getIntegrationConfigurations()
        .where({"integrationConnectionId": integrationConnectionId})
        .then(response => {
            return response;
        }).catch(err => {
            console.log(err, "err");
        })
    }
}

export const updateIntegrationConfiguration = (id, datas) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateIntegrationConfiguration(id, datas).then(() => {
            dispatch({
                type: "SHOW_INTEGRATION_SUCCESS_TOAST",
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="integrations.toast.updateSettings" defaultMessage="Integration settings updated successfully"/>
                }
            })
        })
    }
}

export const installIntegration = ({provider, integrationConnectionId}) => {
    return (dispatch, getState, {rcsdk}) => {
        let INFRABASED_RPA_URL = getState().company.companySettings?.value?.infra === "old" ? "https://rpa.reecall.co" : RPA_URL;
        return axios({
            method: "POST",
            url: `${INFRABASED_RPA_URL}/integration/${provider}/install`,
            data: { 
                companyId: getState().company.company.id,
                integrationConnectionId: integrationConnectionId
            },
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
    }
}

export const createIntegrationConnection = ({provider, ...rest}) => {
    return (dispatch, getState, {rcsdk}) => {

        let datas = {
            "integrationId": getState().integrations.integrations.find(el => el.key === provider)?.id,
            "companyId": getState().company.company.id,
            "data": {
                ...rest
            }
        }

        if(!datas.integrationId){
            console.error(`integration with key "${provider}" is not in database`);
            return false;
        }

        return rcsdk.createIntegrationConnection(datas)
        .then(response => {

            return dispatch(installIntegration({provider: provider, integrationConnectionId: response.id})).then(resp => {
                dispatch({
                    type: CREATE_INTEGRATION_CONNECTION,
                    integrationConnection: response,
                    successToast: {
                        type: "ADD",
                        message: <FormattedMessage id="integrations.toast.add" defaultMessage="Integration added successfully"/>
                    }
                })

                return {...resp.data, "integrationName": getState().integrations.integrations.find(el => el.key === provider)?.name};
            })

        }).catch(err => {
          dispatch(fetchIntegrationConnectionsError(err.message));
        })
    }
}

export const updateIntegrationConnection = (id, data) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateIntegrationConnection(id, data)
        .then(response => {
            dispatch({
                type: UPDATE_INTEGRATION_CONNECTION,
                integrationConnection: response
            });
        }).catch(err => {
            dispatch(fetchIntegrationConnectionsError(err.message));
        })
    }
}



export const uninstallIntegration = ({provider}) => {
    return (dispatch, getState, {rcsdk}) => {

        let INFRABASED_RPA_URL = getState().company.companySettings?.value?.infra === "old" ? "https://rpa.reecall.co" : RPA_URL;
        let integrationConnections = getState().integrations.integrationConnections;
        let companyId = getState().company.company.id;
        let integrationId = getState().integrations.integrations.find(el => el.key === provider)?.id;
        let integrationConnectionId = integrationConnections.find(el => el.companyId === companyId && el.integrationId === integrationId)?.id;

        if(!integrationId || !integrationConnectionId) return false;

        return axios({
            method: "POST",
            url: `${INFRABASED_RPA_URL}/integration/${provider}/uninstall`,
            data: { 
                companyId: companyId,
                integrationConnectionId: integrationConnectionId
            },
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        }).then(() => {
            return dispatch(deleteIntegrationConnection({provider: provider}));
        })
    }
}


export const deleteIntegrationConnection = ({provider}) => {
    return (dispatch, getState, {rcsdk}) => {
        let integrationConnections = getState().integrations.integrationConnections;
        let companyId = getState().company.company.id;
        let integrationId = getState().integrations.integrations.find(el => el.key === provider)?.id;
        let integrationConnectionId = integrationConnections.find(el => el.companyId === companyId && el.integrationId === integrationId)?.id;

        if(!integrationId || !integrationConnectionId) return false;

        const onSuccess = () => {
            dispatch({
                type: DELETE_INTEGRATION_CONNECTION,
                integrationConnectionId: integrationConnectionId,
                integrationId: integrationId,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="integrations.toast.delete" defaultMessage="Integration deleted successfully"/>
                }
            })
        }
        return rcsdk.deleteIntegrationConnection(integrationConnectionId)
        .then(response => {
            return onSuccess()
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchIntegrationConnectionsError(err.message));
            } else {
                onSuccess()
            }
        })
    }
}