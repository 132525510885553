import React from "react"
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
// import styled from "styled-components"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import { Avatar } from "@reecall/components_library/build"
import GenericToast from "./GenericToast";
import { getInitiales } from '../../../utility/utils';


// const StyledToastContent = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// `;

const ToastAssignMember = ({type, ticketId}) => {
    const currentUser = useSelector(state => state.user.rcuser);

    return <GenericToast
        title={
            <>
                {
                    type === "ticketUnAssigned" && <FormattedMessage 
                        id="toast.ticket.unassigned"
                        defaultMessage="You've been unassigned from a ticket"
                    />
                }
                {
                    type === "ticketAssigned" && <FormattedMessage 
                        id="toast.ticket.assigned"
                        defaultMessage="You've been assigned to a ticket"
                    />
                }
            </>
        }
        renderAvatar={() => {
            return <Avatar 
                content={getInitiales(currentUser)}
                img={false}
            />
        }}
        renderMainButton={() => {
            return <Link  to={`/tickets/${type === "ticketUnAssigned" ? "all" : "my"}/${ticketId}`}>
                <Button
                    color="primary"
                    className="btn-icon"
                >
                    <FontAwesomeIcon icon={["far", "eye"]}/>
                </Button>
            </Link>
        }}
    />
    
    // return <StyledToastContent>
    //     {
    //         type === "ticketUnAssigned" && <FormattedMessage 
    //             id="toast.ticket.unassigned"
    //             defaultMessage="You've been unassigned from a ticket"
    //         />
    //     }
    //     {
    //         type === "ticketAssigned" && <FormattedMessage 
    //             id="toast.ticket.assigned"
    //             defaultMessage="You've been assigned to a ticket"
    //         />
    //     }
    //     <Link  to={`/tickets/${type === "ticketUnAssigned" ? "all" : "my"}/${ticketId}`} className="ml-1">
    //         <Button
    //             color="primary"
    //             outline
    //             className="btn-icon"
    //         >
    //             <FontAwesomeIcon icon={["far", "eye"]}/>
    //         </Button>
    //     </Link>
    // </StyledToastContent>
}

export default ToastAssignMember;