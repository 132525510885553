import {
    CREATE_DECK,
    SET_DECK_CHILDRENS,
    ADD_ACTION,
    CREATE_FOLDER,
    REMOVE_ITEM,
    SET_CURRENT_DECK,
    OPEN_FOLDER,
    SELECT_ITEM,
    UPDATE_ITEM,
    UPDATE_DECK,
    UPDATE_DECK_PENDING,
    DELETE_DECK,
    FETCH_DECKS_PENDING,
    FETCH_DECKS_SUCCESS,
    FETCH_DECKS_ERROR,
    SHOW_DECK_MODAL,
    HIDE_DECK_MODAL,
    SET_ACTION_TEMPLATE,
    SET_DECK_CONTEXT_CREATION_DONE
} from '../../actions/deck';
import { v4 as uuid } from 'uuid';

const initialState = {
    pending: true,
    error: null,
    currentDeckId: null,
    decksChanged: [],
    selectedFolderId: null,
    selectedActionId: null,
    selectedType: null,
    decks: [],
    showModal: false,
    currentActionTemplate: null,
    deckContextCreationDone: false,
    decksLoaded: false,
}

const deckReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_DECK_CONTEXT_CREATION_DONE:
            return {...state, deckContextCreationDone: action.done}

        case FETCH_DECKS_PENDING:
            return { ...state, pending: true }
            
        case FETCH_DECKS_SUCCESS:
            return { ...state, pending: false, decks: [...action.decks], decksLoaded: true}

        case FETCH_DECKS_ERROR:
            return { ...state, pending: false, error: action.error }

        case SET_CURRENT_DECK:
            return {...state, currentDeckId: action.deckId, selectedActionId: null, selectedFolderId: null, selectedType: null}

        case OPEN_FOLDER:
            return {...state, selectedFolderId: action.folderId, selectedActionId: null, selectedType: null}

        case SELECT_ITEM:
            return {...state, selectedActionId: action.actionId, selectedType : action.itemType}
        
        case SET_ACTION_TEMPLATE:
            return {...state, currentActionTemplate: action.action}

        case CREATE_DECK: 
            return {
                ...state,
                decks: [
                    ...state.decks,
                    {
                        ...action.deck
                    }
                ]
            }

        case SET_DECK_CHILDRENS: 
            return {
                ...state,
                decksChanged: state.decksChanged.includes(action.deckId) ? [...state.decksChanged] : [...state.decksChanged, action.deckId],
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ? [...action.children] : [...deck.children]
                        }
                    })
                ]
            }

        case ADD_ACTION:
            return {
                ...state,
                decksChanged: state.decksChanged.includes(action.deckId) ? [...state.decksChanged] : [...state.decksChanged, action.deckId],
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                action.folderId ? [
                                    ...deck.children.map(actionList => {
                                        if(actionList.id === action.folderId){
                                            return {
                                                ...actionList, 
                                                childrens: [
                                                    ...actionList.childrens.slice(0, action.index - 1),
                                                    { ...action.action },
                                                    ...actionList.childrens.slice(action.index - 1)
                                                ]};
                                        } else {
                                            return {...actionList};
                                        }
                                    })
                                ] : 
                                [
                                    ...deck.children.slice(0, action.index),
                                    { ...action.action },
                                    ...deck.children.slice(action.index)
                                ]
                            : [...deck.children]
                        }
                    })
                ]
            }

        case UPDATE_ITEM:
            return {
                ...state,
                decksChanged: state.decksChanged.includes(action.deckId) ? [...state.decksChanged] : [...state.decksChanged, action.deckId],
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                [
                                    ...deck.children.map(actionList => {
                                        if(action.actionId === actionList.id){
                                            return {
                                                ...actionList,
                                                ...action.updatedValues
                                            }
                                        } else if(actionList.itemType === "folder"){
                                            return {
                                                ...actionList,
                                                childrens: [
                                                    ...actionList.childrens.map(child => {
                                                        if(child.id === action.actionId){
                                                            return {
                                                                ...child,
                                                                ...action.updatedValues
                                                            }
                                                        }
                                                        return {...child}
                                                    })
                                                ]
                                            }
                                        }
                                        return {...actionList}
                                    })
                                ]
                            : [...deck.children]
                        }
                    })
                ]
            }

        case UPDATE_DECK_PENDING:
            return {
                ...state,
                decksChanged: [...state.decksChanged.filter(el => el !== action.deckId)],
            }

        case UPDATE_DECK:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return action.deckId === deck.id ? {...action.deck} : {...deck}
                    })
                ]
            } 

        case DELETE_DECK:
            return {
                ...state,
                currentDeckId: null,
                selectedFolderId: null,
                selectedActionId: null,
                decks: [...state.decks.filter(deck => deck.id !== action.deckId)]
            }

        case CREATE_FOLDER:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ? [
                            ...deck.children.slice(0, action.index),
                            {
                                id: `folder-${uuid()}`,
                                itemType: "folder",
                                color: "#84b4ff",
                                icon: "folder",
                                fields : [],
                                childrens: []
                            },
                            ...deck.children.slice(action.index)
                            ] : [...deck.children]
                        }
                    })
                ]
            }

        case REMOVE_ITEM:
            return {
                ...state,
                decksChanged: state.decksChanged.includes(action.deckId) ? [...state.decksChanged] : [...state.decksChanged, action.deckId],
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                [
                                    ...deck.children.map(actionList => {
                                        if(actionList?.childrens){
                                            return {
                                                ...actionList, 
                                                childrens: [
                                                   ...actionList.childrens.filter( child => child.id !== action.actionId && child?.quickActionLibraryId !== action.actionId)
                                                ]};
                                        } else {
                                            return { ...actionList }
                                        }
                                    })
                                ].filter( child => child.id !== action.actionId && child?.quickActionLibraryId !== action.actionId)
                            : [...deck.children]
                        }
                    })
                ]
            }

        case SHOW_DECK_MODAL:
            return {
                ...state,
                showModal: true
            }

        case HIDE_DECK_MODAL:
            return {
                ...state,
                showModal: false
            }
        
        default: 
            return state;
    }
}
export default deckReducer;