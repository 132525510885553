import React from "react";
import { FormattedMessage } from "react-intl";

export const FETCH_AGENTS_PENDING = 'FETCH_AGENTS_PENDING';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_ERROR = 'FETCH_AGENTS_ERROR';

export const FLUSH_AGENTS = "FLUSH_AGENTS";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const SET_SHOW_AGENT_CTA_MODAL = "SET_SHOW_AGENT_CTA_MODAL";

function fetchAgentsPending() {
    return {
        type: FETCH_AGENTS_PENDING
    }
}

function fetchAgentsSuccess(agents) {
    return {
        type: FETCH_AGENTS_SUCCESS,
        agents: agents
    }
}

function fetchAgentsError(error) {
    return {
        type: FETCH_AGENTS_ERROR,
        error: error
    }
}

export const fetchAgents = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchAgentsPending());

        return rcsdk.getAgents().then(agents => {
            let channelsPromises = [];
            agents.map((agent) => {
                let channelPromise = rcsdk.getChannels().where({"agentId": agent.id}).then(data => data);
                channelsPromises.push(channelPromise);
                return null;
            });
            Promise.all([...channelsPromises]).then(channels => {
                agents = agents.map((agent, index) => {
                    return {
                        ...agent,
                        channels: channels[index]
                    }
                })
                dispatch(fetchAgentsSuccess(agents));
            });
        }).catch(err => {
            dispatch(fetchAgentsError(err.message));
        })
    }
}

export const flushAgents = () => {
    return { type: FLUSH_AGENTS }
}



export const updateAgent = (agentId, data) => {

    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: UPDATE_AGENT, status: "pending"});

        return rcsdk.updateAgent(agentId, data)
        .then(response => {
            dispatch(
                {
                    type: UPDATE_AGENT,
                    agent: response,
                    status: "success",
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="agents.toast.update" defaultMessage="Agent updated successfully"/>
                    }
                }
            )
        })
        .catch(err => dispatch({ type: UPDATE_AGENT, status: "error", error: err }))
    }
}


export const selectAgentWithChannels = state => state.agents.agents.filter(el => el.channels.length > 0);
