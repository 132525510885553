import {
    FETCH_UPCOMING,
    FETCH_BILLING,
    FETCH_INVOICES,
    FETCH_SUBSCRIPTION,
    SET_STRIPE_SECRET,
    FETCH_PAYMENT_METHOD_LIST,
    SET_PAYMENT_METHOD_AS_DEFAULT,
    UPDATE_PAYMENT_METHOD
} from '../../actions/payment';

const initialState = {
    upcoming: null,
    upcomingStatus: null,
    upcomingError: null,
    billing: null,
    billingStatus: null,
    billingError: null,
    invoices: [],
    invoicesStatus: null,
    invoicesError: null,
    subscription: null,
    subscriptionStatus: null,
    subscriptionError: null,
    stripe_secret: null,
    paymentsMethods: [],
    paymentsMethodsStatus: null,
    paymentsMethodsError: null,
}

const paymentReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_UPCOMING:
            return {
                ...state,
                upcomingStatus: action.status,
                upcomingError: action.status === "error" ? action.error : null,
                upcoming: action.status === "success" ? action.upcoming : state.upcoming
            }

        case FETCH_BILLING:
            return {
                ...state,
                billingStatus: action.status,
                billingError: action.status === "error" ? action.error : null,
                billing: action.status === "success" ? action.billing : state.billing
            }

        case FETCH_INVOICES:
            return {
                ...state,
                invoicesStatus: action.status,
                invoicesError: action.status === "error" ? action.error : null,
                invoices: action.status === "success" ? action.invoices : state.invoices
            }
    
        case FETCH_SUBSCRIPTION:
            return {
                ...state,
                subscriptionStatus: action.status,
                subscriptionError: action.status === "error" ? action.error : null,
                subscription: action.status === "success" ? action.subscription : state.subscription
            }

        case FETCH_PAYMENT_METHOD_LIST:
            return {
                ...state,
                paymentsMethodsStatus: action.status,
                paymentsMethodsError: action.status === "error" ? action.error : null,
                paymentsMethods: action.status === "success" ?  action.paymentsMethods || [] : state.paymentsMethods
            }

        case SET_PAYMENT_METHOD_AS_DEFAULT:
            return {
                ...state,
                paymentsMethods: action.status === "success" ?  
                    state.paymentsMethods.map(paymentMethod => {
                        return {
                            ...paymentMethod,
                            default: action.id === paymentMethod.id
                        }
                    })
                :
                    state.paymentsMethods
            }

        case UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                paymentsMethods: 
                    action.status === "success" ? 
                        [
                            ...state.paymentsMethods.map(elt => {
                                console.log(elt, "elt");
                                console.log(action.paymentMethod, "action.paymentMethod");
                                if(elt.id === action.paymentMethod.id){
                                    return {...elt, ...action.paymentMethod}
                                } else {
                                    return elt
                                }
                            })
                        ]
                    :
                        state.paymentsMethods 
            }

        case SET_STRIPE_SECRET:
            return {
                ...state,
                stripe_secret: action.secret
            }
        
        default: 
            return state;
    }
}


export default paymentReducer;