import {
    TOGGLE_CHANNEL_MODAL,
    TOGGLE_MAIN_MENU
} from '../../actions/menu';

const initialState = {
    showChannelsModal: false,
    mainMenuOpen: false
}

const menuReducer = (state = initialState, action) => {
    switch(action.type) {

        case TOGGLE_CHANNEL_MODAL:
            return {
                ...state,
                showChannelsModal: action?.show ? action.show : !state.showChannelsModal
            }

        case TOGGLE_MAIN_MENU:
            return {
                ...state,
                mainMenuOpen: action?.show ?? !state.mainMenuOpen
            }
            
        default: 
            return state;
    }
}
export default menuReducer;