import {
    FETCH_CONVERSATIONS,
    FLUSH_CONVERSATIONS,
    FETCH_CONVERSATION_LIST_TOTAL,
    SET_NAVBAR_CONVERSATION_TOTAL,
    TOGGLE_CONVERSATION_EVENTS,
    HIDE_CONVERSATION_DRAWER,
    SHOW_CONVERSATION_DRAWER,
    LINK_TICKET,
    UPDATE_CONVERSATION,
    UPDATE_CONVERSATION_COLLECTED_DATAS,
    UPDATE_CONVERSATION_TAGS,
    FETCH_CONVERSATION,
    UNSET_CURRENT_CONVERSATION,
    SET_SEARCH_TERM,
    SET_FILTERS,
    INCREMENT_UNFETCHED_CONVERSATION_TOTAL,
    REMOVE_CONVERSATION_FROM_LIST,
    RELOAD_LIST,
    SET_VIEW_TYPE
} from '../../actions/conversations';
import { UPDATE_CONTACT } from "../../actions/contacts";
import _ from "lodash";

const initialState = {
    newConversationTotal: 0,
    showEvents: false,
    showDrawer: false,
    searchTerm: "",
    filters: [],
    list: {
        conversations: [],
        status: "pending",
        error: null,
        totals: {
            missed: "pending",
            unmissed: "pending"
        }
    },
    table: {
        conversations: [],
        status: "pending",
        error: null,
    },
    pendingCurrent: "pending",
    current: null,
    totalNavBar: null,
    forceReload: false,
    viewType: "list"
}

const conversationsReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_VIEW_TYPE:
            return {
                ...state,
                viewType: action.viewType
            }

        case RELOAD_LIST:
            return {
                ...state,
                forceReload: true,
                newConversationTotal: 0
            }

        case FETCH_CONVERSATIONS:
            return Object.assign(
                {
                    ...state,
                    total: action.total,
                    forceReload: false
                },
                action.view === "list" ? {
                    list: {
                        ...state.list,
                        status: action.status,
                        error: action.status === "error" ? action.error : null,
                        conversations: action.status === "success" ? [...state.list.conversations, ...action.conversations] : [...state.list.conversations],
                    }
                } : null,
                action.view === "table" ? {
                    table: {
                        ...state.table,
                        status: action.status,
                        error: action.status === "error" ? action.error : null,
                        conversations: action.status === "success" ? [...action.conversations] : []
                    }
                } : null
            )

        case FLUSH_CONVERSATIONS:
            return Object.assign(
                {
                    ...state,
                    total: null
                },
                !action?.view ? {
                    list: {
                        ...state.list,
                        conversations: []
                    },
                    table: {
                        ...state.table,
                        conversations: []
                    }
                } : null,
                action.view === "list" ? {
                    list: {
                        ...state.list,
                        conversations: []
                    }
                } : null,
                action.view === "table" ? {
                    table: {
                        ...state.table,
                        conversations: []
                    }
                } : null,
            )
        

        case FETCH_CONVERSATION_LIST_TOTAL:
            if(action?.pending){
                return {
                    ...state,
                    list: {
                        ...state.list,
                        totals: {missed: "pending", unmissed: "pending"}
                    }
                }
            } else {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        totals: {
                            missed: action.missed,
                            unmissed: action.notMissed
                        }
                    },
                }
            }
   
        case SET_NAVBAR_CONVERSATION_TOTAL:
            return {
                ...state,
                totalNavBar: action.total
            }

        case FETCH_CONVERSATION:
            return {
                ...state,
                pendingCurrent: action.status,
                current: action.status === "success" ? action.conversation : null
            }

        case UPDATE_CONVERSATION:
            return {
                ...state,
                current: action.conversation.id === state.current?.id ? {...state.current, ...action.conversation} : {...state.current},
                list: {
                    ...state.list,
                    conversations: [
                        ...state.list.conversations.map(conv => {
                            return conv.id === action.conversation.id ? {
                                ...conv,
                                ..._.omit(action.conversation, ["isDelete", "createdAt", "updatedAt", "id"])
                            } : {...conv}
                        })
                    ],
                },
                table: {
                    ...state.table,
                    conversations: [
                        ...state.table.conversations.map(conv => {
                            return conv.id === action.conversation.id ? {
                                ...conv,
                                ..._.omit(action.conversation, ["isDelete", "createdAt", "updatedAt", "id"])
                            } : {...conv}
                        })
                    ],
                }
            }
        
        case UPDATE_CONVERSATION_TAGS:
            const updateConversationTags = (action, conversations) => {
                return conversations.filter(el => el).map(conversation => {
                    return {
                        ...conversation,
                        tagIds: conversation.id === action.id ? action.tagIds : conversation?.tagIds || []
                    }
                })
            }
            return {
                ...state,
                current: updateConversationTags(action, [state.current])?.[0] || null,
                list:{
                    ...state.list,
                    conversations: [...updateConversationTags(action, state.list.conversations)]
                },
                table:{
                    ...state.table,
                    conversations: [...updateConversationTags(action, state.table.conversations)]
                }
            }

        case UPDATE_CONTACT:
            return {
                ...state,
                current: state.current?.contactId === action.contact.id ? {
                    ...state.current,
                    contact: {
                        ...state.contact,
                        ...action.contact
                    }
                } : {...state.current}
            }
        
        case UPDATE_CONVERSATION_COLLECTED_DATAS:
            return {
                ...state,
                current: state.current?.id === action.id ? {
                    ...state.current,
                    collectedData: [...action.datas]
                } : {...state.current}
            } 
        
        case INCREMENT_UNFETCHED_CONVERSATION_TOTAL:
            return {
                ...state,
                newConversationTotal: state.newConversationTotal + 1
            }

        case SET_FILTERS: 
            return {
                ...state,
                filters: action?.filters
            }

        case SET_SEARCH_TERM: 
            return {
                ...state,
                searchTerm: action?.searchTerm
            }

        case UNSET_CURRENT_CONVERSATION:
            return {
                ...state,
                current: null
            }

        case LINK_TICKET:
            return {
                ...state,
                current: {...state.current, ticketsId: [...state.current.ticketsId, action.ticket.id], tickets: [...state.current.tickets, action.ticket]}
            }
            
        case TOGGLE_CONVERSATION_EVENTS:
            return {
                ...state,
                showEvents: !state.showEvents
            }

        case HIDE_CONVERSATION_DRAWER:
            return {
                ...state,
                showDrawer: false
            }

        case SHOW_CONVERSATION_DRAWER:
            return {
                ...state,
                showDrawer: true
            }


        case REMOVE_CONVERSATION_FROM_LIST:
            let conversation = state.list.conversations.find(conv => conv.id === action.id);
            return {
                ...state,
                total: state.total - 1,
                list: {
                    ...state.list,
                    conversations: state.list.conversations.filter(conv => conv.id !== action.id),
                    totals: {
                        missed: conversation?.missed ? state.list.totals.missed - 1 : state.list.totals.missed,
                        unmissed: !conversation?.missed ? state.list.totals.unmissed - 1 : state.list.totals.unmissed
                    }
                },
                table: {
                    ...state.table,
                    conversations: state.table.conversations.filter(conv => conv.id !== action.id)
                }
                
            }


        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/conversations\/\w+$/;
            if(action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)){
                return {
                    ...state,
                    current: null,
                    forceReload: true,
                    searchTerm: "",
                    newConversationTotal: 0
                }
            } else {
                return {
                    ...state,
                    forceReload: false
                }
            }

            
        default: 
            return state;
    }
}
export default conversationsReducer;