import React from 'react';
import styled from 'styled-components';

const StyledGenericToast = styled.div`

    .toast-wrapper{
        background-color: #fff;
        border-radius: 6px;
    }

    .toast-header-wrapper{
        display: flex;
        align-items: center;
        padding: 0.25rem 0;

        .toast-icon-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-right: 1rem;

            .avatar{
                margin: 0;
            }
        }
    }

    .toast-header{
        display: flex;
        align-items: center;
        padding: 0;
        padding-bottom: 0rem;
        border-bottom: 0;
        
        // padding-bottom: 0.35rem;

        .toast-header-infos{
            display: flex;
            flex-direction: column;

            .toast-title{
                line-height: normal;
                color: var(--toast-color);
            }

            .toast-subtitle{
                margin-top: 0.25rem;
                line-height: normal;
            }
        }

        .toast-mainAction{
            margin-left: 1rem;
        }
    }

    .toast-content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        flex: 1;
        color: #323b44;

        .toast-content{
            margin-top: 0.35rem;
            line-height: normal;
            width: 80%;

            & > p:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const GenericToast = ({title, subTitle, renderContent, renderAvatar, renderMainButton, ...rest }) => {
    return <StyledGenericToast {...rest}>
        <div className="toast-wrapper">
            <div className="toast-content-wrapper">
                <div className="toast-header-wrapper">
                    {renderAvatar && <div className="toast-icon-wrapper">
                        {renderAvatar()}
                    </div>}
                    <div className="toast-header">
                        <div className="toast-header-infos">
                            {title && <span className="toast-title text-bold-500 font-medium-1">{title}</span>}
                            {subTitle && <span className="toast-subtitle text-muted font-small-2">{subTitle}</span>}
                        </div>
                        {renderMainButton && <div className="toast-mainAction">
                            {renderMainButton()}
                        </div>}
                    </div>
                </div>
                {renderContent && <div className="toast-content font-small-3">
                    {renderContent()}
                </div>}
            </div>
        </div>
    </StyledGenericToast>
};

GenericToast.defaultProps = {
    title: "",
    subTitle: "",
    renderContent: null,
    renderAvatar: null,
    renderMainButton: null
}

export default GenericToast; 