import React from "react"
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import FrameLayout from "../../layouts/FrameLayout"
import themeConfig from "../../configs/themeConfig"

import zafClient from '../../ZendeskAppFrameworkClient'

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  frame: FrameLayout
}

const ContextLayout = React.createContext()

class Layout extends React.Component {
  state = {
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction,
    hideui: false
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleWindowResize = () => {
    this.updateWidth()
  }

  componentDidMount = () => {
    if (window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize)
    }
    let queryParams = new URLSearchParams(window.location.search);
    if(queryParams.has("hideui") || (window.location.pathname.toString().startsWith("/zendesk") && zafClient)){
      this.setState({
        hideui: true
      })
    }

    this.handleDirUpdate()
  }

  componentDidUpdate() {
    this.handleDirUpdate()
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === "rtl")
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
  }



  render() {
    const { children } = this.props
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts["full"],
          frameLayout: layouts["frame"],
          VerticalLayout: layouts["vertical"],
          switchDir: dir => {
            this.setState({ direction: dir })
          }
        }}
      >
        {children}
      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
