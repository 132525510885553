import React from "react";
import { FormattedMessage } from "react-intl";
import { getRequestWhere } from "../../../utility/utils"
import moment from 'moment';

export const FETCH_CONVERSATIONS            = 'FETCH_CONVERSATIONS';
export const FLUSH_CONVERSATIONS            = 'FLUSH_CONVERSATIONS';
export const FETCH_CONVERSATION_LIST_TOTAL  = 'FETCH_CONVERSATION_LIST_TOTAL';
export const SET_NAVBAR_CONVERSATION_TOTAL  = 'SET_NAVBAR_CONVERSATION_TOTAL';
export const INCREMENT_UNFETCHED_CONVERSATION_TOTAL  = 'INCREMENT_UNFETCHED_CONVERSATION_TOTAL';

export const TOGGLE_CONVERSATION_EVENTS = "TOGGLE_CONVERSATION_EVENTS";

export const HIDE_CONVERSATION_DRAWER   = 'HIDE_CONVERSATION_DRAWER';
export const SHOW_CONVERSATION_DRAWER   = "SHOW_CONVERSATION_DRAWER";

export const SET_SEARCH_TERM            = 'SET_SEARCH_TERM';
export const SET_FILTERS                = 'SET_FILTERS';

export const LINK_TICKET                = 'LINK_TICKET';
export const RELOAD_LIST                = "RELOAD_LIST";

export const FETCH_CONVERSATION                     = 'FETCH_CONVERSATION';
export const UNSET_CURRENT_CONVERSATION             = 'UNSET_CURRENT_CONVERSATION';

export const REMOVE_CONVERSATION_FROM_LIST          = 'REMOVE_CONVERSATION_FROM_LIST';
export const UPDATE_CONVERSATION                    = 'UPDATE_CONVERSATION';
export const UPDATE_CONVERSATION_COLLECTED_DATAS    = 'UPDATE_CONVERSATION_COLLECTED_DATAS';
export const UPDATE_CONVERSATION_TAGS                     = 'UPDATE_CONVERSATION_TAGS';

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE_CONVERSATION';


export const hideConversationDrawer = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: HIDE_CONVERSATION_DRAWER});
    }
}

export const showConversationDrawer = (conversationId = null) => {
    return (dispatch, getState, {rcsdk}) => {
        if(conversationId){
            //Open Drawer conversation
            dispatch({ type: SHOW_CONVERSATION_DRAWER });
            dispatch(fetchConversation(conversationId));
        } else {
            dispatch({type: SHOW_CONVERSATION_DRAWER});
        }
    }
}

export const setSearchTerm = (searchTerm) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: SET_SEARCH_TERM,
            searchTerm: searchTerm || ""
        })
    }
}

export const setFilters = (filters) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: SET_FILTERS,
            filters: filters || []
        })
    }
}

export const unsetCurrentConversation = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({ type: UNSET_CURRENT_CONVERSATION })
    }
}

export const fetchConversations = ({
    limit,
    skip = 0,
    flush = false,
    order = "createdAt DESC",
    filters = {},
    where = {},
    searchTerm = "",
    view = "list"
}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        if(flush){
            dispatch(flushConversations({view: view}));
        }
        dispatch({type: FETCH_CONVERSATIONS, view: view, status: "pending"})

        let isSearch = searchTerm !== "";
        let loopbackFilters = getRequestWhere({
            where,
            companyId: getState().company.company.id,
            filters,
            toMongo: isSearch
        });

        let request;
        if(isSearch) {
            request = `atlasSearchConversations`;
        } else {
            request = `getConversations`;
        }

        let requestWhere = loopbackFilters?.length > 0 ? {[isSearch ? "$and" : "and"]: [
            ...loopbackFilters
        ]} : {}

        console.groupCollapsed(`RCSDK conversations (${request})`);
        console.log(searchTerm, "searchTerm");
        console.log(where, "where");
        console.log(filters, "filters");
        console.log(order, "order");
        console.log(limit, "limit");
        console.log(skip, "skip");
        console.log(requestWhere, "requestWhere");
        console.groupEnd();

        return rcsdk[request](searchTerm)
        .include(["contact"])
        .skip(skip)
        .limit(limit)
        .raw()
        .where(requestWhere)
        .order(order)
        .then(resp => {
            const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
            dispatch({
                type: FETCH_CONVERSATIONS,
                status: "success",
                conversations: resp.data,
                total: total,
                view: view
            })
            
            return {data: resp.data, total: total, totalRequest: resp.data.length}
        }).catch(err => {
            dispatch({type: FETCH_CONVERSATIONS, view: view, status: "error", err: err})
        });
    }
}

export function flushConversations({view = "list"}) {
    return {
        type: FLUSH_CONVERSATIONS,
        view: view
    }
}

export const linkTicketToConversation = (conversationId, ticketId) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.setConversationTicket(conversationId, ticketId).then(conversationResponse => {
            dispatch({
                type: UPDATE_CONVERSATION,
                conversation: conversationResponse
            })
        }).catch(err => {
        })
    }
}


export const fetchConversationListTotals = ({filters, where, searchTerm}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_CONVERSATION_LIST_TOTAL,
            pending: true 
        })

        let isSearch = searchTerm !== "";
        let loopbackFilters = getRequestWhere({
            where,
            companyId: getState().company.company.id,
            filters,
            toMongo: isSearch
        });

        let request;
        if(isSearch) {
            request = `atlasSearchConversations`;
        } else {
            request = `getConversations`;
        }

        let requestWhereMissed = {[isSearch ? "$and" : "and"]: [...loopbackFilters, {missed: {[isSearch ? "$eq" : "eq"]: true}}]}
        let requestWhereNotMissed = {[isSearch ? "$and" : "and"]: [...loopbackFilters, {missed: {[isSearch ? "$ne" : "neq"]: true}}]}

        let promiseCountMissed = rcsdk[request](searchTerm).raw().where(requestWhereMissed)
        let promiseCountNotMissed = rcsdk[request](searchTerm).raw().where(requestWhereNotMissed)

        Promise.all([promiseCountMissed, promiseCountNotMissed]).then(resp => {
            const totalMissed = parseInt(resp[0].headers["content-range"].substr(resp[0].headers["content-range"].indexOf("/") + 1));
            const totalNotMissed = parseInt(resp[1].headers["content-range"].substr(resp[1].headers["content-range"].indexOf("/") + 1));
            dispatch({
                type: FETCH_CONVERSATION_LIST_TOTAL,
                missed: totalMissed,
                notMissed: totalNotMissed
            })
        }); 
    }
}

export const fetchConversation = (id, isCallSid = false) => {
  return (dispatch, getStat, {rcsdk}) => {
    dispatch({type: FETCH_CONVERSATION, status: "pending"})
    if(isCallSid){
        return rcsdk.getConversations()
        .where({callSid: id})
        .include(["contact"])
        .then((conversations) => {
            if(conversations[0]){
                dispatch({type: FETCH_CONVERSATION, status: "success", conversation: conversations[0]})
                return conversations[0];
            } else {
                dispatch({type: FETCH_CONVERSATION, status: "error"})
            }
        }).catch(err => {
            dispatch({type: FETCH_CONVERSATION, status: "error"})
        })
    } else {
        return rcsdk.getConversation(id)
        .include(["contact"])
        .then((conversation) => {
            dispatch({type: FETCH_CONVERSATION, status: "success", conversation: conversation})
            return conversation;
        }).catch(err => {
            dispatch({type: FETCH_CONVERSATION, status: "error"})
        })
    }
  }
}

export const updateConversation = ({id, datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateConversation(id, datas)
        .then((conversation) => {
            const dispatchUpdate = (formattedConversation) => {
                dispatch({
                    type: UPDATE_CONVERSATION,
                    conversation: formattedConversation,
                    successToast: showToast ? {
                        type: "UPDATE",
                        message: <FormattedMessage id="conversations.toast.update" defaultMessage="Conversation updated successfully"/>
                    } : null
                })
                return formattedConversation;
            }

            if(conversation.contactId){
                rcsdk.getContact(conversation.contactId).then(contact => {
                    dispatchUpdate({...conversation, contact: contact});
                })
            } else {
                dispatchUpdate({...conversation, contact: null});
            }
        }).catch(err => {
            console.log(err, "err");
        });
    }
}

export const updateConversationCollectedDatas = (id, datas) => {
    return (dispatch, getState, {rcsdk}) => {

        return rcsdk.editConversationData(id, datas).then((resp) => {
            dispatch({
                type: UPDATE_CONVERSATION_COLLECTED_DATAS,
                id: id,
                datas: resp.collectedData
            })
        });
    }
}

export const updateConversationTags = ({conversationId, tagIds, showToast = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateConversation(conversationId, {tagIds: tagIds}).then(response => {
            return dispatch({
                type: UPDATE_CONVERSATION_TAGS,
                id: conversationId,
                tagIds: response?.tagIds || [],
                successToast: showToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="conversation.toast.addTags" defaultMessage="Conversation's tags updated successfully"/>
                } : null
            })
        }).catch(err => {
            dispatch({type: "ERROR_UPDATE_CONVERSATION_TAGS", error: `An error occured: ${err.message}`});
        });
    }
}

export const fetchNavBarConversationTotal = () => {
    return (dispatch, getState, {rcsdk}) => {
        rcsdk.Stats.getCount("Conversation")
        .where({
            missed: true,
            createdAt: {$gte: `date:${moment().startOf("day").format()}`}
        })
        .then(resp => {
            dispatch({type: SET_NAVBAR_CONVERSATION_TOTAL, total: resp.count});
        })
    }
}

export const updateConversationInApp = ({conversationId}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getConversation(conversationId).include(["contact"]).then((conversation) => {
            dispatch({
                type: UPDATE_CONVERSATION,
                conversation: conversation
            });
        }).catch(() => {})
    }
}

export const incrementUnfetchedConversationTotal = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: INCREMENT_UNFETCHED_CONVERSATION_TOTAL});
    }
}

export const removeConversationFromList = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: REMOVE_CONVERSATION_FROM_LIST, id: id});
    }
}

export const toggleConversationEvents = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: TOGGLE_CONVERSATION_EVENTS});
    }
}


export const getConversations = ({
    limit,
    skip,
    searchTerm,
    order = "createdAt DESC",
    contactId = null,
}) => {
    return (dispatch, getState, {rcsdk}) => {
        let where = {
            companyId: getState().company.company.id
        }

        if(contactId){
            where["contactId"] = { "eq" : contactId };
        }

        if(searchTerm){
            where["title"] = {"like": `.*${searchTerm}.*`, "options": "i"};
        }

        // console.log({"where": where, "order": order}, "getConversations");
        return rcsdk.getConversations()
        .include(["contact", "ticket"])
        .skip(skip)
        .limit(limit)
        .raw()
        .where(where)
        .order(order)
        .then((resp) => {
            const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
            return {data: resp.data, total: total}
        })
        .catch(err => {})
    }
}

export const addEventToConversation = ({conversationId, event}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {

        return rcRPA.executeAction({ 
            config: {
                "integration": "reecall",
                "action": "addConversationEvent",
                "type": "QaAction",
                "datas": {
                    "companyId": getState().company.company.id,
                    "conversationId": conversationId,
                    "event":{
                        "type":"event",
                        "data": event,
                        "createdAt": (new Date()).toISOString()
                    }
                  }
            }
        })
    }
}