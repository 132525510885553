import React from "react";
import { FormattedMessage } from "react-intl"
import {omit} from "lodash";
import axios from "axios";
import { COLLECTION_URL } from "../../../configs/constants"
import { RPAHook_AfterImport } from "../import/index" 

export const FETCH_COLLECTIONS          = 'FETCH_COLLECTIONS';
export const ADD_COLLECTION             = 'ADD_COLLECTION';
export const UPDATE_COLLECTION          = 'UPDATE_COLLECTION';
export const DELETE_COLLECTION          = 'DELETE_COLLECTION';

export const FETCH_COLLECTION_DATA       = 'FETCH_COLLECTION_DATA';
export const ADD_COLLECTION_DATA       = 'ADD_COLLECTION_DATA';
export const UPDATE_COLLECTION_DATA    = 'UPDATE_COLLECTION_DATA';
export const DELETE_COLLECTION_DATA     = 'DELETE_COLLECTION_DATA';
export const SET_COLLECTION_DATA_STATUS     = 'SET_COLLECTION_DATA_STATUS';

export const fetchCollections = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_COLLECTIONS,
            status: "pending"
        });

        return rcsdk
            .getCollections()
            .then(collections => {
                dispatch({
                    type: FETCH_COLLECTIONS,
                    status: "success",
                    collections: collections
                });
        }).catch(err => {
            dispatch({
                type: FETCH_COLLECTIONS,
                status: "error",
                error: err
            });
        })
    }
}

// export const fetchCollectionDatas = (collectionId) => {
//     return (dispatch, getState, {rcsdk}) => {
//         return rcsdk.getCollectionDatas()
//         .where({"collectionId": collectionId})
//         .then((collectionDatas) => {
//             return collectionDatas;
//         })
//         .catch(err => {})
//     }
// }


export const fetchCollectionDatas = (collectionId, filterRules = []) => {
    return (dispatch, getState, {rcsdk}) => {

        dispatch({
            type: FETCH_COLLECTION_DATA,
            status: "pending"
        });

        return axios.post(`${COLLECTION_URL}/collections/fetch/`, {
            collectionsIds: [collectionId],
            rules: filterRules
        }, {
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
        .then(resp => {
            dispatch({
                type: FETCH_COLLECTION_DATA,
                status: "success",
                collectionDatas: resp.data.results
            });
            return resp.data.results;
        })
        .catch((error) => {
            dispatch({
                type: FETCH_COLLECTION_DATA,
                status: "error",
                error: error
            });
        })
    }
}

export const addCollection = ({collection, collectionDatas}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: ADD_COLLECTION,
            status: "pending"
        });

        let formattedCollection = {
            ...collection,
            companyId: getState().company.company.id
        }

        return rcsdk.createCollection(formattedCollection)
        .then((collection) => {

            let promisesCollectionData = [];
            collectionDatas.map(collectionData => {
                let formattedCollectionData = {
                    companyId: getState().company.company.id,
                    collectionId: collection.id,
                    data: collectionData
                }

                let collectionDataPromise = rcsdk.createCollectionData(formattedCollectionData).then(data => data);
                promisesCollectionData.push(collectionDataPromise);
                return null;
            })

            return Promise.all([...promisesCollectionData]).then(collectionData => {
                collection = {
                    ...collection,
                    collectionDatas: collectionData
                }

                dispatch({
                    type: ADD_COLLECTION,
                    status: "success",
                    collection: collection,
                    successToast: {
                        type: "ADD",
                        message: <FormattedMessage id="collection.toast.add" defaultMessage="Collection added successfully"/>
                    }
                });
                return collection;
            });

        }).catch(err => {
            dispatch({
                type: ADD_COLLECTION,
                status: "error",
                error: err
            });
            return err.message;
        });
    }
}

export const updateCollection = ({id, datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({
            type: UPDATE_COLLECTION,
            status: "pending"
        });

        return rcsdk.updateCollection(id, datas)
        .then((response) => {
            dispatch({
                type: UPDATE_COLLECTION,
                status: "success",
                id: id,
                collection: omit(response, ["createdAt", "isDeleted", "updatedAt"]),
                successToast: showToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="collection.toast.update" defaultMessage="Collection updated successfully"/>
                } : null
            });
        }).catch(err => {
            dispatch({
                type: UPDATE_COLLECTION,
                status: "error",
                error: err
            });
        });

    }
}

export const deleteCollection = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: DELETE_COLLECTION,
            status: "pending"
        });

        return rcsdk.deleteCollection(id)
        .then((response) => {
            dispatch({
                type: DELETE_COLLECTION,
                status: "success",
                id: id,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="collection.toast.delete" defaultMessage="Collection deleted successfully"/>
                }
            });
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch({
                    type: DELETE_COLLECTION,
                    status: "error",
                    error: err
                });
            } else {
                dispatch({
                    type: DELETE_COLLECTION,
                    status: "success",
                    id: id,
                    successToast: {
                        type: "DELETE",
                        message: <FormattedMessage id="collection.toast.delete" defaultMessage="Collection deleted successfully"/>
                    }
                });
            }
        })
    }
}

export const exportCollection = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.exportCollection(id).then(resp => {
            return resp;
        }).catch(err => {
            dispatch({
                error: err
            })
        })
    }
}


export const createCollectionData = ({collection, data, showToast = false}) => {
    return (dispatch, getState, {rcsdk}) => {

        return axios.post(`${COLLECTION_URL}/collections/${collection.id}/unit/`, {
            data: data
        }, {
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
        .then(resp => {
            let formattedCollectionData = {
                "data": omit({
                    ...resp.data.result
                }, ["id"]),
                "collectionId": collection.id,
                "companyId": getState().company.company.id,
                "id": resp.data.result.id,
                "createdAt": (new Date()).toISOString()
            }

            dispatch({
                type: ADD_COLLECTION_DATA,
                collectionData: formattedCollectionData,
                successToast: showToast ? {
                    type: "ADD",
                    message: <FormattedMessage id="collectionData.toast.add" defaultMessage="Collection datas added successfully"/>
                } : null
            });
            return formattedCollectionData;
        }).catch(err => {
            dispatch({
                error: err
            })
        })
    }
} 

export const updateCollectionData = ({collection, id, data, showToast = false}) => {
    return (dispatch, getState, {rcsdk}) => {

        return axios.post(`${COLLECTION_URL}/collections/${collection.id}/unit/${id}`, {
            data: data
        }, {
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
        .then(resp => {
            let formattedCollectionData = {
                "data": omit({
                    ...resp.data.result
                }, ["id"]),
                "collectionId": collection.id,
                "companyId": getState().company.company.id,
                "id": resp.data.result.id,
                "createdAt": getState().collections.collectionDatas.find(colData => colData.id === id).createdAt
            }

            dispatch({
                type: UPDATE_COLLECTION_DATA,
                collectionData: formattedCollectionData,
                successToast: showToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="collectionData.toast.update" defaultMessage="Collection datas updated successfully"/>
                } : null
            });
            return formattedCollectionData;
        }).catch(err => {
            dispatch({
                error: err
            })
        })
    }
} 


export const deleteCollectionData = (id, showToast = false) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.deleteCollectionData(id).then(resp => {
            dispatch({
                type: DELETE_COLLECTION_DATA,
                id: id,
                successToast: showToast ? {
                    type: "DELETE",
                    message: <FormattedMessage id="collectionData.toast.delete" defaultMessage="Collection datas deleted successfully"/>
                } : null
            });
            return id;
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch({
                    error: err
                })
            } else {
                dispatch({
                    type: DELETE_COLLECTION_DATA,
                    id: id,
                    successToast: showToast ? {
                        type: "DELETE",
                        message: <FormattedMessage id="collectionData.toast.delete" defaultMessage="Collection datas deleted successfully"/>
                    } : null
                });
                return id;
            }
        })
    }
}

export const importCollectionDatas = ({id, datas}) => {
    return (dispatch, getState, {rcsdk}) => {
        return axios.post(`${COLLECTION_URL}/collections/${id}/batch/`, {
            data: datas
        }, {
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
        .then(resp => {

            if(resp.data.result.ok === 1){
                dispatch(RPAHook_AfterImport({
                    model: "collection",
                    results: {
                        total: datas.length,
                        created: resp.data.result?.nInserted || 0,
                        updated: resp.data.result?.nModified || 0,
                        collection: {
                            id: id,
                            name: getState().collections.collections.find(el => el.id === id).name
                        }
                    }
                }));
            }

            return resp.data;
        })
        .catch(err => {
            console.log(err, "err");
        })
    }
}