export const SHOW_CONTACT_DRAWER = "SHOW_CONTACT_DRAWER"
export const HIDE_CONTACT_DRAWER = "HIDE_CONTACT_DRAWER"
export const SET_CURRENT_CONTACT = "SET_CURRENT_CONTACT"

export const hideContactDrawer = (contactId = null, conversationId = null) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({ type: HIDE_CONTACT_DRAWER });
    }
}


export const showContactDrawer = (contactId = null, conversationId = null, view = "edit") => {
    return (dispatch, getState, {rcsdk}) => {
        if(contactId){
            //Open Drawer contact edition
            return rcsdk.getContact(contactId).then(contact => {
                dispatch({
                    type: SHOW_CONTACT_DRAWER,
                    contact: contact,
                    conversationId: null,
                    view: view
                });
            })
        } else {
            //Open Drawer contact creation
            dispatch({
                type: SHOW_CONTACT_DRAWER,
                contact: null,
                conversationId: conversationId,
                view: "edit"
            });
        }
    }
}

export const setCurrentContact = (contactId = null) => {
    return (dispatch, getState, {rcsdk}) => {
        if(contactId){
            return rcsdk.getContact(contactId).then(contact => {
                dispatch({
                    type: SET_CURRENT_CONTACT,
                    contact: contact
                });
            })
        } else {
            dispatch({
                type: SET_CURRENT_CONTACT,
                contact: null
            });
        }
    }
}