import React from "react";
import { FormattedMessage } from "react-intl";

export const FETCH_INVITATIONS = 'FETCH_INVITATIONS';
export const FLUSH_INVITATIONS = 'FLUSH_INVITATIONS';
export const ADD_INVITATION = 'ADD_INVITATION';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const SHOW_INVITATION_MODAL = 'SHOW_INVITATION_MODAL';

export const fetchInvitations = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: FETCH_INVITATIONS, status: "pending"});
        return rcsdk._request().route(`/companies/${getState().company.company.id}/invitations`).then(invitations => {
            return dispatch({type: FETCH_INVITATIONS, status: "success", invitations: invitations});
        }).catch(err => {
            return dispatch({type: FETCH_INVITATIONS, status: "error", error: err});
        })
    }
}

export function flushInvitations() {
    return {
        type: FLUSH_INVITATIONS
    }
}

export const createInvitation = (datas) => {
    return (dispatch, getState, {rcsdk}) => {

        dispatch({
            type: ADD_INVITATION,
            status: "pending"
        });
        
        return rcsdk._request()
        .method('POST')
        .route(`/invitations`)
        .data({...datas, companyId: getState().company.company.id, status: "pending"})
        .err(`Could not create invitation`)
        .then(resp => {
            dispatch({
                type: ADD_INVITATION,
                status: "success",
                invitation: resp,
                    successToast: {
                        type: "ADD",
                        message: <FormattedMessage id="invitations.toast.add" defaultMessage="Invitation added successfully"/>
                    }
            });
            return resp;
        })
        .catch(err => {
            dispatch({
                type: ADD_INVITATION,
                status: "error",
                error: err
            });
        })
    }
}

export const deleteInvitation = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: DELETE_INVITATION,
            status: "pending"
        });

        return rcsdk._request()
        .method('DELETE')
        .route(`/invitations/${id}`)
        .err(`Could not delete invitation with id ${id}`)
        .then(resp => {
            dispatch({
                type: DELETE_INVITATION,
                status: "success",
                id: id,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="invitations.toast.delete" defaultMessage="Invitation deleted successfully"/>
                }
            });
            return resp;
        })
        .catch(err => {
            dispatch({
                type: DELETE_INVITATION,
                status: "error",
                error: err
            });
        })
    
    }
}



const selectInvitations = state => state.invitations.invitations;
const selectPendingInvitations = state => selectInvitations(state).filter(invitation => invitation.status === "pending");
export { 
    selectPendingInvitations
}