import { BASE_URL,AIRCALL_CLIENT_ID,AIRCALL_CLIENT_SECRET } from "./constants";


// Aircall endpoint that we need to redirect this request to:
const AircallAuthorizeUrl = "https://dashboard-v2.aircall.io/oauth/authorize";

// client_id given by Aircall when you registered your app:
const AircallClientId = AIRCALL_CLIENT_ID;

// let AircallClientSecret = 'BnmwjQFqHWp3eDINskCSzp1_pVhmBf7oj0RuiDbkt10';
const AircallClientSecret = AIRCALL_CLIENT_SECRET;

// Callback endpoint given to Aircall when registering your app (see next step)
const AircallCallbackUrl = `${BASE_URL}/aircall/callback`;

export {AircallAuthorizeUrl, AircallClientId, AircallClientSecret, AircallCallbackUrl}