import React, {useState} from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav
} from "reactstrap"
import * as Icon from "react-feather"
import { Avatar } from "@reecall/components_library/build"
import { connect } from "react-redux"
import { logout } from '../../../redux/actions/auth/loginActions'

import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom"

const screenfull = require('screenfull');

const UserDropdown = ({user, company, isFullScreen, isAuthenticated, toggleFullScreen, logout}) => {
  return (<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
    <DropdownToggle tag="a" className="nav-link dropdown-user-link d-flex align-items-center">
      <div className="d-lg-flex d-none flex-column me-1">
        <span className="user-name">
          {user.firstName} {user.lastName}
        </span>
        {company && <span className="text-muted user-company">
          {company.name}
        </span>}
      </div>
      <Avatar 
        element={user}
        size="md"
      />
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href=""
        onClick={e => {
          e.preventDefault()
          toggleFullScreen()
        }}
      > 
        {isFullScreen ? <Icon.Minimize size={14} className="mr-50"/> : <Icon.Maximize size={14} className="mr-50" /> }
        <span className="align-middle"><FormattedMessage id="navbar.fullscreen" defaultMessage="Full screen" /></span>
      </DropdownItem>

      <DropdownItem divider />
      
      <DropdownItem
        tag={NavLink}
        to="/settings/my-profil"
      > 
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="navbar.myprofil" defaultMessage="My profil" /></span>
      </DropdownItem>
      
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          e.preventDefault()
          if (isAuthenticated) {
            return logout();
          }
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="navbar.logout" defaultMessage="Log Out" /></span>
      </DropdownItem>
    </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const NavRight  = ({ rcuser, pending, company, isAuthenticated, logout}) => {
  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = (event) => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      setFullScreen(!fullScreen);
    }
  }

  return <Nav navbar className="ml-auto align-items-center flex-row">
      { 
        !pending && rcuser && <UserDropdown 
          user={rcuser}
          company={company}
          isAuthenticated={isAuthenticated}
          toggleFullScreen={() => toggleFullScreen()}
          isFullScreen={fullScreen}
          logout={logout}
        />
      }
    </Nav>
}

const mapStateToProps = state => {
  const { auth: {login}, user: {rcuser, pending}, company: {company} } = state;
  const { isAuthenticated, profile } = login;

  return {
    isAuthenticated,
    profile,
    rcuser,
    company,
    pending
  }
}

export default connect(mapStateToProps, {
  logout
})(NavRight);
