import {
    GET_CONFIG,
    CREATE_RULE,
    CREATE_HOOK,
    FETCH_HOOKS,
    FETCH_RULES,
    DELETE_HOOK,
    FLUSH_HOOK,
    FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK,
    UPDATE_HOOK,
    SET_SEARCH_TERM
} from '../../actions/rules';

const initialState = {
    config: [],
    hooks: [],
    rules: [],
    qAsForHook: [],
    getConfigStatus: null,
    createRulePending: false,
    fetchHooksPending: false,
    createHookPending: false,
    getRulesPending: false,
    getqAsPending: false,
    rule: null,
    newHook: null,
    deleteHookStatus: null,
    searchTerm: "",
}

const rulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action?.searchTerm,
            }
        case FLUSH_HOOK:
            return {
                ...state,
                newHook: null,
                deleteHookStatus: null
            }
        case FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK:
            return {
                ...state,
                qAsForHook: action.status === "success" ? action.qAsForHook : state.qAsForHook,
                getqAsPending: action.status === "pending"
            }
        case GET_CONFIG:
            return {
                ...state,
                config: action.status === "success" ? action.config : state.config,
                getConfigStatus: action.status,
            }
        case CREATE_RULE:
            return {
                ...state,
                rule: action.status === "success" ? action.rule : state.rule,
                createRulePending: action.status === "pending"
            }
        case UPDATE_HOOK:
            return {
                ...state,
                newHook: action.status === "success" ? action.hook : state.newHook,
                hooks: action.status === "success" ? [...state.hooks.filter(el => el.id !== action.hookIdForUpdate), action.hook] : state.hooks,
                createHookPending: action.status === "pending"
            }
        case CREATE_HOOK:
            return {
                ...state,
                newHook: action.status === "success" ? action.hook : state.newHook,
                hooks: action.status === "success" ? [...state.hooks, action.hook] : state.hooks,
                createHookPending: action.status === "pending"
            }
        case FETCH_HOOKS:
            return {
                ...state,
                hooks: action.status === "success" ? action.hooks : state.hooks,
                fetchHooksPending: action.status === "pending"
            }
        case FETCH_RULES:
            return {
                ...state,
                rules: action.status === "success" ? action.rules : state.rules,
                getRulesPending: action.status === "pending"
            }
        case DELETE_HOOK:
            return {
                ...state,
                hooks: action.status === "success" ? state.hooks.filter(el => el.id !== action.hookId) : state.hooks,
                deleteHookStatus: action.status,
            }
        default:
            return state;
    }
}
export default rulesReducer;