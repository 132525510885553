import React from "react"
import {FormattedMessage} from "react-intl";
import {omit} from "lodash"
import {deleteQuickActionLibrary} from "../quickActionLibraries/"
export const CREATE_DECK = 'CREATE_DECK';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const SET_DECK_CHILDRENS = 'SET_DECK_CHILDRENS';
export const ADD_ACTION = 'ADD_ACTION';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_CURRENT_DECK = 'SET_CURRENT_DECK';
export const OPEN_FOLDER = 'OPEN_FOLDER';
export const SELECT_ITEM = 'SELECT_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_DECK = 'UPDATE_DECK';
export const UPDATE_DECK_PENDING = 'UPDATE_DECK_PENDING';
export const DELETE_DECK = 'DELETE_DECK';
export const FETCH_DECKS_PENDING = 'FETCH_DECKS_PENDING';
export const FETCH_DECKS_SUCCESS = 'FETCH_DECKS_SUCCESS';
export const FETCH_DECKS_ERROR = 'FETCH_DECKS_ERROR';
export const SHOW_DECK_MODAL = 'SHOW_DECK_MODAL';
export const HIDE_DECK_MODAL = 'HIDE_DECK_MODAL';
export const SET_ACTION_TEMPLATE = 'SET_ACTION_TEMPLATE';
export const CREATE_QUICK_ACTION_LIBRARY = 'CREATE_QUICK_ACTION_LIBRARY';
export const SET_DECK_CONTEXT_CREATION_DONE = 'SET_DECK_CONTEXT_CREATION_DONE';

function fetchDecksPending() {
    return {
        type: FETCH_DECKS_PENDING
    }
}

function fetchDecksSuccess(decks) {
    return {
        type: FETCH_DECKS_SUCCESS,
        decks: decks
    }
}

function fetchDecksError(error) {
    return {
        type: FETCH_DECKS_ERROR,
        error: error
    }
}

export const fetchDecks = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchDecksPending());
        return rcsdk.getDecks().then(decks => {
            dispatch(fetchDecksSuccess(decks));
        }).catch(err => {
            dispatch(fetchDecksError(err.message));
        })
    }
}

export const setCurrentDeck = (deckId) => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_DECK, deckId: deckId })
    }
}


export const createInitialsDecks = ({decks}) => {
    return (dispatch, getState, {rcsdk}) => {
        let createDeckPromises = [];
        decks.forEach(deck => {
            createDeckPromises.push(dispatch(createDeck({contextId: deck.contextId, name: deck.name, showToast: false})))
        })
        return Promise.all([...createDeckPromises]).then(() => dispatch({type: SET_DECK_CONTEXT_CREATION_DONE, done: true}))
    }
}

export const createDeck = ({contextId = null, name = null, showToast = false}) => {
    return (dispatch, getState, {rcsdk}) => {
        let newDeckDatas = {
            companyId: getState().company.company.id,
            name: name || "New deck",
            contexts: contextId ? [contextId] : [],
            children: []
        }

        //si le deck n'existe pas déjà pour ce context
        if(!getState().deck.decks.find(el => el.contexts.includes(contextId))){
            return rcsdk.createDeck(newDeckDatas).then(deck => {
                let actionDatas = {
                    type: CREATE_DECK,
                    deck: deck
                }
                if(showToast) {
                    actionDatas = { ...actionDatas,
                        successToast: {
                            type: "ADD",
                            message: <FormattedMessage id="decks.toast.add" defaultMessage="Deck added successfully"/>
                        }
                    }
                }
                dispatch({ ...actionDatas });
                return deck;
            });
        }
        return false;
    }
}


export const updateDeck = (deckId, deckDatas, showToast = false) => {
    return (dispatch, getState, {rcsdk}) => {
        let formattedDeckDatas = {
            ...deckDatas
        };

        if(formattedDeckDatas.children){
            formattedDeckDatas = {
                ...formattedDeckDatas,
                children: [
                    ...deckDatas.children.map(child => {
                        let formattedChild = {...child}
                        if(child?.itemType === "folder"){
                            formattedChild = {
                                type: "folder",
                                ...omit(child, ["fields", "createdAt", "updatedAt", "isDeleted", "type"])
                            }
                        }
                        return formattedChild;
                    })
                ]
            }
        }

        dispatch({ 
            type: UPDATE_DECK_PENDING,
            deckId: deckId
        })

        rcsdk.updateDeck(deckId, formattedDeckDatas).then(deck => {
            let actionDatas = {
                type: UPDATE_DECK,
                deckId: deckId,
                deck: deck,
            }
            if(showToast) {
                actionDatas = { ...actionDatas,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="decks.toast.update" defaultMessage="Deck updated successfully"/>
                    }
                }
            }
            dispatch({ ...actionDatas });
        })
    }
}

function deleteDeckSuccess(deckId, showToast){
    return {
        type: DELETE_DECK,
        deckId: deckId,
        successToast: showToast ? {
            type: "DELETE",
            message: <FormattedMessage id="decks.toast.delete" defaultMessage="Deck deleted successfully"/>
        } : null
    }
}

export const deleteDeck = (deckId, showToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchDecksPending());

        return rcsdk.deleteDeck(deckId).then((response) => {
            dispatch(deleteDeckSuccess(deckId, showToast));
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchDecksError(err.message));
            } else {
                dispatch(deleteDeckSuccess(deckId, showToast));
            }
        });
    }
}

export const setDeckChildrens = (deckId, children) => {
    return dispatch => {
        dispatch({ type: SET_DECK_CHILDRENS, deckId: deckId, children: children })
    }
}

export const setActionTemplate = (action) => {
    return dispatch => {
        dispatch({ type: SET_ACTION_TEMPLATE, action: action })
    }
}

export const addItem = (deckId, folderId, index, action) => {
    return (dispatch, getState, {rcsdk}) => {
         dispatch({
            type: ADD_ACTION,
            deckId: deckId,
            folderId: folderId,
            index: index,
            action: action
        })
    }
}

export const updateItem = (updatedValues, actionId, deckId) => {
    return dispatch => {
        dispatch({
            type: UPDATE_ITEM,
            updatedValues: updatedValues,
            actionId: actionId,
            deckId: deckId,
            // successToast: {
            //     type: "UPDATE",
            //     message: <FormattedMessage id="deckActions.toast.update" defaultMessage="Deck Action updated successfully"/>
            // }
        })
    }
}

const checkQALibraryIsInDecks = ({actionId, decks, excludedDeckId}) => {
    let isInDecks = false;
    decks.filter(el => el.id !== excludedDeckId).forEach(deck => {
        if(deck.children.findIndex(el => el?.quickActionLibraryId === actionId) !== -1 && !isInDecks){
            isInDecks = true;
        }
        if(!isInDecks){
            deck.children.filter(el => el.type === "folder").forEach(folder => {
                if(folder.childrens.findIndex(el => el?.quickActionLibraryId === actionId) !== -1){
                    isInDecks = true;
                }
            })
        }
    });

    return isInDecks;
}

export const removeItem = (deckId, actionId, itemType) => {
    return (dispatch, getState, {rcsdk}) => {

        if(itemType === "QAction"){
            let isInDecks = checkQALibraryIsInDecks({actionId, decks: getState().deck.decks, excludedDeckId: deckId});
            if(!isInDecks){
                dispatch(deleteQuickActionLibrary(actionId));
            }
        }

        dispatch({
            type: REMOVE_ITEM,
            deckId: deckId,
            actionId: actionId,
            // successToast: {
            //     type: "DELETE",
            //     message: <FormattedMessage id="deckActions.toast.delete" defaultMessage="Deck Action deleted successfully"/>
            // }
        })
    }
}

export const selectItem = (actionId, itemType) => {
    return dispatch => {
        dispatch({ type: SELECT_ITEM, actionId: actionId, itemType: itemType })
    }
}

export const openFolder = (folderId) => {
    return dispatch => {
        dispatch({ type: OPEN_FOLDER, folderId: folderId })
    }
}

export const createFolder = (deckId, index) => {
    return dispatch => {
        return dispatch({ type: CREATE_FOLDER, deckId: deckId, index: index })
    }
}