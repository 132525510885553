export const BASE_URL            = process.env?.REACT_APP_BASE_URL            || "https://app.reecall.com"
export const CORE_URL            = process.env?.REACT_APP_CORE_URL            || "https://core.reecall.io"
export const RPA_URL             = process.env?.REACT_APP_RPA_URL             || "https://rpa.reecall.io"
export const ACD_URL             = process.env?.REACT_APP_ACD_URL             || "https://acd.reecall.io"
export const COLLECTION_URL      = process.env?.REACT_APP_COLLECTION_URL      || "https://collections.reecall.io"
export const CLASSIFIER_URL      = process.env?.REACT_APP_CLASSIFIER_URL      || "https://classifier.reecall.io"
export const NLU_URL             = process.env?.REACT_APP_MSNLU_URL           || "https://msnlu.reecall.io"
export const FAQ_URL             = process.env?.REACT_APP_FAQ_URL             || "https://faq.reecall.io"
export const PHONE_MANAGEMENT_URL = process.env?.REACT_APP_PHONE_MANAGEMENT_URL || "https://phone.reecall.io"
export const HOOK_URL            = process.env?.REACT_APP_HOOKS_URL           || "https://hooks.reecall.io"
export const API_BASE            = process.env?.REACT_APP_API_URL             || "https://api.reecall.io"
export const SEARCH_URL          = process.env?.REACT_APP_SEARCH_URL          || "https://search.reecall.io"
export const INGESTION_URL       = process.env?.REACT_APP_INGESTION_URL       || "https://ingestion.reecall.io"
export const BILLING_URL         = process.env?.REACT_APP_BILLING_URL         || "https://billing.reecall.io"
export const STATS_URL           = process.env?.REACT_APP_STATS_URL           || "https://stats.reecall.io"
export const EXPORT_URL          = process.env?.REACT_APP_EXPORT_URL          || "https://production.aws.reecall.io/export"
export const AIRCALL_CLIENT_ID = process.env?.REACT_APP_AIRCALL_CLIENT_ID         || "Ej4hv7EkTJ0U6ho1MvZ_TT8FwHK2IfQ1qhYmQ3OwlhU" || "3AoNuDR0wDDpBh5Zlqkfhc31MmeepKp9oxpXjE54LQw";
export const AIRCALL_CLIENT_SECRET = process.env?.REACT_APP_AIRCALL_CLIENT_SECRET || "d0iPgd-lLpJqKzKwH5EV_XnOnafZdcRG-WpzOK-91P0" || "BnmwjQFqHWp3eDINskCSzp1_pVhmBf7oj0RuiDbkt10";
export const AUTH0_DOMAIN = process.env?.REACT_APP_AUTH0_DOMAIN || 'auth.reecall.co'
export const AUTH0_CLIENT_ID = process.env?.REACT_APP_AUTH0_CLIENT_ID || 'TODLg9AwKPMAT3Ia2FeT9nW1kLPsZ3zq'