import { v4 as uuid } from 'uuid';
import { CLEAN_WEB_NOTIFICATION } from '../../actions/notifications/webNotifications';
import { PUSHER_START_RINGING, PUSHER_START_RINGING_CHAT } from '../../actions/pusher';

const initialState = {
    notifications: []
}

const webNotificationsReducer = (state = initialState, action) => {
    switch(action.type) {

        case PUSHER_START_RINGING:
            return {
                ...state,
                notifications: [...state.notifications, {
                    type: "ACDIncomingCall",
                    tag: `RcWebNotif-${uuid()}`
                }]
            }


        case PUSHER_START_RINGING_CHAT:
            return {
                ...state,
                notifications: [...state.notifications, {
                    type: "ACDIncomingChat",
                    tag: `RcWebNotif-${uuid()}`
                }]
            }

        case CLEAN_WEB_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications.filter(notif => notif.tag !== action.tag)]
            }

        default: 
            return state;
    }
}
export default webNotificationsReducer;