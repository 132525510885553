import React, {useEffect, useCallback} from "react";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";

const VerticalLayout = ({children, match}) => {
  const customizer = useSelector(state => state.customizer.customizer)

  useEffect(() => {
    if (customizer.dir === "rtl"){
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
    if(customizer.layout === "dark"){
      document.body.classList.remove("light-layout");
      document.body.classList.add("dark-layout")
    } else {
      document.body.classList.remove("dark-layout");
      document.body.classList.add("light-layout")
    }
  }, [customizer]);

  const noMenu = useCallback(() => {
    return match.path === "/" || match.path.startsWith("/reporting")
  }, [match]);

  return (
    <div className={"wrapper vertical-layout theme-primary navbar-static"}>
      <Navbar />
      <div className={`app-content content ${noMenu() ? "no-main-menu" : ""}`} >
        {!noMenu() && <Sidebar activePath={match.path}/>}
        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  )
}

export default VerticalLayout;
