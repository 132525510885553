import {FETCH_USER_PENDING, FETCH_USER_SUCCESS, FETCH_USER_ERROR, TOGGLE_CHANNEL} from '../../actions/user';
import {UPDATE_MEMBER} from '../../actions/members';
import {PUSHER_UPDATE_MEMBER} from '../../actions/pusher';

const initialState = {
    pending: false,
    rcuser: null,
    error: null
}

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_USER_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                rcuser: action.rcuser
            }
        case FETCH_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case TOGGLE_CHANNEL:
            return {
                ...state,
                rcuser: {...state.rcuser, settings: {...state.rcuser.settings, channels: [
                    ...state.rcuser.settings.channels.map(channel => {
                        return {
                            ...channel,
                            active: channel.name === action.channelName ? action.active : channel.active
                        }
                    })
                ]}}
            }

        case UPDATE_MEMBER:
            return {
                ...state,
                rcuser: state.rcuser.rcId === action.member.id ? {
                    ...state.rcuser,
                    ...action.member,
				    company: {...state.rcuser.company},
                    rights: {...state.rcuser.rights},
                    settings: {...state.rcuser.settings}
                } : {...state.rcuser}
            }


        case PUSHER_UPDATE_MEMBER:
            return {
                ...state,
                rcuser: state.rcuser.rcId === action.data.memberId ? 
                    {
                        ...state.rcuser,
                        "phone_available": action.data.status.phone === "true" ? true : action.data.status.phone === "false" ? false : action.data.status.phone,
                        "chat_counter": action.data.status.chat,
                        connected: action.data.connected
                    }
                    : {...state.rcuser}
            }
        default: 
            return state;
    }
}
export default userReducer;

export const getUser = state => state.rcuser;
export const getUserPending = state => state.pending;
export const getUserError = state => state.error;