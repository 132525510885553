import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider as ReduxProvider } from "react-redux"
import * as serviceWorker from "./serviceWorker"

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

import { ThemeProvider } from "styled-components";

import { LightTheme } from '@reecall/components_library/build';

import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { Layout } from "./utility/context/Layout"
import { store } from "./redux/storeConfig/store"
import LogoSpinner from "./components/reecall/spinner/LogoSpinner"

import '@reecall/components_library/build/bundle.css'
import "./index.scss"

import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/fr';
require("./configs/moment/fr/custom-locale.js");
require("./configs/moment/en/custom-locale.js");
require("./utility/prototype.js")

// Sets the moment instance to use.
Moment.globalMoment = moment;
let queryParams = new URLSearchParams(window.location.search);
Moment.globalLocale = queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';
moment.locale(queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en');

//Font Awesome
library.add(fas, fal, fab, far, fad);

const LazyApp = lazy(() => import("./App"))

serviceWorker.register({
  cb: (registration) => {
      ReactDOM.render(
        <ThemeProvider theme={LightTheme}>
          <ReduxProvider store={store}>
            <Suspense fallback={<LogoSpinner />}>
              <IntlProviderWrapper>
                <Layout>
                    <LazyApp swRegistration={registration} />
                </Layout>
              </IntlProviderWrapper>
            </Suspense>
          </ReduxProvider>
        </ThemeProvider>,
      document.getElementById("root")
      )
  }
})