import axios from "axios"
import { ACD_URL, CORE_URL } from "../../../configs/constants";
import {executeAction} from "../quickActions"
export const FETCH_LIVES_PENDING = 'FETCH_LIVES_PENDING';
export const FETCH_LIVES_SUCCESS = 'FETCH_LIVES_SUCCESS';
export const FETCH_LIVES_ERROR = 'FETCH_LIVES_ERROR';
export const SET_LIVES_TYPE = 'SET_LIVES_TYPE';
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const SET_CURRENT_LIVE_ID = 'SET_CURRENT_LIVE_ID';
export const PUSH_MESSAGE = 'PUSH_MESSAGE';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const USER_TYPING = 'USER_TYPING';
export const RESET_TYPING = 'RESET_TYPING';
export const RESET_UNREAD = 'RESET_UNREAD';
export const REMOVE_LIVE = 'REMOVE_LIVE';

export const ACCEPT_CHAT = "ACCEPT_CHAT"; 
export const DECLINE_CHAT = "DECLINE_CHAT"; 

export const setLivesFilter = type => ({
  type: SET_LIVES_TYPE,
  payload: type
});

export const setInputValue = (liveId, value) => ({
  type: SET_INPUT_VALUE,
  liveId: liveId,
  value: value
})

export const setCurrentLiveId = (liveId) => ({
  type: SET_CURRENT_LIVE_ID,
  liveId: liveId
})


export function pushMessage(conversationId, message){
  return (dispatch, getState, {rcsdk}) => {
    if(getState().lives.lives.find(el => el.id === conversationId).internal){
      return axios.post(CORE_URL+"/internchat/say", {
        message: message,
        conversationId: conversationId,
        from: 'agent',
        fromId: getState().user.rcuser.rcId,
        type: "h2h"
      })
    } else {
      return axios.post(CORE_URL+"/chat/say", {
        message: message,
        conversationId: conversationId,
        from: 'agent',
        fromId: getState().user.rcuser.rcId,
        type: "h2h"
      })
    }
  }
}

export function newInternalChat(member){
  return (dispatch, getState, {rcsdk}) => {
    return axios.post(CORE_URL+"/internchat/connect", {
        from: getState().user.rcuser.rcId,
        to: member.id
    })
  }
}


export function endConversation(liveId) {
  return (dispatch, getState, {rcsdk}) => { 
    let currentLive = getState().lives.lives.find(el => el.id === liveId);
    dispatch(executeAction({
        quickAction: {
          "actionType": "rpa",
          "key":"endConversation",
          "integrationKey":"reecall",
          "integrationId":"5f76e92be0a7e10007e9325d",
          "datas":{
              "conversationId": "{{conversation.id}}",
              "companyId": "{{company.id}}",
              "channelId": "{{channel.id}}",
              "channelType" : "{{channel.type}}"
          }
        },
        context: {
            "conversation": {"id": liveId},
            "company": {"id": getState().company.company.id},
            "channel": {"id": currentLive.channelId, type: "chat" },
        }
    })).then(() => {
      dispatch({
        type: REMOVE_LIVE,
        liveId: liveId
      })
    });
  }
}

export const acceptChat = (config) => {
  return (dispatch, getState, {rcsdk}) => {
    dispatch({ type: ACCEPT_CHAT, ...config})
    axios.get(`${ACD_URL}/dispatch/` + config.conversationId + "/accept", {
        headers: {
            memberId: getState().user.rcuser.rcId
        }
    })
  }
}


export const declineChat = (config) => {
  return (dispatch, getState, {rcsdk}) => {
    dispatch({ type: DECLINE_CHAT, ...config})
    axios.get(`${ACD_URL}/dispatch/` + config.conversationId + "/reject", {
        headers: {
            memberId: getState().user.rcuser.rcId
        }
    })
  }
}


export const selectLiveById = (state, {liveId}) => state.lives.lives.find(el => el.id === `${liveId}`) || null;
export const selectCurrentInternalLive = state => selectInternalLives(state).find(el => el.id ===  state.members.drawer.currentLiveId)
export const selectInternalLives = state => state.lives.lives.filter(el => el.internal);
export const selectExternalLives = state => state.lives.lives.filter(el => !el.internal);

export const selectInternalLiveBetween = (state, {member1Id, member2Id}) => {
  return state.lives.lives.filter(live => { return (live.id === `${member1Id}${member2Id}` || live.id === `${member2Id}${member1Id}`) && live.internal})?.[0]
}

export const selectTotalUnreadInbounds = state => state.reduce((acc, el) => acc + el.unread, 0)