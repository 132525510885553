import {
    FETCH_CONTACTS_PENDING,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_ERROR,
    UPDATE_CONTACT,
    ADD_CONTACT,
} from '../../actions/contacts';

const initialState = {
    pending: false,
    contacts: [],
    total: 0,
    error: null
}

const contactsReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case FETCH_CONTACTS_PENDING: 
            return {
                ...state,
                pending: true
            }
            
        case FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                pending: false,
                contacts: action.contacts,
                total: action.total
            }

        case FETCH_CONTACTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }


        case UPDATE_CONTACT:
            return {
                ...state,
                contacts: [
                    ...state.contacts.map(elt => {
                        if(elt.id === action.contact.id){
                            return {
                                ...elt,
                                ...action.contact,
                                phone: action.contact?.phone || elt.phone
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        case ADD_CONTACT:
            return {
                ...state, 
                contacts: [action.contact, ...state.contacts]
            }

        default: 
            return state;
    }
}
export default contactsReducer;