import {RequestBuilder} from "./RPAService";
import { BILLING_URL } from "../configs/constants"

class PaymentService {

    constructor(companyId, userId){
        this.companyId = companyId;
        this.userId = userId;
        this.secret = null;
    }

    _request() {
        const headers = {
            "x-reecall-source": "APP",
            "x-reecall-sourceId": this.userId,
            "x-reecall-company": this.companyId,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, PATCH, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type"
        }
        return new RequestBuilder({ headers }, BILLING_URL);
    }

    setCompanyId = (companyId) => {
        this.companyId = companyId;
    }

    setUserId = (userId) => {
        this.userId = userId;
    }

    setSecret = (secret) => {
        this.secret = secret;
    }

    getUpcoming = () => {
        return this._request().route(`/upcoming`).err(`Could not get upcoming`);
    }

    getBilling = () => {
        return this._request().route(`/billing`).err(`Could not get billing`);
    }

    getInvoices = () => {
        return this._request().route(`/invoices`).err(`Could not get invoices`);
    }

    getUsageRecord = () => {
        return this._request().route(`/usageRecord`).err(`Could not get usage record`);
    }

    getStripeSecret = () => {
        return this._request().route(`/paymentsmethods/secret`).err(`Could not get secret`);
    }

    getPaymentsMethods = () => {
        return this._request().route(`/paymentsmethods/paymentsmethods`).err(`Could not get payments methods`);
    }

    setPaymentMethodAsDefault = (id) => {
        return this._request()
        .method('POST')
        .route(`/paymentsmethods/setdefault`)
        .data({"paymentId": id})
        .err(`Could not set payment method as default`);
    }

    updatePaymentMethod = ({id, datas}) => {
        return this._request()
        .method('PUT')
        .route(`/paymentsmethods/paymentmethods/${id}`)
        .data({...datas})
        .err(`Could not update payment method`);
    }
}

export default PaymentService;