
export const FETCH_PAGE_TEMPLATES = 'FETCH_PAGE_TEMPLATES';
export const ADD_PAGE_TEMPLATE = 'ADD_PAGE_TEMPLATE';
export const UPDATE_PAGE_TEMPLATE = 'UPDATE_PAGE_TEMPLATE';
export const DELETE_PAGE_TEMPLATE = 'DELETE_PAGE_TEMPLATE';
export const TOGGLE_MODAL_FETCH_CONTEXT = 'TOGGLE_MODAL_FETCH_CONTEXT';
export const TOGGLE_MODAL_CONTEXT = 'TOGGLE_MODAL_CONTEXT';
export const SET_CONTEXT = 'SET_CONTEXT';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';

export const fetchPageTemplates = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_PAGE_TEMPLATES,
            status: "pending"
        });

        return rcsdk
            .getTemplates()
            .where({
                "and": [
                    {"companyId": getState().company.company.id},
                    { "type": "pageTemplate" }
                ]
            })
            .then(templates => {
                dispatch({
                    type: FETCH_PAGE_TEMPLATES,
                    status: "success",
                    templates: templates
                });
        }).catch(err => {
            dispatch({
                type: FETCH_PAGE_TEMPLATES,
                status: "error",
                error: err
            });
        })
    }
}

export const getPageTemplate = (companyId, pageTemplateSlug) => {
    return (dispatch, getState, {rcsdk})  => {
        return rcsdk
        .getPageTemplateBySlug(companyId, pageTemplateSlug)
        .then(resp => {
            console.log(resp, "resp");
            return resp;
        }).catch(err => {
            console.log(err, "err getPageTemplate");
        })
    }
}

export const savePageTemplate = (id, data) => {
    return (dispatch, getState, {rcsdk})  => {
        if(id){
            return rcsdk.updateTemplate(id, data).then(resp => {
                dispatch({type: UPDATE_PAGE_TEMPLATE, payload: resp})
            })
        } else {
            return rcsdk.createTemplate({...data, companyId: getState().company.company.id}).then(resp => {
                dispatch({type: ADD_PAGE_TEMPLATE, payload: resp})
            })
        }
    }
}

export const deletePageTemplate = (id) => {
    return (dispatch, getState, {rcsdk})  => {
        return rcsdk.deleteTemplate(id).then(resp => {
            dispatch({type: DELETE_PAGE_TEMPLATE, id: id})
        }).catch(err => {
            if(err.error?.response?.status === 400){
                dispatch({type: DELETE_PAGE_TEMPLATE, id: id})
            }
        })
    }
}