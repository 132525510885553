export const FETCH_UPCOMING = "FETCH_UPCOMING";
export const FETCH_BILLING  = "FETCH_BILLING";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const SET_STRIPE_SECRET = "SET_STRIPE_SECRET";
export const FETCH_PAYMENT_METHOD_LIST = "FETCH_PAYMENT_METHOD_LIST";
export const SET_PAYMENT_METHOD_AS_DEFAULT = "SET_PAYMENT_METHOD_AS_DEFAULT";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";

export const setStripeSecret = (secret) => {
    return (dispatch, getState, {rcPayment}) => {
        rcPayment.setSecret(secret);
        dispatch({type: SET_STRIPE_SECRET, secret: secret})
    }
}

export const getUpcoming = () => {
    return (dispatch, getState, {rcPayment}) => {
        // dispatch({type: FETCH_UPCOMING, status: "pending"})
        // return rcPayment.getUpcoming().then(resp => {
        //     console.log(resp, "resp");
        //     dispatch({
        //         type: FETCH_UPCOMING,
        //         status: "success",
        //         upcoming: resp.invoice
        //     })
        // }).catch(err => {
        //     dispatch({type: FETCH_UPCOMING, status: "error", err: err})
        // });
    }
}

export const getBilling = () => {
    return (dispatch, getState, {rcPayment}) => {
        // dispatch({type: FETCH_BILLING, status: "pending"})
        // return rcPayment.getBilling().then(resp => {
        //     dispatch({
        //         type: FETCH_BILLING,
        //         status: "success",
        //         billing: resp
        //     })
        // }).catch(err => {
        //     dispatch({type: FETCH_BILLING, status: "error", err: err})
        // });
    }
}

export const getInvoices = () => {
    return (dispatch, getState, {rcPayment}) => {
        // dispatch({type: FETCH_INVOICES, status: "pending"})
        // dispatch({
        //     type: FETCH_INVOICES,
        //     status: "success",
        //     invoices: [
        //         {
        //             status: "draft",
        //             number: "DRAFT001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test"
        //         },
        //         {
        //             status: "open",
        //             number: "OPEN001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test"
        //         },
        //         {
        //             status: "paid",
        //             number: "PAID001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test",
        //             creditNote: {
        //                 date: new Date().toISOString(),
        //                 amount: "400"
        //             }
        //         },
        //         {
        //             status: "pastDue",
        //             number: "PASTDUE001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test"
        //         },
        //         {
        //             status: "void",
        //             number: "VOID001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test"
        //         },
        //         {
        //             status: "uncollectible",
        //             number: "UNCOLLECTIBLE001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "1000",
        //             url: "test"
        //         },
        //         {
        //             status: "creditNote",
        //             number: "CREDITNOTE001",
        //             due_date: new Date().toISOString(),
        //             periods: {start: new Date().toISOString(), end: new Date().toISOString()},
        //             amount: "400",
        //             refund: true
        //         }
        //     ]
        // })
        
        
        dispatch({type: FETCH_INVOICES, status: "pending"})
        return rcPayment.getInvoices().then(resp => {
            dispatch({
                type: FETCH_INVOICES,
                status: "success",
                invoices: resp?.invoices || []
            })
        }).catch(err => {
            dispatch({type: FETCH_INVOICES, status: "error", err: err})
        });
    }
}

export const getSubscription = () => {
    return (dispatch, getState, {rcPayment}) => {
        dispatch({
            type: FETCH_SUBSCRIPTION,
            status: "success",
            subscription: {
                status: "active",
                subscriber: "Reecall",
                created_at: new Date(),
                periods: {
                    end: "2021-09-11T12:04:50.000Z",
                    start: "2021-08-11T12:04:50.000Z"
                },
                reduction: "test",
                billing_method: "Débiter un moyen de paiement spécifique",
                payment: {
                    brand: "Visa",
                    last4: "9514"
                },
                rows: [
                    {
                        rowType: "plan",
                        name: "Reecall - 190",
                        quantity: 1,
                        total: 19000,
                        unitAmount: 19000
                    },
                    {
                        rowType: "plan_detail",
                        calls: 400,
                        sms: 400,
                        minutes: 800
                    },
                    {
                        rowType: "tva",
                        name: "TVA 20%",
                        quantity: 1,
                        total: 3800,
                        unitAmount: 3800
                    }
                ],
                total: 22800
            }
        })
    }
}

export const getPaymentMethodList = () => {
    return (dispatch, getState, {rcPayment}) => {
        dispatch({type: FETCH_PAYMENT_METHOD_LIST, status: "pending"})
        return rcPayment.getPaymentsMethods().then(resp => {
            console.log(resp, "resp");
            dispatch({
                type: FETCH_PAYMENT_METHOD_LIST,
                status: "success",
                paymentsMethods: resp?.paymentMethods || []
            })
        }).catch(err => {
            dispatch({type: FETCH_PAYMENT_METHOD_LIST, status: "error", err: err})
        });
    }
}

export const setPaymentMethodAsDefault = (id) => {
    return (dispatch, getState, {rcPayment}) => {
        dispatch({type: SET_PAYMENT_METHOD_AS_DEFAULT, status: "pending"})
        return rcPayment.setPaymentMethodAsDefault(id).then(resp => {
            console.log(resp, "resp setPaymentMethodAsDefault");
            dispatch({
                type: SET_PAYMENT_METHOD_AS_DEFAULT,
                status: "success",
                id: id
            })
        }).catch(err => {
            dispatch({type: SET_PAYMENT_METHOD_AS_DEFAULT, status: "error", err: err})
        });
    }
}

export const updatePaymentMethod = ({id, datas}) => {
    return (dispatch, getState, {rcPayment}) => {
        dispatch({type: UPDATE_PAYMENT_METHOD, status: "pending"})
        return rcPayment.updatePaymentMethod({id, datas}).then(resp => {
            dispatch({
                type: UPDATE_PAYMENT_METHOD,
                status: "success",
                paymentMethod: resp
            })
        }).catch(err => {
            dispatch({type: UPDATE_PAYMENT_METHOD, status: "error", err: err})
        });
    }
}