import React from "react";
import {
    Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from "reactstrap"
import { FormattedMessage } from "react-intl";
import { IntlProviderWrapper } from "../../../utility/context/Internationalization"


const CustomPrompt = ({ message, cleanUp }) => {
    let parsedMessage = {};
    try {
        parsedMessage = JSON.parse(message);
    } catch(e){
        parsedMessage.message = message !== "" ? message : null;
    }

    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);
  
    return <IntlProviderWrapper>
        <Modal
            isOpen={true}
            toggle={cancel} 
            size="md"
            className="modal-dialog-centered"
        >
            <ModalHeader>
                {parsedMessage?.title && parsedMessage.title}
                {!parsedMessage?.title && <FormattedMessage id="modal.saveChangeOnLeavingModal.title" defaultMessage="You are about to leave the page"/>}
            </ModalHeader>
            <ModalBody>
                {parsedMessage?.message && parsedMessage.message}
                {
                    !parsedMessage?.message && <>
                        <FormattedMessage tag="p" id="modal.saveChangeOnLeavingModal.content1" defaultMessage="Be careful, the changes are not saved."/><br/>
                        <FormattedMessage tag="p" id="modal.saveChangeOnLeavingModal.content2" defaultMessage="Are you sure you want to leave the page ?"/>
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="light"
                    onClick={cancel}
                >
                    <FormattedMessage id="global.no" defaultMessage="No" />
                </Button>
                <Button
                    color="danger"
                    onClick={ok}
                    autoFocus
                >
                    <FormattedMessage id="global.yes" defaultMessage="Yes" />
                </Button>
            </ModalFooter>
        </Modal>
    </IntlProviderWrapper>
}

export default CustomPrompt;