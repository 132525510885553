import {
    FETCH_PAGE_TEMPLATES,
    UPDATE_PAGE_TEMPLATE,
    ADD_PAGE_TEMPLATE,
    DELETE_PAGE_TEMPLATE,
    TOGGLE_MODAL_FETCH_CONTEXT,
    TOGGLE_MODAL_CONTEXT,
    SET_CONTEXT,
    TOGGLE_NAVIGATION
} from '../../actions/pageTemplates';

const initialState = {
    status: "pending",
    error: null,
    templates: [],
    context: null,
    modalContextOpen: false,
    modalFetchContextOpen: false,
    showNavigation: false
}

const pageTemplatesReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_PAGE_TEMPLATES:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                templates: action.status === "success" ? [...action.templates] : [...state.templates]
            }
        
        case UPDATE_PAGE_TEMPLATE:
            return {
                ...state,
                templates: [
                    ...state.templates.map(template => {
                        if(template.id !== action.payload.id) return template;
                        return {
                            ...template,
                            ...action.payload
                        }
                    })
                ]
            }

        case ADD_PAGE_TEMPLATE:
            return {
                ...state,
                templates: [
                    ...state.templates,
                    {...action.payload}
                ]
            }
            
        case DELETE_PAGE_TEMPLATE:
            return {
                ...state,
                templates: state.templates.filter(el  => el.id !== action.id)
            }

        case TOGGLE_MODAL_FETCH_CONTEXT:
            return {
                ...state,
                modalFetchContextOpen: action.payload
            }

        case TOGGLE_MODAL_CONTEXT:
            return {
                ...state,
                modalContextOpen: action.payload
            }
    
        case SET_CONTEXT:
            return {
                ...state,
                context: action.payload
            }
        
        case TOGGLE_NAVIGATION:
            return {
                ...state,
                showNavigation: action.payload
            }

    
        default: 
            return state;
    }
}


export default pageTemplatesReducer;