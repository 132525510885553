import {
    FETCH_LIBRARY,
} from '../../actions/libraries';

const initialState = {
    pending: false,
    library: null,
    error: null
}

const librariesReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_LIBRARY:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                library: action.status === "success" ? action.library : state.library
            }
            
        default: 
            return state;
    }
}
export default librariesReducer;