import React from "react"
import {flatten} from "lodash";
import { v4 as uuid } from 'uuid';
import { FormattedMessage } from "react-intl";
import { getRequestWhere } from "../../../utility/utils"
import { truncateWithEllipses} from '../../../utility/utils';

import { UPDATE_CONVERSATION } from "../conversations"

export const FETCH_TICKETS = 'FETCH_TICKETS';

export const FETCH_TICKET = 'FETCH_TICKET';

export const ADD_TICKET = 'ADD_TICKET';
export const CLOSE_TICKET = 'CLOSE_TICKET';
export const TOGGLE_QUICK_ACTION = 'TOGGLE_QUICK_ACTION';

export const UPDATE_TICKET_TODO = 'UPDATE_TICKET_TODO';
export const ADD_TODO = 'ADD_TODO';

export const FLUSH_TICKETS = 'FLUSH_TICKETS';
export const UNSET_CURRENT_TICKET = 'UNSET_CURRENT_TICKET';

export const ASSIGN_TICKET = "ASSIGN_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_TAGS = "UPDATE_TICKET_TAGS";
export const UPDATE_TICKET_PRIMARY_CONTACT = "UPDATE_TICKET_PRIMARY_CONTACT";

export const ADD_NOTE = "ADD_NOTE";

export const SET_NAVBAR_TICKET_TOTAL = "SET_NAVBAR_TICKET_TOTAL";

export const INCREMENT_UNFETCHED_TICKET_TOTAL = "INCREMENT_UNFETCHED_TICKET_TOTAL";
export const RELOAD_LIST = "RELOAD_LIST";

export const HIDE_TICKET_DRAWER   = 'HIDE_TICKET_DRAWER';
export const SHOW_TICKET_DRAWER   = "SHOW_TICKET_DRAWER";

export const SET_FILTERS   = "SET_FILTERS";
export const SET_SEARCH_TERM   = "SET_SEARCH_TERM";

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE_TICKET';


export const hideTicketDrawer = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: HIDE_TICKET_DRAWER});
    }
}

export const showTicketDrawer = (ticketId = null) => {
    return (dispatch, getState, {rcsdk}) => {
        if(ticketId){
            //Open Drawer Ticket
            dispatch({ type: SHOW_TICKET_DRAWER });
            dispatch(fetchTicket(ticketId));
        } else {
            dispatch({type: SHOW_TICKET_DRAWER});
        }
    }
}


const formatTicketTitle = (ticket, maxLength = 40) => {
    let title = ticket.title;
    let firstConv = ticket?.conversations?.[0];
    if(firstConv){
        if(title === "New Ticket"){
            let hasGPDRConsent = firstConv.collectedData?.find(el => el.label === "GDPRconsentRec");
            let userMessages = firstConv?.messages.filter(mess => mess.from === "user");
            //Si le premier message est le consentement RGDP, on prend le deuxieme message
            let firstMessage = hasGPDRConsent ? userMessages?.[1] : userMessages?.[0];
            title = truncateWithEllipses(firstConv?.title || firstMessage?.message || "(sans objet)", maxLength)
        }
    }
    return title.trim().capitalize()
}
export {formatTicketTitle};

export function flushTickets() {
    return {
        type: FLUSH_TICKETS
    }
}

export const fetchTickets = ({
    limit,
    skip = 0,
    flush = false,
    order = "createdAt DESC",
    filters = {},
    where = {},
    searchTerm = "",
    view = "list"
}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        if(flush){
            dispatch(flushTickets({view: view}));
        }
        
        dispatch({type: FETCH_TICKETS, view: view, status: "pending"})
        let isSearch = searchTerm !== "";
        let loopbackFilters = getRequestWhere({
            companyId: getState().company.company.id,
            where: where,
            filters: filters,
            toMongo: isSearch
        });

        let request;
        if(isSearch) {
            request = `atlasSearchTickets`;
        } else {
            request = `getTickets`;
        }

        let requestWhere = loopbackFilters.length > 0 ? {[isSearch ? "$and" : "and"]: flatten([
            ...loopbackFilters
        ])} : {}

        console.groupCollapsed(`RCSDK tickets (${request})`);
        console.log(isSearch, "isSearch");
        console.log(searchTerm, "searchTerm");
        console.log(where, "where");
        console.log(filters, "filters");
        console.log(order, "order");
        console.log(limit, "limit");
        console.log(skip, "skip");
        console.log(loopbackFilters, "loopbackFilters");
        console.log(requestWhere, "requestWhere");
        console.groupEnd();

        
        return rcsdk[request](searchTerm)
        .include(["assignee", {"relation": "conversations", "fields": ["id"]}])
        .skip(skip)
        .limit(limit)
        .raw()
        .where(requestWhere)
        .order(order)
        .then(resp => {
            let formattedTickets = resp.data.map(ticket => {
                let title = formatTicketTitle(ticket);
                return {...ticket, title: title}
            });

            const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
            dispatch({
                type: FETCH_TICKETS,
                status: "success",
                tickets: formattedTickets,
                total: total,
                view: view
            })
            
            return {data: resp.data, total: total, totalRequest: resp.data.length}
        
        }).catch(err => {
            dispatch({type: FETCH_TICKETS, view: view, status: "error", err: err})
        });
    }
}

export const setSearchTerm = (searchTerm) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: SET_SEARCH_TERM,
            searchTerm: searchTerm || ""
        })
    }
}

export const setFilters = (filters, flush = true) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: SET_FILTERS,
            filters: filters || [],
            flush: flush
        })
    }
}

export const unsetCurrentTicket = () => {
    return {
        type: UNSET_CURRENT_TICKET
    }
}

export const toggleQuickAction = (ticketId, todoId, quickActionLibraryId) => {
    return (dispatch, getState, {rcsdk}) => {
        let currentTicket = getState().tickets.current;
        let updatedTodos = [...currentTicket.todo.map(todo => {
            return todo.id === todoId ? {
                ...todo,
                quickActions: [
                    ...todo.quickActions.map(quickAction => {
                        return quickAction.quickActionLibraryId === quickActionLibraryId ? {...quickAction, done: !!!quickAction?.done} : {...quickAction}
                    })
                ]
            } : {...todo}
        })]
        dispatch({
            type: TOGGLE_QUICK_ACTION,
            ticketId: ticketId,
            todoId: todoId,
            quickActionLibraryId: quickActionLibraryId
        });

        rcsdk.updateTicket(ticketId, {todo: updatedTodos}).then(ticket => {
            let currentTodo = ticket.todo.find(el => el.id === todoId);
            if(currentTodo.quickActions.filter(quickAction => quickAction.done).length === currentTodo.quickActions.length){
                dispatch(toggleTodo({ticketId, todoId, status: true}))
            } else {
                dispatch(toggleTodo({ticketId, todoId, status: false}))
            }
        });
    }
}

export const toggleTodo = ({ticketId, todoId, status}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getTicket(ticketId).then(ticket => {
            let ticketTodos = [
                ...ticket.todo.map(todo => {
                    if(todo.id === todoId){
                        return {
                            ...todo,
                            done: typeof status !== "undefined" ? status : typeof todo?.done !== "undefined" ? !!!todo.done : true,
                            doneBy: {
                                modelType: "members",
                                id: getState().user.rcuser.rcId
                            }
                        }
                    } else {
                        return todo
                    }
                })
            ]

            return rcsdk.updateTicket(ticketId, {todo : [...ticketTodos]}).then(ticket => {
                dispatch({ type: UPDATE_TICKET_TODO, ticketId: ticketId, todos: ticket.todo});
            });
        });
    }
}

export const addTodo = (ticketId, todo) => {
    return (dispatch, getState, {rcsdk}) => {
        const currentTicket = {...getState().tickets.current};

        let newTodo = {
            id: uuid(),
            ...todo,
            quickActions: [
                ...todo.quickActions.map(quickAction => {
                    return {
                        "type": "QAction",
                        "quickActionLibraryId": quickAction.id,
                        "done": false
                    }
                })
            ]
        };

        rcsdk.updateTicket(ticketId, {todo : [...currentTicket.todo, newTodo]}).then(ticket => {
            dispatch({ type: ADD_TODO, ticketId: ticketId, todo: newTodo });
        });
    }
}

export const addNewTicket = (ticket, conversationId = null) => {
    return (dispatch, getState, {rcsdk}) => {

        let newTicket = {
            title: ticket.title,
            assigneeId: ticket.assigneeId,
            tagIds: ticket.tagIds,
            todo: [],
            status: "todo",
            companyId: getState().company.company.id
        }

        // dispatch(fetchTicketsPending());

        return rcsdk.createTicket(newTicket).then(ticketResponse => {
            if(conversationId){
                rcsdk.setConversationTicket(conversationId, ticketResponse.id).then(conversationResponse => {
                    dispatch({
                        type: UPDATE_CONVERSATION,
                        conversation: conversationResponse
                    })
                }).catch(err => {
                    // dispatch(fetchTicketsError(err.message));
                })
            }

            dispatch({ 
                type: "ADD_TICKET",
                ticket: ticketResponse,
                successToast: {
                    type: "ADD",
                    message: <FormattedMessage id="tickets.toast.add" defaultMessage="Ticket added successfully"/>
                }
            });

        }).catch(err => {
            // dispatch(fetchTicketsError(err.message));
        })
    }
}


const getTicketTimeline = async ({ticket}) => {
    let ticketTimeline = [];

    ticket.conversations.map(conv => {
      let hasGPDRConsent = conv.collectedData?.find(el => el.label === "GDPRconsentRec");
      let userMessages = conv?.messages.filter(mess => mess.from === "user");
      //Si le premier message est le consentement RGDP, on prend le deuxieme message
      let firstMessage = hasGPDRConsent ? userMessages?.[1] : userMessages?.[0];
      let title = truncateWithEllipses(conv?.title || firstMessage?.message || "New conversation", 50)

      ticketTimeline.push({
        type: conv?.channelType || 'phone',
        title: title,
        author: conv?.contact ? {
          ...conv?.contact
        } : {},
        createdAt: conv?.messages?.[0]?.time || conv.createdAt,
        id: conv.id,
        isInbound: true
      });
      return null;
    })

    ticket.history.map(hist => {
      ticketTimeline.push({
        ...hist,
        createdAt: hist.timestamp,
        isInbound: false
      });
      return null;
    });

    ticket.notes.map(note => {
      ticketTimeline.push({
        type: "note",
        ...note
      })
      return null;
    });
    
    ticketTimeline = ticketTimeline.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })

    return ticketTimeline;
}

const SortAndFormatTimeline = ({timeline, members}) => {
    timeline = timeline.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
    })
  
    timeline = timeline.map(inbound => {
        return {
          ...inbound,
          author: inbound.author ? inbound.author : members.find(memb => memb.id === inbound.memberId)
        }
    });

    return timeline;
}

const getPrimaryContactTimeline = (primaryContact, ticket) => {
    let primaryContactTimeline = [];

    if(primaryContact?.history){
        let lastEvent = null;
        let lastEventDate = null;
        primaryContact.history.map(event => {
            if(event.timestamp > ticket.createdAt){
                if(lastEvent){
                    if(
                        lastEvent.type === `contact_${event.type}` && 
                        lastEvent.memberId === event?.memberId &&
                        lastEventDate.setMinutes(lastEventDate.getMinutes() + 5).valueOf() > new Date(event.timestamp).valueOf()
                    ){
                        lastEvent = {
                            ...lastEvent,
                            fields: [
                                ...lastEvent.fields,
                                {
                                    field: event.field,
                                    oldValue: event?.oldValue,
                                    newValue: event?.newValue
                                }
                            ]
                        }
                    } else {
                        primaryContactTimeline.push(lastEvent);
                        lastEventDate = new Date(event.timestamp);
                        lastEvent = {
                            memberId: event?.memberId,
                            fields: [
                                {field: event.field, oldValue: event?.oldValue, newValue: event?.newValue}
                            ],
                            type: `contact_${event.type}`,
                            createdAt: event.timestamp,
                            isInbound: false
                        };
                    }
                } else {
                    lastEventDate = new Date(event.timestamp);
                    lastEvent = {
                        memberId: event?.memberId,
                        fields: [
                            {field: event.field, oldValue: event?.oldValue, newValue: event?.newValue}
                        ],
                        type: `contact_${event.type}`,
                        createdAt: event.timestamp,
                        isInbound: false
                    };
                }
            }
            return null;
        })
         
        if(lastEvent){
            primaryContactTimeline.push(lastEvent);
        }
    }

    return primaryContactTimeline;
}

const getFormattedTicket = ({ticket, members}) => {
    return (dispatch, getState, {rcsdk}) => {
        let collectedDatas = ticket.conversations.map(conv => conv.collectedData.map(el => {return {...el, agentId: conv?.agentId}}));
        return getTicketTimeline({ticket: ticket}).then((timeline) => {
            let fullTimeline = [];
            let contactPromise = Promise.resolve();
            if(ticket?.primaryContactId){
                contactPromise = rcsdk.getContact(ticket.primaryContactId).then(contact => {
                    return contact;
                });
            }

            return contactPromise.then(primaryContact => {
                if(!primaryContact){
                    let conversationTypes = ["chat", "phone", "forwarded_phone", "google-home", "google-assistant", "aircall", "alexa", "new_conversation", "threecx", "wildix", "clicktocall"];
                    primaryContact = timeline.find(el => conversationTypes.includes(el.type))?.author;                
                }       
                
                fullTimeline = SortAndFormatTimeline({
                    timeline: [...timeline, ...getPrimaryContactTimeline(primaryContact, ticket)],
                    members: members
                })

                let title = formatTicketTitle(ticket);

                return {
                    ...ticket,
                    title: title,
                    primaryContact: primaryContact,
                    collectedDatas: flatten(collectedDatas),
                    timeline: fullTimeline
                }
            })
        });
    }
}

export const fetchTicket = (id) => {
    return (dispatch, getState, {rcsdk}) => {
      dispatch({type: FETCH_TICKET, status: "pending"})
      //Recupération du ticket avec ces assignees, notes et conversations + contact
      return rcsdk.getTicket(id).include(["assignee" , "notes", {"conversations": ["contact"]}]).then(ticket => {
            dispatch(getFormattedTicket({ticket: ticket, members: getState().members.members})).then((formattedTicket) => {
                dispatch({type: FETCH_TICKET, status: "success", ticket: formattedTicket})
            });
      }).catch(err => {
        dispatch({type: FETCH_TICKET, status: "error"})
      })
    }
}

export const fetchTicketByZendeskId = (zid, direct = false) => {
    return (dispatch, getState, {rcsdk}) => {
        !direct && dispatch({type: FETCH_TICKET, status: "pending"});
        //Recupération du ticket avec ces assignees, notes et conversations + contact
        return rcsdk.getTickets().where({"zendeskTicketId": zid.toString()}).include(["assignee" , "notes", {"conversations": ["contact"]}]).then(tickets => {
            console.log(tickets, "tickets");
                if(tickets[0]){
                    return dispatch(getFormattedTicket({ticket: tickets[0], members: getState().members.members})).then((formattedTicket) => {
                        !direct && dispatch({type: FETCH_TICKET, status: "success", ticket: formattedTicket});
                        return formattedTicket;
                    });
                } else {
                    console.warn(`ticket {zid} missing from rcBDD`);
                    !direct && dispatch({type: FETCH_TICKET, status: "error"});
                    return false;
                }
        }).catch(err => {
            console.log(err, "err");
            !direct && dispatch({type: FETCH_TICKET, status: "error", error: err.message});
        })
    }
}

export const updateTicketStatus = ({id, status}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateTicketStatus(id, status).then(success => {
            if(success){
                let currentUser = getState().user.rcuser;
                dispatch({
                    type: UPDATE_TICKET_STATUS,
                    id: id,
                    status: status,
                    author: {
                        id: currentUser.rcId,
                        lastName: currentUser.lastName,
                        firstName: currentUser.firstName
                    },
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateStatus" defaultMessage="Ticket status updated successfully"/>
                    }
                })
            }
            return success;
        })
    }
}

export const closeTicket = (ticketId) => {
    return (dispatch, getState, {rcsdk}) => {
        return dispatch(updateTicketStatus({id: ticketId, status: "closed"}));
    }
}

export const assignTicketToMember = ({id, assigneeId}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.assignTicket(id, assigneeId).then(ticket => {
            return dispatch(getFormattedTicket({ticket: {...getState().tickets.current, ...ticket}, members: getState().members.members})).then((formattedTicket) => {
                return dispatch({
                    type: ASSIGN_TICKET,
                    ticket: formattedTicket,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateAssign" defaultMessage="Ticket assigned successfully"/>
                    }
                })
            });
        }).catch(err => {
            dispatch({type: FETCH_TICKET, status: "error", error: err.message});
        });
    }
}

export const unassignMember = ({id}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.assignTicket(id).then(ticket => {
            return dispatch(getFormattedTicket({ticket: {...getState().tickets.current, ...ticket}, members: getState().members.members})).then((formattedTicket) => {
                return dispatch({
                    type: ASSIGN_TICKET,
                    ticket: formattedTicket,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateUnassign" defaultMessage="Ticket unassigned successfully"/>
                    }
                })
            });
        });
    }
}

export const addNote = ({ticketId, content}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.createTicketNote(ticketId, getState().company.company.id, content).then(response => {
            let currentUser = getState().user.rcuser;
            dispatch({
                type: ADD_NOTE,
                ticketId: ticketId,
                note: {
                    ...response,
                    author: {
                        id: currentUser.rcId,
                        lastName: currentUser.lastName,
                        firstName: currentUser.firstName
                    }
                },
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="ticket.toast.addNote" defaultMessage="Note added successfully"/>
                }
            })
        });
    }
}

export const updateTicketTags = ({ticketId, tagIds}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateTicket(ticketId, {tagIds: tagIds}).then(response => {
            return dispatch({
                type: UPDATE_TICKET_TAGS,
                id: ticketId,
                tagIds: response?.tagIds || [],
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="ticket.toast.addTags" defaultMessage="Ticket's tags updated successfully"/>
                }
            })
        }).catch(err => {
            dispatch({type: "ERROR_UPDATE_TICKET_TAGS", error: `An error occured: ${err.message}`});
        });
    }
}

export const updateTicketPrimaryContact = ({ticketId, contactId}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.updateTicket(ticketId, {primaryContactId: contactId}).then(ticket => {

            return dispatch(getFormattedTicket({ticket: {...getState().tickets.current, ...ticket}, members: getState().members.members})).then((formattedTicket) => {
                return dispatch({
                    type: UPDATE_TICKET_PRIMARY_CONTACT,
                    ticket: formattedTicket,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updatePriimaryContact" defaultMessage="Ticket's primary contact updated successfully"/>
                    }
                })
            });
            
        }).catch(err => {
            dispatch({type: "ERROR_UPDATE_TICKET_PRIMARY_CONTACT", error: `An error occured: ${err.message}`});
        });
    }
}


export const fetchNavBarTicketTotal = () => {
    return (dispatch, getState, {rcsdk}) => {
        rcsdk.Stats.getCount("NewTicket").where({"status": {"$in": ["todo", "open"]}}).then(resp => {
            dispatch({type: SET_NAVBAR_TICKET_TOTAL, total: resp.count});
        })
    }
}



export const updateTicketInApp = ({ticketId}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getTicket(ticketId).include(["assignee" , "notes", {"conversations": ["contact"]}]).then(ticket => {
            dispatch(getFormattedTicket({ticket: ticket, members: getState().members.members})).then((formattedTicket) => {
                dispatch({type: UPDATE_TICKET, id: formattedTicket.id, datas: formattedTicket});
            });
        }).catch(err => {
        })
    }
}

export const incrementUnfetchedTicketTotal = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: INCREMENT_UNFETCHED_TICKET_TOTAL});
    }
}



export const getReecallTicketIdByZendeskTicketId = (zid) => {
    return (dispatch, getState, {rcsdk}) => {
      return rcsdk.getTickets().where({"zendeskTicketId": zid.toString()}).then(tickets => {
        if(!tickets?.[0]) return false;
        return tickets?.[0].id;
      }).catch(err => {})
    }
}