import React from "react"
import { Spinner } from "reactstrap"
import logo from "../../../assets/img/logo/logo_reecall_2020.svg"

const LogoSpinner = () => {
    return <div className="fallback-spinner vh-100 d-flex flex-columns align-items-center justify-content-center">
        <img className="fallback-logo" src={logo} alt="Logo Reecall" />
        <Spinner size="lg" color="primary"/>
    </div>
}

export default LogoSpinner
