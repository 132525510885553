import React from "react"
import classnames from "classnames"

const FullPageLayout = ({ children, noBg = false, ...rest }) => {
  return (
    <div
      className={classnames(
        "full-layout wrapper blank-page overflow-auto",
        {
          'bg-full-screen-image': !noBg
        }
      )}
      style={{
        height: "100vh"
      }}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
