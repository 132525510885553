import { HIDE_CONTACT_DRAWER, SHOW_CONTACT_DRAWER, SET_CURRENT_CONTACT } from '../../actions/contacts/drawer';
import { UPDATE_CONTACT, ADD_CONTACT } from '../../actions/contacts/';

const initialState = {
    show: false,
    contact: null,
    conversationId: null,
    view: "edit",
    prevView: null
}

const contactsDrawerReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case SET_CURRENT_CONTACT:
            return {
                ...state,
                contact: action.contact
            }
            
        case HIDE_CONTACT_DRAWER:
            return {
                ...state,
                show: false,
                view: "edit",
                prevView: null
            }
        
        case SHOW_CONTACT_DRAWER:
            return {
                ...state,
                prevView: state.view,
                show: true,
                contact: action?.contact || null,
                conversationId: action?.conversationId || null,
                view: action?.view || "edit"
            }
        
        case UPDATE_CONTACT:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    ...action.contact
                }
            }
        
        case ADD_CONTACT:
            return {
                ...state,
                contact: null,
                conversationId: null,
                show: false,
                view: "edit",
                prevView: null
            }

        default: 
            return state;
    }
}
export default contactsDrawerReducer;