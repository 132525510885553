import {
    FETCH_MEMBERS_PENDING,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_ERROR,
    FLUSH_MEMBERS,
    UPDATE_MEMBER,
    TOGGLE_LIST_DRAWER,
    OPEN_INTERNAL_CHAT,
    updatePhoneAvailability
} from '../../actions/members';
import { PUSHER_UPDATE_MEMBER, PUSHER_START_RINGING, PUSHER_STOP_RINGING } from "../../actions/pusher";

const initialState = {
    pending: false,
    members: [],
    error: null,
    drawer: {
        enabled: false,
        show: false,
        currentLiveId: null
    }
}

const membersReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_MEMBERS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state,
                pending: false,
                members: action.flush ? [...action.members] : [...state.members, ...action.members]
            }
        case FETCH_MEMBERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FLUSH_MEMBERS:
            return {
                ...state,
                members: []
            }

        case UPDATE_MEMBER:
            return {
                ...state,
                members: [
                    ...state.members.map(memb => {
                        return memb.id !== action.member.id ? {...memb} : {...memb, ...action.member}
                    })
                ]
            }
        
        case PUSHER_UPDATE_MEMBER:
            return {
                ...state,
                members: [
                    ...state.members.map(member => {
                        return member.id === action.data.memberId ? {
                            ...member,
                            "phone_available": action.data.status.phone === "true" ? true : action.data.status.phone === "false" ? false : action.data.status.phone,
                            "chat_counter": action.data.status.chat,
                            connected: action.data.connected
                        } : {...member}
                    })
                ]
            }

        case TOGGLE_LIST_DRAWER:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    show: !state.drawer.show
                }
            }

        case OPEN_INTERNAL_CHAT:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    show: true,
                    currentLiveId: action.liveId
                }
            }
        
        case PUSHER_STOP_RINGING:
            if(action.data.reason !== "accept"){
                updatePhoneAvailability({
                    memberId: action.additionalParams.userId,
                    companyId: action.additionalParams.companyId,
                    status: true
                })
            }
            return {
                ...state
            }

        case PUSHER_START_RINGING:
            updatePhoneAvailability({
                memberId: action.additionalParams.userId,
                companyId: action.additionalParams.companyId,
                status: false
            })
            return {
                ...state
            }

        default: 
            return state;
    }
}
export default membersReducer;