import React from "react"
import { IntlProvider } from "react-intl"
import translations from "../../assets/data/locales/translations.json"
import {translations as translationsComponentLibrairy} from "@reecall/components_library/build"
import { IntlGlobalProvider } from "../../components/reecall/lang/IntlGlobalProvider";
import moment from 'moment';

const INTL_DEBUG = false;

const Context = React.createContext();

const getTranslationByLocale = (locale) => {
  return Object.assign({},
      translations?.[locale] || null,
      translationsComponentLibrairy?.[locale] || null
  )
}

class IntlProviderWrapper extends React.Component {

  queryParams = new URLSearchParams(window.location.search);

  state = {
    locale: this.queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en',
    messages: getTranslationByLocale(this.queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en')
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state;

    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale: language,
              messages: getTranslationByLocale(language)
            });
            localStorage.setItem('reecall-webapp-locale', language);
            moment.locale(language);
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
          onError={(message, detail) => {
            INTL_DEBUG && console.error(message);
          }}
        >
          <IntlGlobalProvider>
            {children}
          </IntlGlobalProvider>
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
