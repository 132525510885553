import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from "react-intl"
import { store } from '../redux/storeConfig/store';
import Phone from "../components/reecall/call/Phone"
import { omit } from "lodash";
import { NavLink } from "reactstrap"
import { NavLink as Link } from "react-router-dom"

import { getChannelOptionsByType } from "../configs/channelsConfig";
import { SET_SHOW_AGENT_CTA_MODAL } from "../redux/actions/agents"
import { IS_SMARTTAGS_ACTIVE } from "../redux/actions/tags"
import { TOGGLE_MAIN_MENU } from "../redux/actions/menu/index"

const getChannelNavItemProps = (channel) => {
  let channelOptions = getChannelOptionsByType(channel.type);
  let clonedIcon = null;
  if (channelOptions.icon) {
    clonedIcon = React.cloneElement(
      <FontAwesomeIcon icon={channelOptions.icon} className="menu-icon" />,
    );
  }

  return {
    id: channel.id,
    label: channel.name,
    icon: clonedIcon,
    token: channel.type === "phone" ? <Phone phoneNumber={channel.token} callOnClick={false} /> : null,
    type: "item",
    data: channel?.data
  }
}

const NavLinkDefaultComponent = ({children, ...props}) =><NavLink
  tag={Link}
  {...props}
>
  {children}
</NavLink>


const navigationConfig = ({ticketTotal, conversationTotal, dispatch}) => {
  const rcuser = store.getState().user.rcuser;
  const pendingUser = store.getState().user.pending;
  const channelsStore = store.getState().channels;

  if (!rcuser) return [];

  const onItemClick = () => dispatch({type: TOGGLE_MAIN_MENU, show: false})

  //CONVERSATIONS MENU
  let conversationnalChannels = [];
  if (rcuser && !pendingUser && channelsStore.channels.length > 0) {
    conversationnalChannels = channelsStore.channels.map(channel => {
      let convChannelProps = getChannelNavItemProps(channel);
      return {
        ...omit(convChannelProps, ["token", "data"]),
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: `/conversations/${channel.id}`
        },
        onClick: onItemClick
      }
    })
  }

  let conversationsMenu = {
    id: "conversations",
    type: "group",
    collapse: true,
    label: <FormattedMessage id="menu.item.conversations" defaultMessage="Conversations" />,
    childrens: [
      {
        id: "all-conversations",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/conversations/all",
        },
        label: <FormattedMessage id="menu.item.conversations" defaultMessage="Conversations" />,
        icon: ["far", "circle"],
        tooltip: <FormattedMessage id="menu.item.conversations.all.tooltip" defaultMessage="Inbounds from all channels" />,
        onClick: onItemClick
      },
      ...conversationnalChannels,
      {
        id: "archived-conversations",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/conversations/archived"
        },
        label: <FormattedMessage id="menu.item.conversations.archived" defaultMessage="Archived Conversations" />,
        icon: ["far", "archive"],
        onClick: onItemClick
      }
    ]
  };


  //TICKETS MENU
  let ticketsMenu = {
    id: "tickets",
    type: "group",
    collapse: true,
    label: <FormattedMessage id="menu.item.tickets" defaultMessage="Tickets" />,
    badge: ticketTotal,
    badgeProps: {
      color: "light",
      className: "bg-white",
      tooltip: <FormattedMessage id="menu.item.tickets.badge" defaultMessage="In progress tickets" />
    },
    childrens: [
      {
        id: "new-tickets",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/tickets/new"
        },
        label: <FormattedMessage id="menu.item.tickets.new" defaultMessage="New Tickets" />,
        icon: ["far", "circle"],
        tooltip: <FormattedMessage id="menu.item.tickets.all.tooltip" defaultMessage="Unassigned tickets" />,
        onClick: onItemClick
      },
      {
        id: "all-tickets",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/tickets/all"
        },
        label: <FormattedMessage id="menu.item.tickets.all" defaultMessage="All Tickets" />,
        icon: ["far", "copy"],
        onClick: onItemClick
      },
      {
        id: "my-tickets",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/tickets/my"
        },
        label: <FormattedMessage id="menu.item.tickets.my" defaultMessage="My Tickets" />,
        icon: ["far", "file-user"],
        tooltip: <FormattedMessage id="menu.item.tickets.my.tooltip" defaultMessage="Assigned to me" />,
        onClick: onItemClick
      },
      {
        id: "closed-tickets",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/tickets/closed"
        },
        label: <FormattedMessage id="menu.item.tickets.closed" defaultMessage="Closed Tickets" />,
        icon: ["far", "check"],
        onClick: onItemClick
      },
      {
        id: "archived-tickets",
        type: "item",
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/tickets/archived"
        },
        label: <FormattedMessage id="menu.item.tickets.archived" defaultMessage="Archived Tickets" />,
        icon: ["far", "archive"],
        onClick: onItemClick
      }
    ]
  };


  //CONTACTS MENU
  let contactsMenu = {
    id: "contacts",
    type: "group",
    collapse: true,
    label: <FormattedMessage id="menu.item.contacts" defaultMessage="Contacts" />,
    childrens: [
      {
        id: "all-contacts",
        type: "item",
        label: <FormattedMessage id="menu.item.contacts.all" defaultMessage="All Contacts" />,
        icon: ["far", "users"],
        navLinkComponent: NavLinkDefaultComponent,
        navLinkProps: {
          to: "/contacts"
        },
        onClick: onItemClick
      }
    ]
  }



  //SETTINGS MENU
  let settingsMenus = [
    {
      id: "settings-productivity",
      type: "group",
      collapse: true,
      label: <FormattedMessage id="settings.productivityGroup" defaultMessage="PRODUCTIVITY"/>,
      childrens: [
        {
          id: "settings-channels",
          type: "item",
          label: <FormattedMessage id="settings.channels" defaultMessage="Channels"/>,
          icon: ["far", "bullseye"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/channels`,
          },
          tab: 8,
          onClick: onItemClick
        },
        {
          id: "settings-scenarios",
          type: "item",
          label: <FormattedMessage id="settings.scenarios" defaultMessage="Scenarios and Treatments"/>,
          icon: ["far", "sitemap"],
          tab: 18,
          navLinkComponent: "div",
          onClick: () => {
            dispatch({type: SET_SHOW_AGENT_CTA_MODAL, payload: true});
            onItemClick()
          },
          //style: {cursor: "pointer"},
        },
        {
          id: "settings-deck",
          type: "item",
          label: <FormattedMessage id="settings.doItForMe" defaultMessage="Do It For Me"/>,
          icon: ["far", "magic"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/deck`,
          },
          tab: 6,
          onClick: onItemClick
        },
        {
          id: "settings-integrations",
          type: "item",
          label: <FormattedMessage id="settings.integrations" defaultMessage="Integrations"/>,
          icon: ["far", "cube"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/integrations`,
          },
          tab: 10,
          onClick: onItemClick
        },
        {
          id: "settings-tags",
          type: "item",
          label: <FormattedMessage id="settings.tags" defaultMessage="Tags"/>,
          icon: ["far", "tags"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/tags`,
          },
          tab: 11,
          onClick: onItemClick
        },
        IS_SMARTTAGS_ACTIVE ? {
          id: "settings-smartTags",
          type: "item",
          label: <FormattedMessage id="settings.smartTags" defaultMessage="Smart Tags"/>,
          icon: ["far", "tags"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/smartTags`,
          },
          tab: 24,
          onClick: onItemClick
        } : null,
        {
          id: "settings-conversation-summary",
          type: "item",
          label: <FormattedMessage id="settings.conversation-summary" defaultMessage="Conversation summary"/>,
          icon: ["far", "square-check"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/conversation-summary`,
          },
          tab: 13,
          onClick: onItemClick
        },
        {
          id: "settings-rules",
          type: "item",
          label: <FormattedMessage id="settings.rules" defaultMessage="Rules" />,
          icon: ["far", "book-blank"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/rules/list`,
          },
          tab: 25,
          onClick: onItemClick
        },
        // {
        //   type: "item",
        //   id: "settings-preferences",
        //   label: <FormattedMessage id={"settings.preferences-settings"} defaultMessage={"Preferences"}/>,
        //   icon: ["far", "bars"],
        //   navLinkComponent: NavLinkDefaultComponent,
        //   navLinkProps: {
        //     to: `/settings/preferences`,
        //   },
        //   tab: 22,
        // }
      ]
    },
    {
      id: "settings-team",
      type: "group",
      collapse: true,
      label: <FormattedMessage id="settings.teamGroup" defaultMessage="Team"/>,
      childrens: [
        {
          id: "settings-agents",
          type: "item",
          label: <FormattedMessage id="settings.virualagents" defaultMessage="Virtual Agents"/>,
          icon: ["far", "headphones"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/agents`,
          },
          tab: 4,
          onClick: onItemClick
        },
        {
          id: "settings-members",
          type: "item",
          label: <FormattedMessage id="settings.teammates" defaultMessage="Teammates"/>,
          icon: ["far", "user"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/members`,
          },
          tab: 5,
          onClick: onItemClick
        },
        {
          id: "settings-invitations",
          type: "item",
          label: <FormattedMessage id="settings.invitations" defaultMessage="Invitations"/>,
          icon: ["far", "user-plus"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/invitations`,
          },
          tab: 9,
          onClick: onItemClick
        }
        // ,{
        //   id: "settings-teams",
        //   type: "item",
        //   label: <FormattedMessage id="settings.teams" defaultMessage="Teams"/>,
        //   icon: ["far", "users"],
        //   navLinkComponent: NavLinkDefaultComponent,
        //   navLinkProps: {
        //     to: `/settings/teams`,
        //   },
        //   tab: 14,
        //   onClick: onItemClick
        // }
      ]
    },
    {
      id: "settings-businessData",
      type: "group",
      collapse: true,
      label: <FormattedMessage id="settings.businessDataGroup" defaultMessage="Business datas"/>,
      childrens: [
        {
          id: "settings-faqs",
          type: "item",
          label: <FormattedMessage id="settings.faqs" defaultMessage="FAQS"/>,
          icon: ["far", "question"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/faqs`,
          },
          tab: 16,
          onClick: onItemClick
        },
        {
          id: "settings-collections",
          type: "item",
          label: <FormattedMessage id="settings.collections" defaultMessage="Collections"/>,
          icon: ["far", "list"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/collections`,
          },
          tooltip: <FormattedMessage id="settings.collections.tooltip" defaultMessage="Imports & Repositories" />,
          tab: 17,
          onClick: onItemClick
        },
        {
          id: "settings-collectedDatas",
          type: "item",
          label: <FormattedMessage id="settings.collectedDatas" defaultMessage="Collected Datas"/>,
          icon: ["far", "crosshairs"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/collectedDatas`,
          },
          tab: 20,
          onClick: onItemClick
        },
        {
          id: "settings-exports",
          type: "item",
          label: <FormattedMessage id="settings.exports" defaultMessage="Exports" />,
          icon: ["far", "file-export"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/exports`,
          },
          tab: 23,
          onClick: onItemClick
        }
      ]
    },
    {
      id: "settings-companyGroup",
      type: "group",
      collapse: true,
      label: <FormattedMessage id="settings.companyGroup" defaultMessage="COMPANY"/>,
      childrens: [
        {
          id: "settings-company-general",
          type: "item",
          label: <FormattedMessage id="settings.company-general" defaultMessage="General"/>,
          icon: ["far", "building"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/company-general`,
          },
          tab: 15,
          onClick: onItemClick
        },
        {
          id: "settings-company-openingHours",
          type: "item",
          label: <FormattedMessage id="settings.company-openingHours" defaultMessage="Opening Hours"/>,
          icon: ["far", "calendar"],
          navLinkComponent: NavLinkDefaultComponent,
          navLinkProps: {
            to: `/settings/company-openingHours`,
          },
          tab: 19,
          onClick: onItemClick
        }
      ]
    }
  ]

  return [
    conversationsMenu,
    ticketsMenu,
    contactsMenu,
    ...settingsMenus.filter(el => el)
  ]
}

export default navigationConfig
