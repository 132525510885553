import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG } from '../../../configs/auth0Config';
import GoogleIcon from "../../../assets/img/icons/google-oauth2.png"

var queryParams = new URLSearchParams(window.location.search);

const LockLanguageDictionnary = {
    'fr': {
        title: '',
        signUpTitle: '',
		signUpSubmitLabel: 'Je crée mon compte',
		signUpTerms: "En créant votre compte, vous acceptez nos <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>CGV et CGU</a> ainsi que notre <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>Politique de Protections des données</a>.",
        // signUpTerms: "En créant votre compte, vous acceptez nos <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>Conditions Générales de Ventes et d'Utilisation</a>, ainsi que notre <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>Politique de Protection des Données</a>.",
        captchaCodeInputPlaceholder: '',
        captchaMathInputPlaceholder: '',
        error: {
            signUp: {
                social_signup_needs_terms_acception: ''
            }
        }
    },

    'en': {
        title: '',
        signUpTitle: '',
		signUpSubmitLabel: 'Sign Up',
		signUpTerms: "By creating your account, you accept our <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>T&Cs</a> as well as our <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>Data Protection Policy</a>.",
        // signUpTerms: "By signing up, you agree to our <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>terms of service</a> and <a href='https://www.reecall.co/fr/conditions-generales-de-vente-et-dutilisation-cgvu/' target='_blank'>privacy policy</a>.",
        captchaCodeInputPlaceholder: '',
        captchaMathInputPlaceholder: '',
        error: {
            signUp: {
                social_signup_needs_terms_acception: ''
            }
		}
    }
}


const LockDefaultConfig = (locale) => {
	let langOptions = LockLanguageDictionnary?.[locale];
	if(!langOptions){
		langOptions = LockLanguageDictionnary["en"];
	}
	let baseOptions = {
		configurationBaseUrl: "https://"+AUTH_CONFIG.domain,
		auth: {
			redirect: false,
			redirectUrl: window.location.origin + '/login?goto=' + escape(window.location.pathname),
			responseType: 'token id_token',
			autoParseHash: true,
			params: {
				scope: 'openid profile email'
			}
		},
		avatar: null,
		closable: false,
		container: AUTH_CONFIG.container,
		language: locale || 'en',
		allowSignUp: false,
		prefill: queryParams.has('email') ? { email: queryParams.get('email') } : {},
		languageDictionary: {
			...langOptions
		},
		rememberLastLogin: true,
		theme: {
			primaryColor: '#504ADB',
			logo: '//assets.reecall.co/logos/svg/logo_reecall_2020.svg',
			authButtons: {
				"google-oauth2": {
				  primaryColor: "#ffffff",
				  foregroundColor: "#504ADB",
				  icon: GoogleIcon
				}
			}
		},
		usernameStyle: 'email'
	}

	return {
		...baseOptions
	}
}

export { LockDefaultConfig }


const Lock = (options = {}) => {
	let locale = queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';
	return new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
		...LockDefaultConfig(locale),
		...options
	})
}

export default Lock;
