import React from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { toast } from "react-toastify";

import { updateCompanySettings } from "../../actions/company"

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TICKET_TAGS_PENDING = 'FETCH_TICKET_TAGS_PENDING';
export const FETCH_TICKET_TAGS_SUCCESS = 'FETCH_TICKET_TAGS_SUCCESS';
export const FETCH_TICKET_TAGS_ERROR = 'FETCH_TICKET_TAGS_ERROR';
export const UPDATE_TICKET_TAG = 'UPDATE_TICKET_TAG';
export const ADD_TICKET_TAG = 'ADD_TICKET_TAG';
export const DELETE_TICKET_TAG = 'DELETE_TICKET_TAG';
export const DELETE_TICKET_TAG_FROM_TICKET = 'DELETE_TICKET_TAG_FROM_TICKET';
export const FETCH_TICKET_TAG_FAMILIES = 'FETCH_TICKET_TAG_FAMILIES';
export const FETCH_MODEL_TAGS = 'FETCH_MODEL_TAGS';
export const FETCH_TICKET_TAG_DATA = 'FETCH_TICKET_TAG_DATA';
export const UPDATE_TICKET_TAG_DATA = 'UPDATE_TICKET_TAG_DATA';
export const UPDATE_TICKET_TAG_PENDING = 'UPDATE_TICKET_TAG_PENDING';
export const GET_DIRTY = "GET_DIRTY";
export const SET_TRAIN_STATUS = "SET_TRAIN_STATUS";

const cleanTagDatas = (tag) => {
    return {...tag, color: tag.color.trim()}
}

export const trainTags = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: SET_TRAIN_STATUS, status: "pending"});
        axios
        .post('https://tag.reecall.co/update', { companyId: getState().company.company.id })
        .then((response) => {
            dispatch(updateCompanySettings({"shouldTrainTags": false}, false));
            dispatch({type: SET_TRAIN_STATUS, status: "success"});
            toast.success(<FormattedMessage id="tag.training.success" defaultMessage="Training complete" />)
        }).catch(function (error) {
            toast.error(error)
            dispatch({type: SET_TRAIN_STATUS, status: "error"});
        });
    }
} 

function fetchTicketTagsPending() {
    return {
        type: FETCH_TICKET_TAGS_PENDING
    }
}

function fetchTicketTagsSuccess(ticketTags) {
    return {
        type: FETCH_TICKET_TAGS_SUCCESS,
        ticketTags: ticketTags
    }
}

function fetchTicketTagsError(error) {
    return {
        type: FETCH_TICKET_TAGS_ERROR,
        error: error
    }
}

export const fetchTicketTags = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchTicketTagsPending());

        return rcsdk.getTicketTags()
        .where({companyId: getState().company.company.id})
        .then(ticketTags => {
            dispatch(fetchTicketTagsSuccess(ticketTags));
        }).catch(err => {
            dispatch(fetchTicketTagsError(err.message));
        })
    }
}

export const fetchTags = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: FETCH_TAGS, status: "pending"});

        return rcsdk.getTags()
        .where({companyId: getState().company.company.id})
        .then(tags => {
            dispatch({type: FETCH_TAGS, status: "success", tags});
        }).catch(err => {
            dispatch({type: FETCH_TAGS, status: "error", error: err});
        })
    }
}

function addTicketTagSuccess(ticketTag, showToast = true){
    return {
        type: ADD_TICKET_TAG,
        status: "success",
        ticketTag: ticketTag,
        successToast: showToast ? {
            type: "ADD",
            message: <FormattedMessage id="ticketTags.toast.add" defaultMessage="Ticket Tag added successfully"/>
        } : null
    }
}

export const addTicketTag = (datas, sentences = [], showToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({ type: ADD_TICKET_TAG, status: "pending" });

        return rcsdk.createTicketTag(cleanTagDatas(datas))
        .then((ticketTag) => {

            rcsdk.createTicketTagData({
                companyId: ticketTag.companyId,
                ticketTagId: ticketTag.id,
                sentences: sentences,
            }).then((ticketTagData => dispatch(addTicketTagSuccess(ticketTag, showToast))))

            return ticketTag;

        }).catch(err => {
            dispatch({ type: ADD_TICKET_TAG, status: "error" });
            dispatch(fetchTicketTagsError(err.message));
        });
    }
}

function updateTicketTagSuccess(ticketTag){
    return {
        type: UPDATE_TICKET_TAG,
        ticketTag: ticketTag,
        status: "success",
        successToast: {
            type: "UPDATE",
            message: <FormattedMessage id="ticketTags.toast.update" defaultMessage="Ticket Tag updated successfully"/>
        }
    }
}

function updateTicketTagError(){
    return {
        type: UPDATE_TICKET_TAG,
        status: "error",
        errorToast: {
            type: "ERROR",
            message: <FormattedMessage id="ticketTags.toast.update.error" defaultMessage="error updating Ticket Tag" />
        }
    }
}

export const updateTicketTag = ({id, datas}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({ type: UPDATE_TICKET_TAG, status: "pending" });
        return rcsdk.updateTicketTag(id, cleanTagDatas(datas))
        .then((response) => {
            dispatch(updateTicketTagSuccess(response));
        }).catch(err => {
            dispatch(updateTicketTagError(err.message));
        });
    }
}

export const updateTicketTagData = (ticketTagDataId, data) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: UPDATE_TICKET_TAG_DATA, status: "pending" });
        return rcsdk.updateTicketTagData(ticketTagDataId, data)
            .then(resp => {
                if(getState().ticketTags.dirty === "sentences"){
                    let ticketTagId = getState().ticketTags.ticketTagDatas.find(el => el.id === ticketTagDataId).ticketTagId;
                    let shouldTrainTags = getState().company.companySettings?.value?.shouldTrainTags ? [...getState().company.companySettings?.value?.shouldTrainTags, ticketTagId] : [ticketTagId] ;
                    dispatch(updateCompanySettings({"shouldTrainTags": shouldTrainTags}, false));
                } 
                dispatch({ type: UPDATE_TICKET_TAG_DATA, status: "success", data: resp});
            }).catch(err => {
                dispatch({
                    type: UPDATE_TICKET_TAG_DATA,
                    error: err,
                    status: "error"
                });
            })
    }
}

export const createTicketTagData = (ticketTagId, sentences) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.createTicketTagData({
            companyId: getState().company.company.id,
            ticketTagId: ticketTagId,
            sentences: sentences || [],
        }).then((resp) => {
            dispatch({ type: UPDATE_TICKET_TAG_DATA, status: "success", data: resp});
        })
    }
}

function deleteTicketTagSuccess(ticketTagId){
    return {
        type: DELETE_TICKET_TAG,
        id: ticketTagId,
        successToast: {
            type: "DELETE",
            message: <FormattedMessage id="ticketTags.toast.delete" defaultMessage="Ticket Tag deleted successfully"/>
        }
    }
}

export const deleteTicketTag = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        // dispatch(fetchTicketTagsPending());

        return rcsdk.deleteTicketTag(id)
        .then((response) => {
            dispatch(deleteTicketTagSuccess(id));
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchTicketTagsError(err.message));
            } else {
                dispatch(deleteTicketTagSuccess(id));
            }
        });
    }
}

export const deleteTagInTagsAndTickets = (id) => {
    return (dispatch, getState, { rcsdk }) => {

        dispatch(deleteTicketTag(id))
        rcsdk.getTickets()
            .where({ tags: { elemMatch: { id } } })
            .then(tickets => {
                if (tickets.length > 0) tickets.forEach(ticket => {
                    if (ticket.companyId === getState().company.company.id) {
                        rcsdk
                            .updateTicket(ticket.id, { tags: [...ticket.tags.filter(ticketTag => ticketTag.id !== id).filter(ticketTag => !ticketTag.id.startsWith("tid:"))] })
                            .then(res => {
                                // dispatch({
                                //     type: DELETE_TICKET_TAG_FROM_TICKET,
                                //     successToast: {
                                //         type: "DELETE",
                                //         message: <FormattedMessage id="ticketTags.toast.deleteFromTicket" defaultMessage="Tag successfully deleted from ticket"/>
                                //     }
                                // })
                            })
                            .catch(err => console.log("update", err))
                    }
                })
            })
            .catch(err => console.log("getTickets", err))
    }
}




export const fetchTicketTagFamilies = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_TICKET_TAG_FAMILIES,
            status: "pending"
        })

        return rcsdk.getTicketTags().where({
            "and": [{
                "companyId": "*"
            }, {
                "parentTagId": {
                    "exists": false
                }
            }]
        }).then(ticketTagsFamilies => {
            dispatch({
                type: FETCH_TICKET_TAG_FAMILIES,
                status: "success",
                ticketTagsFamilies: ticketTagsFamilies
            })
        }).catch(err => {
            dispatch({
                type: FETCH_TICKET_TAG_FAMILIES,
                status: "error",
                error: err
            })
        });
    }
}


export const fetchModelTags = () => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: FETCH_MODEL_TAGS, status: "pending" });

        return rcsdk.getTicketTagDatas()
            .include(["ticketTag"])
            .where({ "companyId": "*" })
            .then(modelTags => {
                dispatch({
                    type: FETCH_MODEL_TAGS,
                    status: "success",
                    modelTags: modelTags
                });
            }).catch(err => {
                dispatch({ type: FETCH_MODEL_TAGS, status: "error", error: err });
            })
    }
}


export const fetchTicketTagData = (ticketsIdsArray) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: FETCH_TICKET_TAG_DATA, status: "pending" });

        return rcsdk.getTicketTagDatas()
            .where({ ticketTagId: { inq: ticketsIdsArray } })
            .then(data => {
                dispatch({ type: FETCH_TICKET_TAG_DATA, status: "success", ticketTagDatas: data });
            }).catch(err => {
                dispatch({
                    type: FETCH_TICKET_TAG_DATA,
                    status: "error",
                    error: err
                });
            })
    }
}
