import React from "react"
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { HOOK_TOAST } from "../../actions/pusher"

import ToastAssignMember from "../../../components/reecall/toasts/ToastAssignMember"

const SuccessIcon = ({type}) => {
    switch(type){
        case "EXECUTE_QA":
            return <FontAwesomeIcon 
                icon={["fas", "magic"]}
                className="mr-1"
            />
        case "UPDATE":
            return <FontAwesomeIcon 
                icon={["fas", "edit"]}
                className="mr-1"
            />

        case "DELETE":
            return <FontAwesomeIcon 
                icon={["fas", "trash"]}
                className="mr-1"
            />

        case "ARCHIVE":
            return <FontAwesomeIcon 
                icon={["fas", "box-archive"]}
                className="mr-1"
            />

        case "UNARCHIVE":
            return <FontAwesomeIcon 
                icon={["fas", "box-open"]}
                className="mr-1"
            />

        default:
        case "ADD":
            return <FontAwesomeIcon 
                icon={["fas", "plus"]}
                className="mr-1"
            />
    }
}

const initialState = {};
const toastsReducer = (state = initialState, action) => {
    switch(action.type){
        case HOOK_TOAST:
            switch(action.data.type){
                case "ticketUnAssigned":
                case "ticketAssigned":
                    toast.info(<ToastAssignMember {...action.data} />, {
                        autoClose: 10000,
                        className: "assignToast genericToast"
                    });
                    break;
                default:
                    break;
            }
            return {...state}
        default:
            if(action?.successToast?.message){
                toast.success(<>
                    <SuccessIcon type={action.successToast?.type} />
                    {action.successToast.message}
                </>, {})
            }
            return {...state}
    }
}
export default toastsReducer;