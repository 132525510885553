export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_PUSHED = 'MARK_AS_PUSHED';
export const TOGGLE_CENTER_MODAL = 'TOGGLE_CENTER_MODAL';
export const TOGGLE_FOOTER_MODAL = 'TOGGLE_FOOTER_MODAL';

export const addNotification = (notification) => {
    return dispatch => {
        dispatch({ type: ADD_NOTIFICATION, notification: notification })
    }
}

export const markAllNotificationsAsRead = () => {
    return dispatch => {
        dispatch({ type: MARK_ALL_AS_READ })
    }
}

export const markAsRead = (id) => {
    return dispatch => {
        dispatch({ type: MARK_AS_READ, id: id })
    }
}

export const markAsPushed = (id) => {
    return dispatch => {
        dispatch({ type: MARK_AS_PUSHED, id: id })
    }
}

export const toggleMarketingCenterModal = () => dispatch => dispatch({ type: TOGGLE_CENTER_MODAL })
export const toggleMarketingFooterModal = () => dispatch => dispatch({ type: TOGGLE_FOOTER_MODAL })


//Selectors
export const selectUnreadNotifications = state => state.notifications.notifications.filter(el => !el?.isSeen);
export const selectUnreadCenterMarketingNotifications = state => selectUnreadNotifications(state).filter(el => el.notificationType === "marketing" && el.notificationContainer === "center");
export const selectUnreadFooterMarketingNotifications = state => selectUnreadNotifications(state).filter(el => el.notificationType === "marketing" && el.notificationContainer === "footer");
export const selectToPushFooterMarketingNotifications = state => selectUnreadFooterMarketingNotifications(state).filter(el => el.toPush);