import {
    SHOW_QUICK_ACTION_MODAL,
    HIDE_QUICK_ACTION_MODAL,
} from '../../actions/quickActions';

const initialState = {
    showFormModal: false,
    currentQuickAction: null,
    currentContext: null,
    onDone: () => {},
    isLastStep: true,
    chainedActionLabels: []
}

const quickActionsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case SHOW_QUICK_ACTION_MODAL:
            return {
                ...state,
                showFormModal: true,
                currentQuickAction: {...action.quickAction},
                currentContext: action.context,
                onDone: action.onDone,
                isLastStep: action.isLastStep,
                chainedActionLabels: [...state.chainedActionLabels, action.quickAction.title]
            }
        case HIDE_QUICK_ACTION_MODAL:
            return {
                ...state,
                showFormModal: false,
                currentQuickAction: action.complete ? null : state.currentQuickAction,
                currentContext: action.complete ? null : state.currentContext,
                onDone: action.complete ? () => {} : state.onDone,
                isLastStep: true,
                chainedActionLabels: []
            }
        default: 
            return state;
    }
}
export default quickActionsReducer;