import {
    FETCH_QUICK_ACTION_LIBRARIES,
    ADD_QUICK_ACTION_LIBRARIES,
    DELETE_QUICK_ACTION_LIBRARIES,
    UPDATE_QUICK_ACTION_LIBRARIES
} from '../../actions/quickActionLibraries';

const initialState = {
    status: "pending",
    error: null,
    quickActionLibraries: []
}

const quickActionLibrariesReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_QUICK_ACTION_LIBRARIES:
        
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                quickActionLibraries: action.status === "success" ? [...action.quickActionLibraries] : [...state.quickActionLibraries]
            }

        case ADD_QUICK_ACTION_LIBRARIES:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                quickActionLibraries: action.status === "success" ? [...state.quickActionLibraries, action.quickActionLibrary] : [...state.quickActionLibraries]
            }

        case UPDATE_QUICK_ACTION_LIBRARIES:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                quickActionLibraries: action.status === "success" ? [...state.quickActionLibraries.map(quickActionLibrary => {
                    return quickActionLibrary.id !== action.quickActionLibrary.id ? {...quickActionLibrary} : {...quickActionLibrary, ...action.quickActionLibrary}
                })] : [...state.quickActionLibraries]
            }
            
        
        case DELETE_QUICK_ACTION_LIBRARIES:
            return {
                ...state, 
                quickActionLibraries: action.status === "success" ? [...state.quickActionLibraries.filter(el => el.id !== action.id)] : [...state.quickActionLibraries]
            }

        default: 
            return state;
    }
}


export default quickActionLibrariesReducer;