// You can customize the theme with the help of this file

//Template config options
const themeConfig = {
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  disableThemeTour: false, // options[Boolean]: true, false(default)
  direction: "ltr" // options[String] : ltr / rtl
};

export default themeConfig;
