import {fetchMembers} from "../members";
import { fetchTicketTags } from "../ticketTags";
import { fetchTags } from "../tags";
import { fetchDecks } from "../deck";
import { fetchTeams } from "../teams";
import { fetchAgents } from "../agents";
import { fetchCompanySettings } from "../company";
import { fetchInvitations } from "../../../redux/actions/invitations"
import { fetchIntegrations } from "../../../redux/actions/integrations"
import { fetchChannelConnections, fetchChannels } from "../../../redux/actions/channels"
import { fetchQuickActionLibraries } from "../quickActionLibraries";

export const SET_APP_TITLE = 'SET_APP_TITLE';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const LOAD_ALL_MODELS = 'LOAD_ALL_MODELS';


export const setAppTitle = (title) => {
    return dispatch => {
        dispatch({ type: SET_APP_TITLE, title: title })
    }
}

export const setAppLoaded = () => {
    return dispatch => {
        dispatch({ type: SET_APP_LOADED })
    }
}

export const loadAllModels = () => {
    return async (dispatch) => {

        await Promise.all([
            dispatch(fetchCompanySettings()),
            dispatch(fetchAgents()),
            dispatch(fetchTeams()),
            dispatch(fetchTicketTags()),
            dispatch(fetchTags()),
            dispatch(fetchMembers({flush: false})),
            dispatch(fetchInvitations()),
            dispatch(fetchDecks()),
            dispatch(fetchIntegrations()),
            dispatch(fetchQuickActionLibraries()),
            dispatch(fetchChannels()),
            dispatch(fetchChannelConnections())
        ]);
        
        return dispatch(setAppLoaded());
    }
}

