import {
	SET_APP_TITLE,
	SET_APP_LOADED
} from '../../actions/app';
import {MEMBER_ADDED} from '../../actions/pusher';

const initState = {
	loaded: false,
	version: "1.2",
	headers: {
		title: "Reecall"
	},
	pusherConnected: false
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
	
	case "PUSHER-REDUX/CONNECTED":
		return {
			...state,
			pusherConnected: true
		}

	case MEMBER_ADDED:
		console.log(action, "MEMBER_ADDED action");
		return { ...state }

	case SET_APP_LOADED:
		return {
			...state,
			loaded: true
		}

	case SET_APP_TITLE:
		return {
			...state,
			headers: {
				...state.headers,
				title: action.title
			}
		}

    default: {
    	return state
    }
  }
}

export default appReducer;