import {
    FETCH_COLLECTED_DATAS
} from '../../actions/collectedDatas';

const initialState = {
    status: null,
    error: null,
    collectedDatas: []
}

const collectedDatasReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_COLLECTED_DATAS:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                collectedDatas: action.status === "success" ? [...action.collectedDatas] : [...state.collectedDatas]
            }

        default: 
            return state;
    }
}


export default collectedDatasReducer;