import {
    TOGGLE_DRAWER
} from '../../actions/zendesk';

const initialState = {
    drawerShow: false,
    drawerTicketId: null,
    drawerParentTicketId: null
}

const zendeskReducer = (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawerShow: !state.drawerShow,
                drawerTicketId: action.payload?.ticketId || null,
                drawerParentTicketId: action.payload?.parentTicketId || null,
            }
        default: 
            return state;
    }
}
export default zendeskReducer;