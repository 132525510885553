import axios from "axios";
import { FAQ_URL } from "../configs/constants"

class FaqService {
    constructor(isOldInfra){
        this.axios = axios.create({
            headers: {
              post: {
                'Content-Type': 'application/json' 
              }
            },
            baseURL: `${isOldInfra ? "https://faq.reecall.io" : FAQ_URL}/`
        })
    }

    getScore = ({question = "", answer = "", lang = "fr", faqId = null}) => {
        return this.axios.post(`score`, { question, answer, lang })
        .then(response => {
            return { feedback: response.data, faqId: faqId};
        })
    }

    update = () => {
        return this.axios.post('update');
    }

    search = ({companyId = null, text = ""}) => {
        if(!companyId) return false;

        return this.axios.post('search', {
            "companyId": companyId,
            "text": text
        }).then(resp => {
            return resp?.data;
        })
    }

}

export default FaqService;