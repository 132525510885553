import React from "react"
import { FormattedMessage } from "react-intl"
import { omit } from "lodash"
import moment from "moment"
import {
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfDay,
    endOfWeek,
    endOfMonth,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,
    addMinutes,
    addHours,
    addDays,
    addWeeks,
    addMonths,
    format
} from "date-fns"
import { getRequestWhere } from "../../../utility/utils"

export const FETCH_DASHBOARDS       = 'FETCH_DASHBOARDS'
export const ADD_DASHBOARD          = 'ADD_DASHBOARD'
export const UPDATE_DASHBOARD       = 'UPDATE_DASHBOARD'
export const DELETE_DASHBOARD       = 'DELETE_DASHBOARD'

export const fetchDashboards = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_DASHBOARDS,
            status: "pending"
        });
        return rcsdk
            .getDashboards()
            .then(dashboards => {
                dispatch({
                    type: FETCH_DASHBOARDS,
                    status: "success",
                    dashboards: dashboards
                });
        }).catch(err => {
            dispatch({
                type: FETCH_DASHBOARDS,
                status: "error",
                error: err
            });
        })
    }
}


export const addDashboard = ({datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: ADD_DASHBOARD,
            status: "pending"
        });

        let formattedDashboard = {
            ...datas,
            companyId: getState().company.company.id
        }

        return rcsdk.createDashboard(formattedDashboard)
        .then((dashboard) => {
            dispatch({
                type: ADD_DASHBOARD,
                status: "success",
                dashboard: dashboard,
                successToast: showToast ? {
                    type: "ADD",
                    message: <FormattedMessage id="dashboard.toast.add" defaultMessage="Dashboard added successfully"/>
                } : null
            });
            return dashboard;
        }).catch(err => {
            dispatch({
                type: ADD_DASHBOARD,
                status: "error",
                error: err
            });
            return err.message;
        });
    }
}

export const updateDashboard = ({id, datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({
            type: UPDATE_DASHBOARD,
            status: "pending"
        });

        return rcsdk.updateDashboard(id, omit(datas, ["id", "createdAt", "updatedAt", "companyId", "isDeleted"]))
        .then((response) => {
            dispatch({
                type: UPDATE_DASHBOARD,
                status: "success",
                id: id,
                dashboard: response,
                successToast: showToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="dashboard.toast.update" defaultMessage="Dashboard updated successfully"/>
                } : null
            });
            return response;
        }).catch(err => {
            dispatch({
                type: UPDATE_DASHBOARD,
                status: "error",
                error: err
            });
        });
    }
}

export const deleteDashboard = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: DELETE_DASHBOARD,
            status: "pending"
        });

        return rcsdk.deleteDashboard(id)
        .then((response) => {
            dispatch({
                type: DELETE_DASHBOARD,
                status: "success",
                id: id,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="dashboard.toast.delete" defaultMessage="Dashboard deleted successfully"/>
                }
            });
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch({
                    type: DELETE_DASHBOARD,
                    status: "error",
                    error: err
                });
            } else {
                dispatch({
                    type: DELETE_DASHBOARD,
                    status: "success",
                    id: id,
                    successToast: {
                        type: "DELETE",
                        message: <FormattedMessage id="dashboard.toast.delete" defaultMessage="Dashboard deleted successfully"/>
                    }
                });
            }
        })
    }
}

export const updateDashboardsOrder = (newOrder) => {
    return (dispatch, getState, {rcsdk}) => {
        let promisesUpdate = newOrder.map(el => {
            return dispatch(updateDashboard({
                id: el.id,
                datas: {
                    order: el.order
                },
                showToast: false
            }))
        })

        return Promise.allSettled(promisesUpdate).then(allResp => {
            dispatch({
                type: "UPDATE_DASHBOARDS_ORDER",
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="dashboard.toast.reorder" defaultMessage="Dashboard reorder successfully"/>
                }
            });
        })
    }
}

//Return formatted date range
const formatDateRange = (dateRange, unit) => {
    let formattedDateRange = {
        startDate: dateRange?.startDate || null,
        endDate: dateRange?.endDate || null
    }
    switch(unit){
        default:
        case "minute":
        case "hour":
        case "day":
            formattedDateRange.startDate = dateRange?.startDate ? startOfDay(dateRange.startDate) : null
            formattedDateRange.endDate = dateRange?.endDate ? endOfDay(dateRange.endDate) : null
            break;
        case "week":
            formattedDateRange.startDate = dateRange?.startDate ? startOfWeek(dateRange.startDate) : null
            formattedDateRange.endDate = dateRange?.endDate ? endOfWeek(dateRange.endDate) : null
            break;
        case "month":
            formattedDateRange.startDate = dateRange?.startDate ? startOfMonth(dateRange.startDate) : null
            formattedDateRange.endDate = dateRange?.endDate ? endOfMonth(dateRange.endDate) : null
            break;
    }
    
    return formattedDateRange;
}

const addUnit = (date, unit, amount) => {
    switch(unit){
        case "minute":
            return addMinutes(date, amount);
        case "hour":
            return addHours(date, amount);
        default:
        case "day":
            return addDays(date, amount);
        case "week":
            return addWeeks(date, amount);
        case "month":
            return addMonths(date, amount);
    }
}

//Fill missing unit time with empty value
const fulfillDatas = (datas, dateRange, unit) => {
    let formattedDateRange = formatDateRange(dateRange, unit);
    let formattedDatas = [];
    let diffInUnit;
    switch(unit){
        case "minute":
            diffInUnit = differenceInMinutes(formattedDateRange.endDate, formattedDateRange.startDate)
            break;
        case "hour":
            diffInUnit = differenceInHours(formattedDateRange.endDate, formattedDateRange.startDate)
            break;
        default:
        case "day":
            diffInUnit = differenceInDays(formattedDateRange.endDate, formattedDateRange.startDate)
            break;
        case "week":
            diffInUnit = differenceInWeeks(formattedDateRange.endDate, formattedDateRange.startDate)
            break;
        case "month":
            diffInUnit = differenceInMonths(formattedDateRange.endDate, formattedDateRange.startDate)
            break;
    }

    let startDate = new Date(formattedDateRange.startDate);
    for(let i = 0; i <= diffInUnit; i++){
        let curDate = format(addUnit(startDate, unit, i), ["minute", "hour"].includes(unit) ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd");
        let findedDate = datas.find(el => el["_id"] === curDate)
        if(findedDate){
            formattedDatas.push(findedDate)
        } else {
            formattedDatas.push({"_id": curDate, count: 0})
        }
    }

    return formattedDatas.map(({_id, count}) => {
        return [moment.utc(_id).valueOf(), count]
    })
}

//map model to mongo model
const mapModels = {
    "conversation": "Conversation",
    "ticket": "NewTicket",
    "contact": "Contact",
    "collection": "CollectionData",
}

//Fetch stat datas by widget and dateRange
export const fetchWidgetDatas = (widget, dateRange) => {
    return (dispatch, getState, {rcsdk}) => {
        let statsPromises = [];
        let groupsBy = [];
        if(widget?.sources?.length > 0){
            widget.sources.forEach((source, sourceIdx) => {
                let model = source.source;
                if(mapModels?.[model]) model = mapModels[model];

                /// Group by ///
                let groupBy = source?.group || "total";
                groupsBy.push(groupBy);
                ////////////////


                ///// Where ////
                let where = getRequestWhere({
                    companyId: getState().company.company.id,
                    filters: source.filters,
                    toMongo: true
                })

                if(source.source === "conversation" && source?.sourceConfig?.agentId){
                    where.push({agentId: {$eq: source.sourceConfig.agentId}});
                }

                if(source.source === "collection" && source?.sourceConfig?.id){
                    where.push({collectionId: {$eq: source.sourceConfig.id}});
                }
                ////////////////


                /// Date Range ///
                let formattedDateRange;
                if(groupBy === "total"){ // si le groupement est "total"
                    //Si la source est de type "collection" => pas de filtre de période
                    //Sinon on ajout au "where" le filter de période
                    if(source.source !== "collection"){
                        formattedDateRange = formatDateRange(dateRange, "day")
                        if(dateRange?.startDate) where.push({createdAt: {$gte: `date:${formattedDateRange.startDate.toISOString()}`}})
                        if(dateRange?.endDate) where.push({createdAt: {$lte: `date:${formattedDateRange.endDate.toISOString()}`}})
                    }
                } else { // si le groupement est une unité de temps (heure, jour)
                    formattedDateRange = formatDateRange(dateRange, groupBy)
                }
                //////////////////
                
                
                where = {"$and": [...where]}

                if(groupBy === "total"){ //Si le groupement en "total" => on utilise la méthode "count"
                    statsPromises.push(rcsdk.Stats.getCount(model).where(where))
                } else { //Sinon on utilise la méthode "getCountByPeriod"
                    statsPromises.push(
                        rcsdk.Stats.getCountByPeriod(model)
                        .period(formattedDateRange?.startDate, formattedDateRange?.endDate, groupBy)
                        .where(where)
                    );
                }
            })

            //on récupère et formate les stats de chaques sources
            return Promise.allSettled(statsPromises).then(respAll => {
                const statsResults = [];
                respAll.forEach((resp, respIdx) => {
                    if(resp.status === "fulfilled"){
                        let respDatas = resp.value;
                        statsResults.push({
                            count: respDatas?.count ?? respDatas.reduce((a, b) => a + (b["count"] || 0), 0),
                            serie: groupsBy[respIdx] !== "total" ? {
                                name: widget.sources[respIdx]?.label,
                                data: fulfillDatas(respDatas, dateRange, groupsBy[respIdx])
                            }: null,
                            periodUnit: groupsBy[respIdx] !== "total" ? groupsBy[respIdx] : null
                        })
                    }
                })

                return {
                    config:{
                        periodUnit: statsResults[0]?.periodUnit
                    },
                    data: {
                        title: widget.title,
                        // selon la configuration du widget, 
                        // le total est égal à la somme des totaux des sources ou au total d'une source spécifique
                        count: (widget?.appearance?.totalSourceIndex === "sum" || typeof widget?.appearance?.totalSourceIndex === "undefined")
                            ? statsResults.reduce((a, b) => a + (b["count"] || 0), 0) 
                            : statsResults?.[widget?.appearance?.totalSourceIndex]?.count || 0,
                        series: statsResults.map(el => el?.serie).filter(el => el)
                    }
                }
            })
        } else {
            return new Promise((resolve) => {
                resolve({
                    ...widget,
                    data: Object.assign({
                        title: widget.title
                    }, widget?.data ? widget.data : null)
                })
            })
        }
    }
}


export const selectLOrderedDashboards = (state) => state.dashboards.dashboards.sort((a,b) => a.order === b.order ? 0 : a.order > b.order ? 1 : -1);