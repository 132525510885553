import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

const Phone = ({phoneNumber, className, callOnClick, withIcon, missingText = "N/A", format = PNF.E164, ...rest}) => {
	let formatedPhoneNumber = false;

	if(typeof phoneNumber !== "undefined"){
		try {
			const parsedPhone = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FR');
			if(format !== "default"){
				formatedPhoneNumber = phoneUtil.format(parsedPhone, format);
			} else {
				formatedPhoneNumber = phoneNumber;
			}
	    } catch(err) {
	        console.log(err.message, "err");
	    };
	}

	const CallPhone = () => {
		if(formatedPhoneNumber){
			window.location.href = `tel:${formatedPhoneNumber}`;
		}
	}

	return <div 
		className={`${className} ${callOnClick ? "cursor-pointer" : ""}`}
		onClick={callOnClick ? CallPhone : null}
		style={{
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		}}
		{...rest}
	>
		{ withIcon && formatedPhoneNumber && <FontAwesomeIcon icon={faPhoneAlt} className="mr-50" /> }
		{ formatedPhoneNumber || missingText }
	</div>;
};

Phone.defaultProps = {
  className: "",
  phoneNumber: null,
  callOnClick: true,
  withIcon: false
};

export default Phone;
