import {
    FETCH_INTEGRATIONS_PENDING,
    FETCH_INTEGRATIONS_SUCCESS,
    FETCH_INTEGRATIONS_ERROR,

    FETCH_INTEGRATION_CONNECTIONS_PENDING,
    FETCH_INTEGRATION_CONNECTIONS_SUCCESS,
    FETCH_INTEGRATION_CONNECTIONS_ERROR,

    CREATE_INTEGRATION_CONNECTION,
    UPDATE_INTEGRATION_CONNECTION,
    DELETE_INTEGRATION_CONNECTION,
    SET_SHOW_INTEGRATION_CTA_MODAL,
    SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL
} from '../../actions/integrations';

const initialState = {
    pending: false,
    integrations: null,
    integrationConnections: null,
    error: null,
    showCTAModal: false,
    showDetailCTAModal: false
}

const integrationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_INTEGRATIONS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                integrations: action.integrations
            }
        case FETCH_INTEGRATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FETCH_INTEGRATION_CONNECTIONS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_INTEGRATION_CONNECTIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                integrations: [...state.integrations.map(integration => {
                    return {
                        ...integration,
                        integrationConnectionId: action.integrationConnections.find(el => el.integrationId === integration.id)?.id
                    }
                })],
                integrationConnections: action.integrationConnections
            }
        case FETCH_INTEGRATION_CONNECTIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case CREATE_INTEGRATION_CONNECTION:
            return {
                ...state,
                integrations: [
                    ...state.integrations.map(integration => {
                        return {
                            ...integration,
                            integrationConnectionId: integration.id === action.integrationConnection.integrationId ? action.integrationConnection.id : integration?.integrationConnectionId
                        }
                    })
                ],
                integrationConnections: [
                    ...state.integrationConnections,
                    action.integrationConnection
                ]
            }

        case UPDATE_INTEGRATION_CONNECTION:
            return {
                ...state,
                integrationConnections: [
                    ...state.integrationConnections.map(el => {
                        return el.id !== action.integrationConnection.id ? { ...action.integrationConnection.data } : { ...el }
                    })
                ]
            }
        
        case DELETE_INTEGRATION_CONNECTION:

            return {
                ...state,
                integrations: [
                    ...state.integrations.map(integration => {
                        return {
                            ...integration,
                            integrationConnectionId: integration.id === action.integrationId ? null : integration?.integrationConnectionId
                        }
                    })
                ],
                integrationConnections: [
                    ...state.integrationConnections.filter(el => el.id !== action.integrationConnectionId)
                ]
            }

        case SET_SHOW_INTEGRATION_CTA_MODAL:
            return {
                ...state,
                showCTAModal: action.payload || false
            }

        case SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL:
            return {
                ...state,
                showDetailCTAModal: action.payload || false
            }

        default: 
            return state;
    }
}
export default integrationsReducer;