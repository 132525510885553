import {
    H2H_RESET,
    H2H_START_CALL,
    H2H_SAVE_LAST_STEP,
    H2H_ADD_STEP,
    H2H_SET_CURRENT_STEP,
    H2H_END_CALL,
    H2H_ADD_NOTE,
    H2H_SET_FAQS,
    H2H_SET_SCENARIOS,
    H2H_OPEN_SUGGESTION,
    H2H_CLOSE_SUGGESTION,
    H2H_SET_SUGGESTION_SEARCH,
    H2H_LOAD_SUGGESTION,
    H2H_TOGGLE_ADD_NOTE,
    H2H_LOADING_STEP,
    H2H_SET_CONV_ID
} from '../../actions/h2h';

import { PUSHER_H2H } from "../../actions/pusher/index"

const initialState = {
    currentStepId: null,
    lastStep: null,
    steps: [],
    callContactId: null,
    callContactPhone: null,
    callUid: null,
    callStartDate: null,
    suggestionLoading: false,
    suggestionOpen: false,
    suggestionSearch: "",
    suggestions: [],
    faqs: [],
    scenarios: [],
    showModalAddAdditionalInformation: false,
    conversationId: null,
    classifierName: null,
    loadingStepId: null,
    loadedStepIds: []
}


function populateStep(step, steps) {
    return {
        ...step,
        id: `H2H-part-${steps.length + 1}`,
        odd: step.type === "message"
    }
}

const h2hReducer = (state = initialState, action) => {
    switch(action.type) {

        case H2H_ADD_NOTE:
        case PUSHER_H2H:
            return {
                ...state
            }

        case H2H_RESET:
            return {
                ...state,
                currentStepId: null,
                lastStep: null,
                steps: [],
                callContactId: null,
                callContactPhone: null,
                callUid: null,
                callStartDate: null,
                suggestionOpen: false,
                suggestionSearch: "",
                suggestions: [],
                faqs: [],
                scenarios: [],
                showModalAddAdditionalInformation: false,
                conversationId: null,
                classifierName: null
            }

        case H2H_START_CALL:
            return {
                ...state,
                steps: [],
                callContactId: action.contactId,
                callContactPhone: action.contactPhone,
                callUid: action.uid,
                callStartDate: action.startDate,
                classifierName: action.classifierName
            }

        case H2H_SAVE_LAST_STEP:
            return {
                ...state,
                lastStep: action.step
            }

        case H2H_ADD_STEP:
            const newStep = populateStep(action.step, state.steps);
            return {
                ...state,
                steps: [...state.steps, newStep],
                currentStepId: action.scrollTo ? newStep.id : state.currentStepId
            }

        case H2H_SET_CURRENT_STEP:
            return {
                ...state,
                currentStepId: action.stepId
            }

        case H2H_LOADING_STEP:
            return {
                ...state,
                loadingStepId: action.stepId,
                loadedStepIds: action.stepId ? [...state.loadedStepIds, action.stepId] : [...state.loadedStepIds]
            }

        case H2H_END_CALL:
            return {
                ...state,
                showModalAddAdditionalInformation: true
            }

        case H2H_SET_FAQS:
            return {
                ...state,
                faqs: action.faqs
            }

        case H2H_SET_SCENARIOS:
            return {
                ...state,
                scenarios: action.scenarios
            }

        case H2H_SET_SUGGESTION_SEARCH:
            return {
                ...state,
                suggestionSearch: action.search
            }

        case H2H_LOAD_SUGGESTION:
            return {
                ...state,
                suggestionLoading: action.loading || false
            }
        
        case H2H_OPEN_SUGGESTION:
            return {
                ...state,
                suggestionOpen: true
            }

        case H2H_CLOSE_SUGGESTION:
            return {
                ...state,
                suggestionSearch: "",
                suggestionOpen: false,
                suggestions: [],
                faqs: [],
                scenarios: []
            }

        case H2H_SET_CONV_ID:
            return {
                ...state,
                conversationId: action.convId
            }

        case H2H_TOGGLE_ADD_NOTE:
            return {
                ...state,
                showModalAddAdditionalInformation: !state.showModalAddAdditionalInformation
            }
    
        default: 
            return {...state}
    }
}

export default h2hReducer;