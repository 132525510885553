import React from "react"
import { FormattedMessage } from "react-intl";
import { pick, omit} from "lodash"
import { getRequestWhere } from "../../../utility/utils"
import { RPAHook_AfterImport } from "../import/index" 

export const FETCH_CONTACTS_PENDING = 'FETCH_CONTACTS_PENDING';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";


function fetchContactsPending() {
    return {
        type: FETCH_CONTACTS_PENDING
    }
}

function fetchContactsSuccess(contacts, total) {
    return {
        type: FETCH_CONTACTS_SUCCESS,
        contacts: contacts,
        total: total
    }
}

function fetchContactsError(error) {
    return {
        type: FETCH_CONTACTS_ERROR,
        error: error
    }
}

export const getContacts = ({
    limit,
    skip = 0,
    searchTerm = "",
    filters,
    order = "createdAt DESC"
}) => {
    return (dispatch, getState, {rcsdk}) => {
        let isSearch = searchTerm !== "";
        let loopbackFilters = getRequestWhere({
            companyId: getState().company.company.id,
            filters,
            toMongo: isSearch
        });

        let request;
        if(isSearch) {
            request = `atlasSearchContacts`;
        } else {
            request = `getContacts`;
        }

        let requestWhere = loopbackFilters?.length > 0 ? {[isSearch ? "$and" : "and"]: [
            ...loopbackFilters
        ]} : {}

        console.groupCollapsed(`RCSDK contact (${request})`);
        console.log(searchTerm, "searchTerm");
        console.log(filters, "filters");
        console.log(order, "order");
        console.log(limit, "limit");
        console.log(skip, "skip");
        console.log(requestWhere, "requestWhere");
        console.groupEnd();

        return rcsdk[request](searchTerm)
        .skip(skip)
        .limit(limit)
        .raw()
        .where(requestWhere)
        .order(order)
        .then((resp) => {
            const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
            return {data: resp.data.map(contact => contact), total: total}
        })
        .catch(err => {
        })
    }
}

export const fetchContacts = ({
    limit,
    skip,
    searchTerm,
    filters,
    order = "createdAt DESC"
}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchContactsPending());
        return dispatch(getContacts({
            limit,
            skip,
            searchTerm,
            filters,
            order
        }))
        .then(({data, total}) => {
            dispatch(fetchContactsSuccess(data, total));
            return {data, total}; 
        })
        .catch(err => {
            dispatch(fetchContactsError(err.message));
        })
    }
}

export const getContactActivities = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getConversations()
        .where({"contactId": { "eq" : id }})
        .order("createdAt DESC")
        .then(convs => {
            return rcsdk.getContact(id)
            .fields({events: true, formattedMeta: false})
            .then(contact => {
                return ([
                    ...(contact?.events ? contact.events.map(event => {
                        return {
                            type: "event",
                            obj: event,
                            createdAt: event.createdAt
                        }
                    }) : []),
                    ...convs.map(conv => {
                        return {
                            type: "conversation",
                            obj: conv,
                            createdAt: conv?.messages?.[0]?.time || conv.createdAt
                        }
                    })
                ]).sort((a, b) => (b?.time || b?.createdAt).localeCompare(a?.time || a?.createdAt))
            })
        })
    }
}


function addContactSuccess(contact, showToast = true){
    return {
        type: ADD_CONTACT,
        contact: contact,
        successToast: showToast ? {
            type: "ADD",
            message: <FormattedMessage id="contacts.toast.add" defaultMessage="Contact added successfully"/>
        } : null
    }
}

export const addContact = (datas, showToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        let formattedDatas = {
            ...datas,
            companyId: getState().company.company.id,
            // phone: datas.phone.filter(el => el !== null && el !== "").map(el => formatPhoneNumberForContact(el)),
            phone: datas.phone.filter(el => el !== null && el !== ""),
            email: datas.email.filter(el => el !== null && el !== "")
        }

        return rcsdk.createContact(formattedDatas)
        .then((contact) => {

            let contactMetaCfg = getState().company?.companySettings?.value?.contactMeta;
            let formattedContact = {
                ...contact,
                formattedMeta: contactMetaCfg ? contactMetaCfg.map(metaCfg => {
                    return {
                        value: contact?.meta?.[metaCfg.internalId],
                        ...metaCfg
                    }
                }) : [],
                company: {
                    ...getState().user.rcuser.company
                }
            };

            dispatch(addContactSuccess({
                ...formattedContact
            }, showToast));
            return formattedContact;
        }).catch(err => {
            return err
        });
    }
}


function updateContactSuccess(contact, showToast){
    return {
        type: UPDATE_CONTACT,
        contact: contact,
        successToast: showToast ? {
            type: "UPDATE",
            message: <FormattedMessage id="contacts.toast.update" defaultMessage="Contact updated successfully"/>
        } : null
    }
}

export const updateContact = ({id, datas, showToast = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        let formattedDatas = {
            ...datas,
            // phone: datas.phone.filter(el => el !== null && el !== "").map(el => formatPhoneNumberForContact(el)),
            phone: datas.phone.filter(el => el !== null && el !== ""),
            email: datas.email.filter(el => el !== null && el !== "")
        }
        
        return rcsdk.updateContact(id, formattedDatas).then((response) => {
            let contactMetaCfg = getState().company?.companySettings?.value?.contactMeta;
            let contact = {
                ...response,
                formattedMeta: contactMetaCfg ? contactMetaCfg.map(metaCfg => {
                    return {
                        value: response?.meta?.[metaCfg.internalId],
                        ...metaCfg
                    }
                }) : []
            };
            
            return dispatch(updateContactSuccess(contact, showToast));
        }).catch(err => {
            dispatch(fetchContactsError(err.message));
            return err;
        });
    }
}

export const editConversationContact = ({conversationId, datas}) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.editConversationContact(conversationId, datas)
    }
}


const formatContactForImport = (contactRow, contactMetasKeys) => {
    let baseHeaders = ["lastName", "firstName", "bio", "photo", "phone", "email", "id"]
    let multipleHeaders = ["phone", "email"]
    let multipleHeadersRegexp = multipleHeaders.map(el => new RegExp(`(${el})(\\d+)`, 'gm'))
    let metas = pick(contactRow, contactMetasKeys);
    let contactRowWithoutMeta = omit(contactRow, contactMetasKeys);

    let formattedContact = {
        ...pick(contactRowWithoutMeta, baseHeaders),
        phone: contactRowWithoutMeta?.phone ? [contactRowWithoutMeta.phone] : [],
        email: contactRowWithoutMeta?.email ? [contactRowWithoutMeta.email] : [],
        meta: {
            ...metas
        }
    }

    Object.entries(contactRowWithoutMeta).forEach(([key, value]) => {
        multipleHeadersRegexp.forEach(regexp => {
            let matches = [...key.matchAll(regexp)];
            if(matches.length > 0 && value){
                formattedContact[matches[0][1]].push(value);
            }
        })
    })

    return formattedContact;
}

export const importContacts = ({datas}) => {
    return (dispatch, getState, {rcsdk}) => {
        const companySettings = getState().company.companySettings;
        let contactMetasKeys = companySettings.value?.contactMeta.map(el => el.internalId);
        let formattedContacts = [];
        let state = {
            total: datas.length,
            created: 0,
            updated: 0,
            error: 0,
            errorsDetail: []
        }

        let promisesCheckExist = datas.map(contactRow => {
            return rcsdk.getContacts()
            .where({phone: contactRow.phone})
            .then(resp => {
                if(resp.length === 0){
                    return {...contactRow}
                } else {
                    return {...contactRow, id: resp[0].id}
                }
            })
        })

        return Promise.allSettled(promisesCheckExist).then(allResp => {
            allResp.forEach(resp => {
                if(resp.status === "fulfilled"){
                    let formattedContact = formatContactForImport(resp.value, contactMetasKeys);
                    formattedContacts.push(formattedContact);
                }
            })

            let promisesImport = formattedContacts.map(formattedContact => {
                if(formattedContact?.id){
                    return dispatch(updateContact({
                        id: formattedContact.id,
                        datas: formattedContact,
                        showToast: false
                    }))
                } else {
                    return dispatch(addContact(formattedContact, false))
                }
            })

            return Promise.allSettled(promisesImport).then(respAll => {
                respAll.forEach(resp => {
                    if(resp.status === "fulfilled"){
                        if(resp.value.error){
                            state.error += 1;
                            if(resp.value?.error?.response?.data?.error?.details){
                                resp.value.error.response.data.error.details.forEach((errDetail) => {
                                    state.errorsDetail.push(`${errDetail.error} (${errDetail.value})`);
                                })
                            }
                        } else if(resp.value?.type === "UPDATE_CONTACT"){
                            state.updated += 1;
                        } else {
                            state.created += 1;
                        }
                    } else if(resp.status === "rejected"){
                        // state.error.push(resp.reason);
                        state.error += 1;
                    }
                })

                dispatch(RPAHook_AfterImport({
                    model: "contacts",
                    results: {
                        total: state.total,
                        created: state.created,
                        updated: state.updated
                    }
                }));

                return state;
            })
        })
    }
}


const getContactPhoneNumber = (contactId) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getContact(contactId)
        .fields({phone: true, formattedMeta: false})
        .then((resp) => {
            return resp?.phone?.[0] || null;
        })
    }
}

const getContactByPhoneNumber = (phoneNumber) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk.getContacts()
        .where({
            phone: phoneNumber
        })
        .then((resp) => {
            if(!resp?.[0]) return false;
            return resp?.[0]
        })
    }
}

export {getContactPhoneNumber, getContactByPhoneNumber}