
import React, {useCallback, useEffect, useState} from "react"
import { Spinner } from "reactstrap"
import { NavLink } from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import classnames from "classnames"

import "react-contexify/dist/ReactContexify.min.css"
import "../../../assets/scss/plugins/extensions/context-menu.scss"

import useInterval from '../../../utility/customHooks/useInterval'
import useWindowSize from '../../../utility/customHooks/windowSize'

import navigationConfig from "../../../configs/navigationConfig"
import { fetchNavBarTicketTotal } from '../../../redux/actions/tickets';
import { fetchNavBarConversationTotal } from '../../../redux/actions/conversations';

import { RcMenu, Tooltip } from "@reecall/components_library/build"
import { FormattedMessage } from "react-intl"

const Sidebar = ({
    activePath,
}) => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize({});
    const app = useSelector(state => state.app);
    const mainMenuOpen = useSelector(state => state.menu.mainMenuOpen);
    const totalTicketNavBar = useSelector(state => state.tickets.totalNavBar);
    const totalConversationNavBar = useSelector(state => state.conversations.totalNavBar);
    const [navConfig, setNavConfig] = useState(null);
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        dispatch(fetchNavBarTicketTotal());
        dispatch(fetchNavBarConversationTotal());
    }, [dispatch]);

    useInterval(() => {
        dispatch(fetchNavBarTicketTotal());
        dispatch(fetchNavBarConversationTotal());
    }, 300000);

    useEffect(() => {
        setNavConfig(navigationConfig({
            dispatch: dispatch,
            ticketTotal: totalTicketNavBar,
            conversationTotal: totalConversationNavBar
        }))
    }, [dispatch, totalTicketNavBar, totalConversationNavBar]);

    useEffect(() => {
        setActiveMenu(activePath.split("/")[1]);
    }, [activePath]);

    const renderMenu = useCallback(() => {
        if(!navConfig && activeMenu !== "") return <Spinner color="primary" className="m-auto" />;
        if(activeMenu === "") return null;
        return <RcMenu
            config={
                navConfig
                .filter(el => el)
                .filter(el => el.id.startsWith(activeMenu))
            }
        />
    }, [activeMenu, navConfig]);

    return <div
        // className={`main-menu menu-light menu-border`}
        className={classnames(
            `main-menu menu-light menu-border menu-accordion`,
            {
                "hide-sidebar": windowSize.width < 992 && mainMenuOpen === false
            }
        )}
    >
        <div
            className="d-flex flex-column pt-50"
            style={{height: "calc(100% - (1.3rem + 1.3rem + 4.5rem)", overflow: "auto"}}
        >
            {renderMenu()}
        </div>

        <NavLink to="/" className="sidebar-brand">
            <img
                src="//assets.reecall.co/logos/svg/logo_reecall_2020.svg"
                alt="Reecall Logo"
                data-tip
                data-for="reecall-brand-version"
            />
        </NavLink>
        <Tooltip id="reecall-brand-version" effect="solid">
            <FormattedMessage id="app.version" defaultMessage="Version {version}" values={{version: app.version}}/>
        </Tooltip>
    </div>
}

export default Sidebar