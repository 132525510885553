import {
    END_CALL,
    SET_PHONE_NUMBER_TO,
    SET_PHONE_NUMBER_FROM,
    TOGGLE_SOFT_PHONE,
    TOGGLE_MUTE,
    SET_TWILIO_CONN,
    SET_TWILIO_CONN_STATUS,
    SET_CALL_DIRECTION,
    CALL_ERROR,
    SET_START_TIME,
    ACCEPT_ACD_CALL,
    REJECT_ACD_CALL,
    SET_SOFTPHONE_SOURCE,
    SET_CALL_CONTACT
} from '../../actions/call';

import {
    PUSHER_START_RINGING,
    PUSHER_STOP_RINGING
} from '../../actions/pusher';

import { toast } from "react-toastify";

const initialState = {
    phoneNumberTo : "",
    phoneNumberFrom: "",
    softPhoneOpen: false,
    softPhoneSource: null,
	startTime: null,
    contact: null,
    twilioConn: null,
    twilioStatus: null,
    callDirection: null,
    error: null,
    ACD_Call: false,
    ACD_Ringing: false,
    ACD_CallSid: null,
    ACD_from: null,
    ACD_to: null
}


const callReducer = (state = initialState, action) => {
    switch(action.type) {

        // case START_OUTGOING_CALL:
        //     return {
        //         ...state,
        //         startTime: action.startTime,
        //         contact: action.contact,
        //         twilioConn: action.conn
        //     };

        case END_CALL:
            return {
                ...state,
                startTime: null,
                contact: null,
                twilioConn: null,
                twilioStatus: null,
                ACD_Call: false,
                ACD_Ringing: false,
                ACD_CallSid: null,
                ACD_from: null,
                ACD_to: null
            };

        case SET_PHONE_NUMBER_TO:
            return {
                ...state,
                phoneNumberTo: action.number
            };

        case SET_PHONE_NUMBER_FROM:
            return {
                ...state,
                phoneNumberFrom: action.number
            };

        case TOGGLE_SOFT_PHONE:
            return {
                ...state,
                softPhoneOpen: action.open
            };

        case TOGGLE_MUTE:
            return {
                ...state
            }

        case SET_TWILIO_CONN:
            return {
                ...state,
                twilioConn: action.conn
            }

        case SET_TWILIO_CONN_STATUS:
            return {
                ...state,
                twilioStatus: action.status
            }

        case SET_CALL_DIRECTION:
            return {
                ...state,
                callDirection: action.dir
            }

        case CALL_ERROR:
            toast.error(action.message);
            return {
                ...state,
                error: action.message
            }

        case SET_START_TIME: 
            return {
                ...state,
                startTime: action.time
            }

        case PUSHER_START_RINGING:
            return {
                ...state,
                softPhoneOpen: true,
                ACD_Ringing: true,
                ACD_Call: true,
                ACD_CallSid: action.data.call_sid || null,
                ACD_from: action.data.from,
                ACD_to: action.data.to,
                phoneNumberTo : action.data.to,
                phoneNumberFrom: action.data.from,
                callDirection: "incoming"
            }

        case PUSHER_STOP_RINGING:
            return {
                ...state,
                softPhoneOpen: action.data.reason === "accept" ? state.softPhoneOpen : false,
                ACD_Ringing: false,
                ACD_Call: false,
                ACD_CallSid: null,
                ACD_from: null,
                ACD_to: null,
                callDirection: null,
                phoneNumberTo : "",
                phoneNumberFrom: ""
            }

        case REJECT_ACD_CALL:
            return {
                ...state,
                ACD_Ringing: false
            }

        case ACCEPT_ACD_CALL:
            return {
                ...state,
                ACD_Ringing: false
            }
            
        case SET_SOFTPHONE_SOURCE:
            return {
                ...state,
                softPhoneSource: action.channelId
            }

        case SET_CALL_CONTACT:
            return {
                ...state,
                contact: action.contact
            }

        default: 
            return state;
    }
}
export default callReducer;