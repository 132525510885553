import axios from "axios";
import { INGESTION_URL } from "../../../configs/constants";
export const FETCH_LIBRARY = "FETCH_LIBRARY";

export const IS_LIBRARIES_ACTIVE = false;

export const fetchLibrary = ({context = "tag", lang = "fr"}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        dispatch({type: FETCH_LIBRARY, status: "pending"})

        return rcsdk.getLibraryPages()
        .then(resp => {
            dispatch({
                type: FETCH_LIBRARY,
                status: "success",
                library: resp.find(el => el?.context === context && el?.lang === lang && el?.active)
            })
        }).catch(err => {
            dispatch({type: FETCH_LIBRARY, status: "error", err: err})
        });
    }
}


export const importLibraryItem = ({context = "tag", json}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        return axios({
            method: 'post',
            url: `${INGESTION_URL}/${context}/import`,
            data: {
                companyId: getState().company.company.id,
                object: json
            }
        }).then(response => {
            console.log(response, "response");
            return response.data;
        }).catch(err => {
            if(err?.response?.data?.message){
                throw new Error(err?.response?.data?.message)
            } else {
                throw err;
            }
        })
    }
}