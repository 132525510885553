import {
    FETCH_TICKET_TAGS_PENDING,
    FETCH_TICKET_TAGS_SUCCESS,
    FETCH_TICKET_TAGS_ERROR,
    UPDATE_TICKET_TAG,
    ADD_TICKET_TAG,
    DELETE_TICKET_TAG,
    FETCH_TICKET_TAG_FAMILIES,
    FETCH_MODEL_TAGS,
    FETCH_TICKET_TAG_DATA,
    UPDATE_TICKET_TAG_DATA,
    GET_DIRTY,
    SET_TRAIN_STATUS,
    FETCH_TAGS
} from '../../actions/ticketTags';

const initialState = {
    pending: false,
    addTicketTagsStatus: null,
    updateTicketTagsStatus: null,
    ticketTags: [],
    tags: [],
    fetchTagsPending: false,
    error: null,
    ticketTagsFamilies: [],
    ticketTagsFamiliesStatus: "pending",
    ticketTagsFamiliesError: null,
    modelTags: [],
    modelTagsStatus: "pending",
    modelTagsError: null,
    ticketTagDatas: [],
    ticketTagDatasStatus: "pending",
    ticketTagDatasError: null,
    dirty: false,
    trainStatus: null,
}

const ticketTagsReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_TAGS:
            return {
                ...state,
                tags: action.status === "success" ? action.tags : state.tags,
                fetchTagsPending: action.status === "pending"
            }

        case SET_TRAIN_STATUS:
            return {
                ...state,
                trainStatus: action.status
            }

        case GET_DIRTY:
            return {
                ...state,
                dirty: action.value
            }

        case FETCH_TICKET_TAGS_PENDING:
            return {
                ...state,
                pending: true
            }

        case FETCH_TICKET_TAGS_SUCCESS:
            return {
                ...state,
                pending: false,
                ticketTags: action.ticketTags
            }

        case FETCH_TICKET_TAGS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case UPDATE_TICKET_TAG:
            return {
                ...state,
                pending: false,
                updateTicketTagsStatus: action.status,
                ticketTags: action.status === "success" ? [
                    ...state.ticketTags.map(elt => {
                        if (elt.id === action.ticketTag.id) {
                            return {
                                ...elt,
                                ...action.ticketTag
                            }
                        } else {
                            return elt
                        }
                    })
                ] : state.ticketTags
            }

        case ADD_TICKET_TAG:
            return {
                ...state,
                addTicketTagsStatus: action.status,
                ticketTags: action.status === "success" ? [...state.ticketTags, action.ticketTag] : state.ticketTags
            }

        case DELETE_TICKET_TAG:
            return {
                ...state,
                pending: false,
                ticketTags: [...state.ticketTags.filter(ticketTag => ticketTag.id !== action.id)]
            }

        case FETCH_TICKET_TAG_FAMILIES:
            return {
                ...state,
                ticketTagsFamiliesStatus: action.status,
                ticketTagsFamiliesError: action.status === "error" ? action.error : null,
                ticketTagsFamilies: action.status === "success" ? action.ticketTagsFamilies : state.ticketTagsFamilies
            }

        case FETCH_MODEL_TAGS:
            return {
                ...state,
                modelTagsStatus: action.status,
                modelTagsError: action.status === "error" ? action.error : null,
                modelTags: action.status === "success" ? action.modelTags : state.modelTags
            }

        case FETCH_TICKET_TAG_DATA:
            return {
                ...state,
                ticketTagDatasStatus: action.status,
                ticketTagDatasError: action.status === "error" ? action.error : null,
                ticketTagDatas: action.status === "success" ? action.ticketTagDatas : state.ticketTagDatas
            }

        case UPDATE_TICKET_TAG_DATA:
            return {
                ...state,
                updateTicketTagsStatus: action.status,
                ticketTags: action.status === "success" ? [
                    ...state.ticketTags.map(elt => {
                        if (elt.id === action.data.id) {
                            return {
                                ...elt,
                                ...action.ticketTag
                            }
                        } else {
                            return elt
                        }
                    })
                ] : state.ticketTags
            }

        default:
            return state;
    }
}
export default ticketTagsReducer;