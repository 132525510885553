import React from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { deleteQuickActionLibrary } from '../quickActionLibraries';
import { FormattedMessage } from 'react-intl';
import { isArray } from 'lodash';
import { HOOK_URL } from '../../../configs/constants';


export const GET_CONFIG = "GET_CONFIG";
export const CREATE_HOOK = "CREATE_HOOK";
export const CREATE_RULE = "CREATE_RULE";
export const CREATE_QUICK_ACTION_LIBRAIRY = "CREATE_QUICK_ACTION_LIBRAIRY";
export const FETCH_HOOKS = "FETCH_HOOKS";
export const FETCH_RULES = "FETCH_RULES";
export const DELETE_HOOK = "DELETE_HOOK";
export const DELETE_RULE_PENDING = "DELETE_RULE_PENDING";
export const DELETE_RULE_SUCCESS = "DELETE_RULE_SUCCESS";
export const DELETE_RULE_ERROR = "DELETE_RULE_ERROR";
export const FLUSH_HOOK = "FLUSH_HOOK";
export const FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK = "FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK";
export const UPDATE_HOOK = "UPDATE_HOOK";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

export const fetchConfig = () => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: GET_CONFIG, status: "pending" });
        return axios({
            method: "GET",
            url: `${HOOK_URL}/configuration`,
            // url: `http://127.0.0.1:8077/configuration`, //service en local
            // url: `https://hooks.staging.reecall.io/configuration`, // staging
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        }).then(res => {
            return dispatch({ type: GET_CONFIG, status: "success", config: res.data });
        }).catch(err => {
            dispatch({ type: GET_CONFIG, status: "error", err });
            console.log(err, "err");
        })
    }
}

export const fetchHooks = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_HOOKS, status: "pending" });
        return rcsdk
            .getHooks()
            .where(
                {
                    and: [
                        { type: "automation" },
                        { companyId: getState().company.company.id },
                    ].filter(el => el)
                }
            )
            .then(response => {
                return dispatch({ type: FETCH_HOOKS, status: "success", hooks: response });
            })
            .catch(err => {
                dispatch({ type: FETCH_HOOKS, status: "error", error: err });
            })
    }
}

export const fetchRules = (ids) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_RULES, status: "pending" });
        const rule = await rcsdk.getRules().where({ id: { inq: ids } })
        const step = await rcsdk.getRuleStep(rule[0].sequence[0].stepId)
        const then = await rcsdk.getQuickActionLibraries().where({ id: { inq: rule[0].sequence[0].then } })
        return rcsdk
            .getRuleConditions()
            .where({ id: { inq: step.conditions } })
            .then(conditions => dispatch({ type: FETCH_RULES, status: "success", rules: [{ ...rule[0], step, conditions, then }] }))
            .catch(err => {
                dispatch({ type: FETCH_RULES, status: "error", error: err });
            })
    }
}

export const fetchQuickActionLibrariesForHook = (ids) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK, status: "pending" });
        return rcsdk
            .getQuickActionLibraries().where({ id: { inq: ids } })
            .then(qAsForHook => {
                dispatch({ type: FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK, status: "success", qAsForHook })
                return qAsForHook
            })
            .catch(err => {
                dispatch({ type: FETCH_QUICK_ACTION_LIBRARIES_FOR_HOOK, status: "error", error: err });
            })
    }
}

const createQuickActionLibrary = (data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: CREATE_QUICK_ACTION_LIBRAIRY,
            status: "pending",
        });
        return rcsdk
            .createQuickActionLibrary({ ...data, name: `| AUTOMATION | ${data.name || data.title}` })
            .then(response => {
                dispatch({
                    type: CREATE_QUICK_ACTION_LIBRAIRY,
                    status: "success",
                    quickActionLibrairy: response,
                });
                return response
            }).catch(err => {
                dispatch({
                    type: CREATE_QUICK_ACTION_LIBRAIRY,
                    status: "error",
                    error: err,
                });
            })
    }
}


const createRuleConditions = async (rcsdk, conditions, companyId) => {
    var conditionPromises = [];
    for (let index = 0; index < conditions.length; index++) {
        const condition = conditions[index];

        conditionPromises.push(rcsdk.createRuleCondition({
            source: condition.source,
            value: condition.model ? isArray(condition.data) ? condition.data[0]?.id : condition.data.id : condition.value,
            filter: condition.filter,
            companyId
        }));
    }

    return Promise.all(conditionPromises).then(results => results.map(res => res.id))
}

export const createRule = (conditions, name = "", description = "", actions) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_RULE, status: "pending" });

        var rule = {
            name: `| AUTOMATION | ${name}`,
            description,
            companyId: getState().company.company.id,
            sequence: [],
            // isTest: true,
        }

        let ruleCondition = {
            type: "IF",
            companyId: getState().company.company.id,
            conditions: [],
        };

        ruleCondition.conditions = await createRuleConditions(rcsdk, conditions, getState().company.company.id);

        const step = await rcsdk.createRuleStep(ruleCondition);

        rule.sequence.push({
            step: 0,
            stepId: step.id,
            then: actions || []
        })

        return rcsdk
            .createRule(rule)
            .then(response => {
                dispatch({ type: CREATE_RULE, status: "success", rule: response })
                return response
            })
            .catch(err => {
                dispatch({ type: CREATE_RULE, status: "error", error: err })
            })
    }

}

export const createHook = (data, hookIdForUpdate = null) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: !hookIdForUpdate ? CREATE_HOOK : UPDATE_HOOK, status: "pending" });
        const action = await dispatch(createQuickActionLibrary(data.then))
        const rule = data.conditions.length > 0 ? await dispatch(createRule(data.conditions, data.name, data.description, [action.id])) : null
        const formattedData = {
            name: data.name,
            companyId: getState().company.company.id,
            hook: data.hook,
            data: rule ? {
                "type": "rule",
                "rules": [
                    rule.id
                ]
            } : {
                "type": "action",
                "actions": [
                    action.id
                ]
            },
            scheduleType: "immediate",
            type: "automation",
            agentsId: [],
            targetId: "",
            targetModelName: "",
            authorId: getState().user.rcuser.rcId
        }
        return rcsdk.createHook(formattedData).then(response => {
            toast.success(hookIdForUpdate ? <FormattedMessage id="rules.update.success" defaultMessage="rule updated successfully" /> : <FormattedMessage id="rules.create.success" defaultMessage="rule created successfully" />);
            return dispatch({ type: !hookIdForUpdate ? CREATE_HOOK : UPDATE_HOOK, status: "success", hook: response, hookIdForUpdate });
        }).catch(err => {
            toast.error(hookIdForUpdate ? <FormattedMessage id="rules.update.error" defaultMessage="Oups... something went wrong while updating rule. Please retry. Error message : {err}" values={{ err }} /> : <FormattedMessage id="rules.create.error" defaultMessage="Oups... something went wrong while creating rule. Please retry. Error message : {err}" values={{ err }} />);
            dispatch({ type: !hookIdForUpdate ? CREATE_HOOK : UPDATE_HOOK, status: "error", error: err });
        })
    }
}

const deleteRuleConditions = (rcsdk, conditions) => {
    for (let index = 0; index < conditions.length; index++) {
        const conditionId = conditions[index];
        rcsdk.getRuleCondition(conditionId).then(condition => {
            if (condition.filter === "OR" || condition.filter === "AND") {
                deleteRuleConditions(rcsdk, condition.conditions)
            }
            rcsdk.deleteRuleCondition(conditionId)
        })
    }
}

const deleteRuleSequence = (rcsdk, sequence) => {
    for (var x = 0; x < sequence.length; x++) {
        const stepId = sequence[x].stepId;
        if (stepId) {
            rcsdk.getRuleStep(stepId).then(step => {
                const conditions = step.conditions;
                deleteRuleConditions(rcsdk, conditions)
                rcsdk.deleteRuleStep(stepId)
            })
        }
    }
}

const deleteRule = (ruleId, sequence) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_RULE_PENDING })
        deleteRuleSequence(rcsdk, sequence);
        return rcsdk.deleteRule(ruleId)
            .then(res => {
                dispatch({ type: DELETE_RULE_SUCCESS, ruleId: ruleId })
            })
            .catch(err => {
                if (err.error?.response?.status !== 400) {
                    dispatch({
                        type: DELETE_RULE_ERROR,
                        status: "error",
                        error: err
                    });
                } else {
                    dispatch({ type: DELETE_RULE_SUCCESS, ruleId: ruleId })
                }
            })
    }
}

export const deleteHook = ({ forUpdate = false, hookId, qaLibrairyId = null, ruleId = null, sequence = [] }) => {

    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        !forUpdate && dispatch({ type: DELETE_HOOK, status: "pending" });

        if (qaLibrairyId) {
            await dispatch(fetchQuickActionLibrariesForHook([qaLibrairyId])).then(arr => {
                return arr[0]?.id === qaLibrairyId ? dispatch(deleteQuickActionLibrary(qaLibrairyId)) : true
            })
        }
        if (ruleId) await dispatch(deleteRule(ruleId, sequence))

        return rcsdk.deleteHook(hookId).then(() => {
            !forUpdate && toast.success(<FormattedMessage id="rules.delete.success" defaultMessage="rule deleted successfully" />);
            return !forUpdate ? dispatch({ type: DELETE_HOOK, status: "success", hookId }) : true
        }).catch(err => {
            if (err.error?.response?.status !== 400) {
                toast.error(<FormattedMessage id="rules.delete.error" defaultMessage="Oups... something went wrong while deleting rule. Please retry. Error message : {err}" values={{ err }} />);
                dispatch({ type: DELETE_HOOK, status: "error", error: err });
            } else {
                !forUpdate && toast.success(<FormattedMessage id="rules.delete.success" defaultMessage="rule deleted successfully" />);
                return !forUpdate ? dispatch({ type: DELETE_HOOK, status: "success", hookId }) : true
            }
        })


    }
}

export const updateHook = ({ hookId, deleteData, createData }) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_HOOK, status: "pending" });
        await dispatch(deleteHook({ forUpdate: true, hookId, ...deleteData }))
        return dispatch(createHook(createData, hookId))
    }
}

export const setSearchTerm = (searchTerm) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({
            type: SET_SEARCH_TERM,
            searchTerm: searchTerm || ""
        })
    }
}
