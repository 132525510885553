import Lock from '../../../views/ui-elements/auth/Lock';
import { AUTH_CONFIG } from '../../../configs/auth0Config';
import {updateMember} from "../members"
import { push } from 'connected-react-router'
import { AIRCALL_LOGIN_SUCCESS } from "../aircall/signup"

import zafClient from '../../../ZendeskAppFrameworkClient'

const reloadZendeskApp = () => {
  if(zafClient){
    zafClient.get('instances').then(function(instancesData) {
      let instances = instancesData.instances;
      for(let instance in instances) {
          // if(instances[instance].location === "ticket_editor"){
            let curInstance = zafClient.instance(instances[instance].instanceGuid);
            curInstance._source[1].location.reload(true);
          // }
      }
    });
  }
}

let oLock = Lock({});

export const showLock = (options) => {
  // display lock widget
  return dispatch => {
    oLock = Lock(options);
    oLock.show();
  }
}

// Listen to authenticated event and get the Auth0 profile of the user
export const doAuthentication = () => {
    return (dispatch, getState, {rcsdk}) => {
      oLock.on("authenticated", function(authResult) {
          oLock.getUserInfo(authResult.accessToken, function(error, profile) {
            if (error) {
              // handle error
              return dispatch({ type: "LOGIN_ERROR", error })
            }

            localStorage.setItem('profile', JSON.stringify(profile))
            localStorage.setItem('id_token', authResult.idToken)
            localStorage.setItem('access_token', authResult.accessToken);

            rcsdk.setToken(authResult.idToken);
            if(rcsdk.Stats){
              rcsdk.Stats.setToken(authResult.idToken);
            }
            if(rcsdk.Search){
              rcsdk.Search.setToken(authResult.idToken);
            }

            let rcuser = {};
            for (let key in profile) rcuser[key] = profile[key];

            if(window.location.pathname.match(/^\/aircall/)){
              dispatch({ type: AIRCALL_LOGIN_SUCCESS, profile })
            }

            if (rcuser.email_verified !== true && !window.location.pathname.match(/^\/aircall/)) {
              return dispatch(push(`/verifyEmail`));
            }

            if(rcuser.email_verified === true && window.location.pathname.match(/^\/verifyEmail/)) {
              return dispatch(push(`/`));
            }

            reloadZendeskApp();

            return dispatch({ type: "LOGIN_SUCCESS", profile })
          });
      });
    }
}

export const logout = (toIdleScreen) => {
  return (dispatch, getState, {rcsdk})  => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');

    if(getState().user?.rcuser){
      dispatch(updateMember(getState().user.rcuser.rcId, {connected: false}, false));
    }

    let logoutConfig = {
        clientId: AUTH_CONFIG.clientId,
    }
    if(!toIdleScreen){
      logoutConfig = {...logoutConfig, returnTo: escape(window.location.pathname) !== "/logout" && escape(window.location.pathname) !== "/forceLogout" ? window.location.origin + '/login?goto=' + escape(window.location.pathname) : window.location.origin + '/login'}
    } else {
      logoutConfig = {...logoutConfig, returnTo: window.location.origin + '/idle-screen?goto=' + escape(window.location.pathname)}
    }

    oLock.logout(logoutConfig);

    return dispatch({ type: "LOGOUT_SUCCESS", userId: getState().user.rcuser.rcId });
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
