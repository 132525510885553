import {
    FETCH_TEAMS_PENDING,
    FETCH_TEAMS_SUCCESS,
    FETCH_TEAMS_ERROR,
    UPDATE_TEAM,
    ADD_TEAM,
    DELETE_TEAM
} from '../../actions/teams';

const initialState = {
    pending: false,
    teams: [],
    error: null
}

const teamsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_TEAMS_PENDING: 
            return {
                ...state,
                pending: true
            }
            
        case FETCH_TEAMS_SUCCESS:
            return {
                ...state,
                pending: false,
                teams: action.teams
            }

        case FETCH_TEAMS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case UPDATE_TEAM:
            return {
                ...state,
                pending: false,
                teams: [
                    ...state.teams.map(elt => {
                        if(elt.id === action.team.id){
                            return {
                                ...elt,
                                ...action.team
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        case ADD_TEAM:
            return {
                ...state,
                pending: false,
                teams: [...state.teams, action.team]
            }

        case DELETE_TEAM:
            return {
                ...state,
                pending: false,
                teams: [...state.teams.filter(team => team.id !== action.id)]
            }

        default: 
            return state;
    }
}
export default teamsReducer;