import {
    FETCH_AGENTS_PENDING,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_ERROR,
    FLUSH_AGENTS,
    SET_SHOW_AGENT_CTA_MODAL,
    UPDATE_AGENT
} from '../../actions/agents';

const initialState = {
    pending: false,
    agents: [],
    error: null,
    showCTAModal: false,
    updateAgentStatus: null
}

const agentsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_AGENTS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_AGENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                agents: action.agents
            }
        case FETCH_AGENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FLUSH_AGENTS:
            return {
                ...state,
                agents: []
            }

        case SET_SHOW_AGENT_CTA_MODAL:
            return {
                ...state,
                showCTAModal: action.payload || false
            }

        case UPDATE_AGENT:
            return {
                ...state,
                updateAgentStatus: action.status
            }

        default: 
            return state;
    }
}
export default agentsReducer;