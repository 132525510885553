import {
    FETCH_INVITATIONS,
    FLUSH_INVITATIONS,
    ADD_INVITATION,
    DELETE_INVITATION,
    SHOW_INVITATION_MODAL
} from '../../actions/invitations';

const initialState = {
    status: "pending",
    invitations: [],
    error: null,
    showAddModal: false
}

const invitationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_INVITATIONS:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: action.status === "success" ? [...action.invitations] : state.invitations
            }

        case FLUSH_INVITATIONS:
            return {
                ...state,
                invitations: []
            }

        case ADD_INVITATION:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: action.status === "success" ? [...state.invitations, action.invitation] : [...state.invitations]
            }

        case DELETE_INVITATION:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: action.status === "success" ? [...state.invitations.filter(el => el.id !== action.id)] : [...state.invitations]
            }
        
        case SHOW_INVITATION_MODAL:
            return {
                ...state,
                showAddModal: action.payload
            }

        default: 
            return state;
    }
}
export default invitationsReducer;