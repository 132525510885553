import { setCompany } from "../company";
import { setStripeSecret } from "../payment";

export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const TOGGLE_CHANNEL = 'TOGGLE_CHANNEL';
export const TOGGLE_CHANNELS = 'TOGGLE_CHANNELS';

function fetchUserPending() {
    return {
        type: FETCH_USER_PENDING
    }
}

function fetchUserSuccess(user) {
    return {
        type: FETCH_USER_SUCCESS,
        rcuser: user
    }
}

function fetchUserError(error) {
    return {
        type: FETCH_USER_ERROR,
        error: error
    }
}

export const toggleChannel = (channelName) => {
    return (dispatch, getState) => {
        dispatch({ type: TOGGLE_CHANNEL, channelName: channelName, active: !getState().user.rcuser.settings.channels.find(channel => channel.name === channelName).active })
    }
}

export const toggleChannels = (active) => {
    return (dispatch, getState) => {
    	getState().user.rcuser.settings.channels.map(channel => {
        	dispatch({ type: TOGGLE_CHANNEL, channelName: channel.name, active: active });
        	return null;
    	})
    }
}

export const fetchUser = () => {
    return (dispatch, getState, {rcsdk, rcRPA, rcPayment}) => {
        dispatch(fetchUserPending());

	    return rcsdk.getMyself().then(user => {
			return rcsdk._request().route(`/members/${user.id}/companies`).then(companies => {
				if(companies?.[0]){
					dispatch(setCompany(companies[0]));
				}

				let rcuser = {};
				rcuser.rcId = user.id;
				rcuser.settings = user.settings || undefined;
				rcuser.settings.activePhone = (rcuser.settings.activePhone) ? rcuser.settings.activePhone : [];
				rcuser.settings.activeChat = (rcuser.settings.activeChat) ? rcuser.settings.activeChat : [];

				rcuser.firstName = user.firstName || undefined;
				rcuser.lastName = user.lastName || undefined;
				rcuser.phone = user.phone || undefined;
				rcuser.email = user.email || undefined;
				rcuser.organizations = user.organizations || [];

				if(companies?.[0]){
					rcuser.rights = {
						"ADMIN": user.permissions[companies[0].id].ADMIN,
						...user.permissions[companies[0].id]?.rtPerms
					}
				} else {
					rcuser.rights = {
						"ADMIN": true
					}
				}
				
				rcuser.settings = {
					...rcuser.settings,
					channels: []
				}
				
				rcRPA.setCompanyId(companies[0].id);
				rcRPA.setUser(rcuser);

				console.log(rcuser, "rcuser");
				rcPayment.setCompanyId(companies[0].id);
				rcPayment.setUserId(rcuser.rcId);
				rcPayment.getStripeSecret().then(resp => {
					dispatch(setStripeSecret(resp.secret));
				}).catch(err => {
					console.log(err, "err");
				})

				dispatch(fetchUserSuccess(rcuser));
				return {rcuser: rcuser, hasCompany: !!companies?.[0]};
			}).catch(err => {
				console.log(err, "err fetch company");
			})
	    }).catch(err => {
        	dispatch(fetchUserError(err.message));
	    });

    }
}