import * as Twilio from 'twilio-client';
import axios from "axios"
import { executeAction } from "../quickActions"
import { updatePhoneAvailability } from "../members"
import { RPA_URL, ACD_URL } from "../../../configs/constants"


export const START_OUTGOING_CALL = 'START_OUTGOING_CALL';
export const END_CALL = 'END_CALL';

export const SET_PHONE_NUMBER_TO = 'SET_PHONE_NUMBER_TO';
export const SET_PHONE_NUMBER_FROM = 'SET_PHONE_NUMBER_FROM';
export const SET_CALL_DIRECTION = 'SET_CALL_DIRECTION';
export const SET_START_TIME = 'SET_START_TIME';

export const TOGGLE_SOFT_PHONE = 'TOGGLE_SOFT_PHONE';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const SET_SOFTPHONE_SOURCE = 'SET_SOFTPHONE_SOURCE';

export const SET_TWILIO_CONN = 'SET_TWILIO_CONN';
export const SET_TWILIO_CONN_STATUS = 'SET_TWILIO_CONN_STATUS';

export const CALL_ERROR = 'CALL_ERROR';

export const ACCEPT_ACD_CALL = "ACCEPT_ACD_CALL";
export const REJECT_ACD_CALL = "REJECT_ACD_CALL";

export const SET_CALL_CONTACT = "SET_CALL_CONTACT";

export function startOutgoingCall(contact = null, phoneNumber = null, channelId = null) {
    return (dispatch, getState, {rcsdk}) => {
        let selectedChannel = null;
        if(channelId){
            selectedChannel = getState().channels.channels.find(channel => channel.id === channelId)
        }

        let connection = Twilio.Device.connect({
            To: phoneNumber ? `+${phoneNumber.replace("+", "")}` : `phone_${contact.id}`,
            source: selectedChannel?.token || null
        });

        dispatch(initCall(connection, "outgoing"));
    }
}

export function endCall() {
    return {
        type: END_CALL
    }
}

export function setError(message){
    return {
        type: CALL_ERROR,
        message: message
    }
}

export function setPhoneNumberTo(number) {
    return {
        type: SET_PHONE_NUMBER_TO,
        number: number
    }
}

export function setPhoneNumberFrom(number) {
    return {
        type: SET_PHONE_NUMBER_FROM,
        number: number
    }
}

export function toggleSoftPhone(open) {
    return {
        type: TOGGLE_SOFT_PHONE,
        open: open
    }
}

export function toggleMute() {
    return {
        type: TOGGLE_MUTE
    }
}

export function setTwilioConn(conn) {
    return {
        type: SET_TWILIO_CONN,
        conn: conn
    }
}

export function setTwilioConnStatus(status) {
    return {
        type: SET_TWILIO_CONN_STATUS,
        status: status
    }
}

export function setCallDirection(dir){
    return {
        type: SET_CALL_DIRECTION,
        dir: dir
    }
}

export function setStartTime(time){
    return {
        type: SET_START_TIME,
        time: time
    }
}

export function setContact(contact) {
    return {
        type: SET_CALL_CONTACT,
        contact: contact
    }
}

export function initCall(conn, dir){
    return (dispatch, getState, {rcsdk}) => {
        dispatch(setTwilioConn(conn));
        dispatch(setCallDirection(dir));
        dispatch(setTwilioConnStatus("ringing"));
        
        conn.on("ringing", function() {
            console.log("RINGING");
            dispatch(setTwilioConnStatus("ringing"));
            updatePhoneAvailability({
                memberId: getState().user.rcuser.rcId,
                companyId: getState().company.company.id,
                status: false
            });
            dispatch(setUserPhoneStatus(false));
        })

        conn.on("accept", function(conn) {
            console.log("ACCEPT");
            dispatch(setTwilioConnStatus(conn.status()));
            dispatch(setStartTime(+ new Date()));
            dispatch(setUserPhoneStatus(false));
        });
        
        conn.on("reject",  function(){
            console.log("REJECT");
            dispatch(setTwilioConn(null));
            dispatch(setTwilioConnStatus(null));
            dispatch(toggleSoftPhone(false));
            dispatch(setContact(null));
            updatePhoneAvailability({
                memberId: getState().user.rcuser.rcId,
                companyId: getState().company.company.id,
                status: true
            });
            dispatch(setUserPhoneStatus(true));
        });

        conn.on('disconnect', function() {
            console.log("DISCONNECT");
            dispatch(setTwilioConn(null));
            dispatch(setTwilioConnStatus(null));
            dispatch(toggleSoftPhone(false));
            dispatch(setContact(null));
            updatePhoneAvailability({
                memberId: getState().user.rcuser.rcId,
                companyId: getState().company.company.id,
                status: true
            });
            dispatch(setUserPhoneStatus(true));
        });

        if(dir === "incoming"){
            dispatch(setPhoneNumberFrom(conn.parameters.From));
            dispatch(setPhoneNumberTo(""));
        } else {
            dispatch(setPhoneNumberFrom(null));
        }
        
        dispatch(toggleSoftPhone(true));

        if(getState().call.ACD_Call){
            dispatch(acceptCall(conn));
        }
    }
}

export function hangOutCall(conn){
    return (dispatch, getState, {rcsdk}) => { 
        console.log("hangOutCall");
        dispatch(setUserPhoneStatus(true));
        dispatch(toggleSoftPhone(false));
        dispatch(setTwilioConn(null));
        dispatch(setCallDirection(null));
        dispatch(setTwilioConnStatus(null));
        dispatch(setPhoneNumberFrom(""));
        dispatch(setPhoneNumberTo(""));
        dispatch(setStartTime(null));
        dispatch(setContact(null));
    }
}

export function disconnectCall(conn){
    return (dispatch, getState, {rcsdk}) => { 
        conn.disconnect();
        dispatch(hangOutCall(conn));
    }
}

export function rejectCall(conn){
    return (dispatch, getState, {rcsdk}) => { 
        conn.reject();
    }
}

export function rejectACDCall(callSid){
    return (dispatch, getState, {rcsdk}) => {
        dispatch({ type: REJECT_ACD_CALL });
        axios.get(`${ACD_URL}/dispatch/` + callSid + "/reject",
        {
            headers: {
                memberId: getState().user.rcuser.rcId
            }
        })
    }
}

export function acceptCall(conn){
    return (dispatch, getState, {rcsdk}) => { 
        conn.accept();
        dispatch(setTwilioConnStatus("open"));
        dispatch(setStartTime(+ new Date()));
    }
}


export function acceptACDCall(callSid, from , to){
    return (dispatch, getState, {rcsdk}) => {
        axios({
            method: "POST",
            url: `${RPA_URL}/action`,
            data: { 
                config: {
                    integration: "reecall", 
                    action: "callTransfer",
                    datas: {
                        callSid: callSid,
                        to: `phone_${getState().user.rcuser.rcId}`
                    }
                }
            },
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        }).then(response => {
            dispatch({ type: ACCEPT_ACD_CALL })
            axios.get(`${ACD_URL}/dispatch/` + callSid + "/accept", {
                headers: {
                    memberId: getState().user.rcuser.rcId
                }
            })
        }).catch(err => {
            console.log(err.message, "err");
        });
    }
}

export const setSoftPhoneSource = (channelId) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: SET_SOFTPHONE_SOURCE,
            channelId: channelId
        })
    }
}

export const setUserPhoneStatus = (status) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(executeAction({
            quickAction: {
                "actionType": "rpa",
                "key":"setUserPhoneStatus",
                "integrationKey":"reecall",
                "integrationId":"5f76e92be0a7e10007e9325d",
                "datas":{
                    "memberId": "{{member.id}}",
                    "companyId": "{{company.id}}",
                    "status": "{{status}}"
                }
            },
            context: {
                "member": {"id": getState().user.rcuser.rcId},
                "company": {"id": getState().company.company.id},
                "status": status 
            }
        }));
    }
}