import {AIRCALL_SIGNUP_SET_STEP, AIRCALL_SET_ACCESS_TOKEN, AIRCALL_LOGIN_SUCCESS, AIRCALL_SET_FORM_DATA, SET_SIGNUP_AGENT} from '../../actions/aircall/signup';

const initialState = {
    currentStep: null,
    // accessToken: "m5VpACrgvhM9UUKR54Px9FoqvMNQPkYfqV84gl3ael0"
    accessToken: null,
    auth0profile: {},
    formDatas: {},
    agent: null
}

const AircallSignUpReducer = (state = initialState, action) => {
    switch(action.type) {
        case AIRCALL_SIGNUP_SET_STEP:
            return {
                ...state,
                currentStep: action.payload
            }
        case AIRCALL_SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.token
            }
        case AIRCALL_LOGIN_SUCCESS:
            return {
                ...state,
                auth0profile: {...action.profile}
            }
        case AIRCALL_SET_FORM_DATA:
            return {
                ...state,
                formDatas: action.formDatas
            }
        case SET_SIGNUP_AGENT:
            return {
                ...state,
                agent: action.agent
            }
        default: 
            return state;
    }
}
export default AircallSignUpReducer;