import React from "react"
import { FormattedMessage } from "react-intl";
// import axios from "axios"
import { selectChannelsConnectionsByMemberId, selectChannelById } from "../channels"
// import { RPA_URL } from "../../../configs/constants"

export const updatePhoneAvailability = ({memberId, companyId, status}) => {
    // axios({
    //     method: "POST",
    //     url: `${RPA_URL}/action`,
    //     data: { 
    //         config: {
    //             integration: "reecall", 
    //             action: "setUserPhoneStatus",
    //             datas: {
    //                 "companyId": companyId,
    //                 "memberId": memberId,
    //                 "status": status
    //             }
    //         }
    //     }
    // })
}

export const FETCH_MEMBERS_PENDING = 'FETCH_MEMBERS_PENDING';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';
export const FLUSH_MEMBERS = 'FLUSH_MEMBERS';
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const TOGGLE_LIST_DRAWER = "TOGGLE_LIST_DRAWER";
export const OPEN_INTERNAL_CHAT = "OPEN_INTERNAL_CHAT";

export const openInternalChat = (liveId) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: OPEN_INTERNAL_CHAT, liveId: liveId});
    }
}

function fetchMembersPending() {
    return {
        type: FETCH_MEMBERS_PENDING
    }
}

function fetchMembersSuccess(members, flush = false) {
    return {
        type: FETCH_MEMBERS_SUCCESS,
        members: members,
        flush: flush
    }
}

function fetchMembersError(error) {
    return {
        type: FETCH_MEMBERS_ERROR,
        error: error
    }
}

export function flushMembers() {
    return {
        type: FLUSH_MEMBERS
    }
}

export const fetchMembers = ({flush = true}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchMembersPending());
        return rcsdk.getCompanyMembers(getState().company.company.id)
        .then(members => {
            let membershipPromises = [];
            members.map(member => {
                let membershipPromise = rcsdk._request().route(`/members/${member.id}/memberships`)
                .where({"companyId": getState().company.company.id})
                .err('Could not fetch memberships')
                membershipPromises.push(membershipPromise);
                return null;
            })
            
            Promise.all([...membershipPromises]).then(memberships => {
                memberships.map((membership, index) => {
                    members[index].role = membership?.[0]?.role;
                    members[index].membershipMeta = membership?.[0]?.meta || {};
                    return null;
                });
                return dispatch(fetchMembersSuccess(members, flush));
            })
        }).catch(err => {
            dispatch(fetchMembersError(err.message));
        })
    }
}

export function updateMember(id, datas, showToast = true){
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk._request().method('PATCH').route(`/members/${id}`).data(datas).then(member => {
            return dispatch({
                type: UPDATE_MEMBER,
                member: member,
                successToast: showToast ? {
                    type: "UPDATE",
                    message: <FormattedMessage id="members.toast.update" defaultMessage="Member updated successfully"/>
                } : null
            });
        }).catch(err => {
            dispatch(fetchMembersError(err.message));
        });
    }
}

const selectMembers = state => state.members.members;
const selectMemberById = (state, {memberId}) => {
    if(!memberId) return null;
    return state.members.members.find(el => el.id === memberId);
}

const selectMembersWithTeam = (state, {excludeMe}) => {
    const members = state.members.members.map(member => {
        if(excludeMe && member.id === state.user.rcuser.rcId) return null;
        const teamsMember = state.teams.teams.filter(team => team.memberIds.includes(member.id))
        return {
            ...member,
            teams: teamsMember
        }
    }).filter(el => el)
    return members;
}

const selectMembersWithChannelConnections = (state, {excludeMe}) => {
    return selectMembersWithTeam(state).map(member => {
        if(excludeMe && member.id === state.user.rcuser.rcId) return null;

        const channelConnections = selectChannelsConnectionsByMemberId(state, {memberId: member.id}).map(channelConnection => {
            return {
                ...channelConnection,
                channel: selectChannelById(state, {channelId: channelConnection.channelId})
            }
        });
        return {
            ...member,
            channelConnections: channelConnections,
            isPhoneActive: channelConnections ? !!channelConnections.filter(channelConnection => channelConnection.active && channelConnection.channel.type === "phone").length : false
        }
    }).filter(el => el)
}

const selectConnectedMembers = state => selectMembers(state).filter(el => el.connected);

const isDrawerListOpen = state => state.members.drawer.show;

export {selectMembers, selectMemberById, selectConnectedMembers, selectMembersWithTeam, selectMembersWithChannelConnections, isDrawerListOpen}