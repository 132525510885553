import axios from "axios";
import {toast} from "react-toastify"
import FaqService from "../../../services/FAQService.js"
import {getContactPhoneNumber} from "../contacts/index"
import { getChannel } from 'pusher-redux';
import { isArray } from "lodash";
import { CORE_URL, NLU_URL } from "../../../configs/constants"

export const H2H_IS_ACTIVE = false;

export const H2H_RESET                  = 'H2H_RESET';
export const H2H_START_CALL             = 'H2H_START_CALL';
export const H2H_SAVE_LAST_STEP         = 'H2H_SAVE_LAST_STEP';
export const H2H_ADD_STEP               = 'H2H_ADD_STEP';
export const H2H_SET_CURRENT_STEP       = 'H2H_SET_CURRENT_STEP';
export const H2H_END_CALL               = 'H2H_END_CALL';
export const H2H_ADD_NOTE               = 'H2H_ADD_NOTE';
export const H2H_SET_SUGGESTIONS        = 'H2H_SET_SUGGESTIONS';
export const H2H_SET_FAQS               = 'H2H_SET_FAQS';
export const H2H_SET_SCENARIOS          = 'H2H_SET_SCENARIOS';
export const H2H_OPEN_SUGGESTION        = 'H2H_OPEN_SUGGESTION';
export const H2H_CLOSE_SUGGESTION       = 'H2H_CLOSE_SUGGESTION';
export const H2H_SET_SUGGESTION_SEARCH  = 'H2H_SET_SUGGESTION_SEARCH';
export const H2H_LOAD_SUGGESTION        = 'H2H_LOAD_SUGGESTION';
export const H2H_TOGGLE_ADD_NOTE        = 'H2H_TOGGLE_ADD_NOTE';
export const H2H_LOADING_STEP           = 'H2H_LOADING_STEP';
export const H2H_SET_CONV_ID            = 'H2H_SET_CONV_ID';


let faqService = new FaqService();

let channelId = "d9976262-eb4d-46cd-b2fa-4c755c4eb3d4"; //acme
// let channelId = "20fe3274-48ee-488f-868e-1441e9048103";


const handleH2HPusher = (data, dispatch) => {
    if(data?.action === "closeConversation"){
        dispatch(EndCall());
    }
}

const StartCall = (h2hUID, contactId) => {
    return async (dispatch, getState, {rcsdk}) => {
        dispatch({type: H2H_RESET});

        let contactPhone = await dispatch(getContactPhoneNumber(contactId));

        axios.post(CORE_URL+`/h2h/connect`, {
            "From"          : getState().user.rcuser.rcId,
            "To"            : contactPhone,
            "conversationId": h2hUID,
            // "companyId"     : "5fb260907da43200088a05ca",
            "companyId"     : getState().company.company.id,
            "channel"       : channelId
        }).then(res => {
            dispatch({
                type: H2H_START_CALL,
                uid: h2hUID,
                startDate: new Date(),
                contactId: contactId,
                contactPhone: contactPhone,
                classifierName: res.data.agent.classifier
            })

			const h2hChannel = getChannel(`company-${getState().company.company.id}`);
            h2hChannel.bind(h2hUID, function (data) {
                handleH2HPusher(data, dispatch);
			});

            dispatch(handleResponse(res?.data));
        }).catch(err => toast.error(`Oups... something went wrong. Error message : ${err}`))
    }
}

const handleResponse = (resp, isPrevStep = false) => {
    return (dispatch, getState, {rcsdk}) => {
        let scrollTo = true;

        let steps = resp.turns;
        if(!isArray(steps)){ steps = [steps] }
        
        dispatch({type: H2H_SAVE_LAST_STEP, step: resp});

        steps.forEach(step => {
            if(step?.sentences){
                dispatch(AddStep({
                    type: "message",
                    data: {
                        messages: step.sentences
                    }
                }, scrollTo));
    
                if(scrollTo) scrollTo = false;
            }

            if(step?.events && step?.events.length > 0 && !isPrevStep){
                step.events.forEach(event => {
    
                    dispatch(AddStep({
                        type: "markdown",
                        data: event.data
                    }, scrollTo));
    
                    if(scrollTo) scrollTo = false;
                })
            }
    
            if(step?.collect){
                dispatch(AddStep({
                    type: "collect",
                    data: step.collect
                }));
            }
    
            if(step?.hints && step?.hints.length > 0){
                dispatch(AddStep({
                    type: "buttonsList",
                    data: {actions: step.hints.map(hint => ({title: hint}))}
                }));
            }
        })

    }
}

const loadingStep = (stepId) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: H2H_LOADING_STEP,stepId: stepId});
    }
}

const PushMessageToCORE = (text) => {
    return (dispatch, getState, {rcsdk}) => {

        return axios.post(CORE_URL+`/h2h/say`, {
            "From"          : getState().user.rcuser.rcId,
            "To"            : getState().human2human.callContactPhone,
            "conversationId": getState().human2human.callUid,
            // "companyId"     : "5fb260907da43200088a05ca",
            "companyId"     : getState().company.company.id,
            "channel"       : channelId,
            "message": text
        }).then(resp => {
            dispatch(handleResponse(resp?.data));
            dispatch(loadingStep(null))
        })
    }
}

const SetCurrentStep = (stepId) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: H2H_SET_CURRENT_STEP,
            stepId: stepId
        })
    }
}

const AddStep = (step, scrollTo = false) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: H2H_ADD_STEP,
            step: step,
            scrollTo: scrollTo
        })
    }
}

const fetchConversation = () => {
    return (dispatch, getState, {rcsdk}) => {
        return axios.get(CORE_URL+`/h2h/conversation/${getState().human2human.callUid}`).then(resp => {
            dispatch({type: H2H_SET_CONV_ID, convId: resp.data?.reecallConversationId})
            return resp.data;
        })
    }
}

const EndCall = (props) => {
    return (dispatch, getState, {rcsdk}) => {

        let callDuration = Math.floor(new Date() - getState().human2human.callStartDate) / 1000;
        let callUid = getState().human2human.callUid;

        return axios.post(CORE_URL+`/h2h/status`, {
            "From"          : getState().user.rcuser.rcId,
            "To"            : getState().human2human.callContactPhone,
            "conversationId": callUid,
            // "companyId"     : "5fb260907da43200088a05ca",
            "companyId"     : getState().company.company.id,
            "channel"       : channelId,
            "CallStatus":"completed",
            "CallDuration": callDuration
        }).then(resp => {

			const h2hChannel = getChannel(`company-${getState().company.company.id}`);
            h2hChannel.unbind(callUid);

            dispatch({type: H2H_END_CALL});
        })
    }
}

const AddNote = ({message}) => {
    return (dispatch, getState, {rcsdk}) => {
        return dispatch({
            type: H2H_ADD_NOTE,
            message: message
        })
    }
}

const AddFaqStep = (faq) => {
    return (dispatch, getState, {rcsdk}) => {
        if(!faq) return null;

        let markdown = `|<span className='icon-round-wrapper'>![icon](icon:question)</span>**${faq.question}** |\n|--|\n|**${faq.answer}**|\n`;
        if(faq?.url !== ""){
            markdown += `|[Show details](${faq.url})|\n`
        }

        dispatch(AddStep({
            type: "markdown",
            data: {
                display: markdown 
            }
        }, true));

        dispatch(handleResponse(getState().human2human.lastStep, true));
    }
}

const SearchSuggestions = (text) => {
    return (dispatch, getState, {rcsdk}) => {
        // H2H_SET_SUGGESTIONS
        return new Promise(resolve => resolve([]));
    }
} 

const SearchFaqs = (text) => {
    return (dispatch, getState, {rcsdk}) => {
        return faqService.search({
            companyId: getState().company.company.id,
            text: text
        }).then(resp => {
            const faqs = resp?.question !== "" && resp.enabled ? 
                [{
                    ...resp,
                    title: resp.question,
                    icon: "question"
                }]
            : 
                [];

            dispatch({
                type: H2H_SET_FAQS,
                faqs: faqs
            })
            return faqs
        })
    }
}

const SearchScenarios = (text) => {
    return (dispatch, getState, {rcsdk}) => {
        return axios.post(`${NLU_URL}/tag`, {
            "companyId": getState().company.company.id,
            "key": getState().human2human.classifierName,
            "text": text,
            "label_names": true
        }).then(res => {
            let scenarios = res?.data?.tags || [];
            dispatch({
                type: H2H_SET_SCENARIOS,
                scenarios: scenarios.filter(el => el !== "rcoos").map(scenario => ({
                    title: scenario,
                    icon: "leaf"
                }))
            })
            return scenarios;
        }).catch(err => toast.error(`Oups... something went wrong. Error message : ${err}`))
    }
} 

const setSuggestionsSearch = (search) => {
    return async (dispatch, getState, {rcsdk}) => {
        dispatch({type: H2H_SET_SUGGESTION_SEARCH, search: search});
        dispatch({type: H2H_LOAD_SUGGESTION, loading: true});

        const suggestionsPromise    = dispatch(SearchSuggestions(search));
        const faqsPromise           = dispatch(SearchFaqs(search));
        const scenariosPromise      = dispatch(SearchScenarios(search));

        await Promise.all([suggestionsPromise, faqsPromise, scenariosPromise]);
        
        dispatch(openSuggestions());
        dispatch({type: H2H_LOAD_SUGGESTION, loading: false});
    }
}

const openSuggestions = () => {
    return (dispatch, getState, {rcsdk}) => {
        return dispatch({type: H2H_OPEN_SUGGESTION})
    }
}

const closeSuggestions = () => {
    return (dispatch, getState, {rcsdk}) => {
        return dispatch({type: H2H_CLOSE_SUGGESTION})
    }
}


const getConversationInfosFromCORE = ({conversationUid}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        return axios.get(CORE_URL+`/h2h/conversation/${conversationUid}`)
    }
}


export {StartCall, SetCurrentStep, AddStep, handleResponse, AddFaqStep, EndCall, fetchConversation, AddNote, SearchSuggestions, SearchFaqs, SearchScenarios, openSuggestions, closeSuggestions, setSuggestionsSearch, getConversationInfosFromCORE, PushMessageToCORE, loadingStep}