import React from "react";
import { FormattedMessage } from "react-intl"
import { ReplaceByContext, formatQaFieldsDefaultValue } from "../../../utility/utils"
import {omit, flatten} from "lodash";

export const SHOW_QUICK_ACTION_MODAL = "SHOW_QUICK_ACTION_MODAL";
export const HIDE_QUICK_ACTION_MODAL = "HIDE_QUICK_ACTION_MODAL";

const getContext = (contextIds) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        return rcRPA.getContext({...contextIds, companyId: getState().company.company.id})
    }
}
export {getContext};

const getFormattedQA = (action) => {
    return (dispatch, getState, {rcsdk}) => {
        let integrations = getState().integrations.integrations;
        let integration = integrations.find(inte => inte.key === action.integration);
        let quickActionModel = integration.quickActions.find(quickAction => quickAction.id === action.quickActionModelId);
        let fields = formatQaFieldsDefaultValue(quickActionModel.fields, action.data);

        return {
            datas: action.data,
            fields: [...fields.map(field => {
                let formattedField = {
                    ...field,
                    showOnModal: field.showOnModal
                };
                return formattedField;
            })],
            key: quickActionModel.key,
            integrationKey: integration.key
        }
    }
}

export const handleQuickAction = ({
    quickAction,
    contextIds = {},
    onDone = () => {},
    step = 0,
    additionnalContext = {previous: []}
}) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(getContext(contextIds)).then(context => {
            let quickActionLibraries = getState().quickActionLibraries.quickActionLibraries;
            let selectedQuickActionLibrary = quickActionLibraries.find(el => el.id === quickAction.id);
  
            let currentAction = selectedQuickActionLibrary.actions[step];
            let isLastStep = step === selectedQuickActionLibrary.actions.length - 1;
            let formattedQuickAction, onDoneCustom;

            if(currentAction.type === "QaAction"){
                formattedQuickAction = dispatch(getFormattedQA(currentAction))
                onDoneCustom = (props) => {
                    onDone(props)
                };
            } else if(currentAction.type === "QaLibrary") {
                let currentQuickActionLibrary = quickActionLibraries.find(el => el.id === currentAction.QaLibraryId);
                currentAction = currentQuickActionLibrary?.actions?.[0]
                formattedQuickAction = dispatch(getFormattedQA(currentAction))
                formattedQuickAction = {
                    ...formattedQuickAction,
                    title: currentQuickActionLibrary.title,
                    submitBtnLabel: currentQuickActionLibrary?.submitBtnLabel || formattedQuickAction?.submitBtnLabel || "",
                    cancelBtnLabel: currentQuickActionLibrary?.cancelBtnLabel || formattedQuickAction?.cancelBtnLabel || "",
                }
                onDoneCustom = (props) => {
                    if(isLastStep){
                        onDone(props)
                    } else {
                        dispatch(handleQuickAction({
                            quickAction,
                            contextIds,
                            onDone,
                            step: step + 1,
                            additionnalContext: {
                                ...additionnalContext,
                                previous: [
                                    ...additionnalContext.previous,
                                    {...props.QAresp}
                                ]
                            }
                        }))
                    }
                };
            }

            formattedQuickAction = {
                ...omit(selectedQuickActionLibrary, ["createdAt", "isDeleted", "updatedAt", "actions"]),
                ...formattedQuickAction
            }

            let testModal = formattedQuickAction.fields.map(field => {
                if(field?.showOnModal === false){
                    return false
                } else if(field.type === "dictionary"){
                    if(!field?.default) return false;
                    if(field.default.length === 0) return false;
                    return field.default.map(el =>  {
                        if(el?.showOnModal === false) return false;
                        return true;
                    })
                }
                return true;
            })
            
            testModal = flatten(testModal);
            let showModal = testModal.includes(true);

            if(showModal){
                dispatch({
                    type: SHOW_QUICK_ACTION_MODAL,
                    quickAction: formattedQuickAction,
                    context: {...context, ...additionnalContext},
                    onDone: onDoneCustom,
                    isLastStep: isLastStep
                });
            } else {
                dispatch(executeQuickAction({
                    quickAction: formattedQuickAction,
                    context: {...context, ...additionnalContext}
                })).then((resp) => onDoneCustom(resp));
            }
        });
    }
}

export const executeAction = ({quickAction, context = {}, showToast = false}) => {
    return (dispatch, getState, {rcRPA}) => {
        console.groupCollapsed("QA: executeAction");
        console.log(quickAction, "quickAction");
        console.log(context, "context");
        console.log(showToast, "showToast");

        let formattedQuickAction = {
            ...quickAction,
            datas: {
                ...ReplaceByContext(quickAction.datas, "{{(.+?)}}", context),
                companyId: getState().company.company.id
            }
        };
        let quickActionIntegrationKey = formattedQuickAction?.integrationId ? getState().integrations.integrations.find(el => el.id === formattedQuickAction.integrationId).key : formattedQuickAction?.integrationKey;
        
        console.log(formattedQuickAction, "formattedQuickAction");
        console.groupEnd();

        return rcRPA.executeAction({ 
            config: {
                integration: quickActionIntegrationKey, 
                action: formattedQuickAction.key,
                datas: {...formattedQuickAction.datas}
            }
        })
        .then(resp => {
            if(showToast){
                dispatch({
                    type: "SHOW_EXECUTE_QA_TOAST",
                    successToast: {
                        type: "EXECUTE_QA",
                        message: <FormattedMessage id="quickAction.toast.executionSuccess" defaultMessage="Quick action executed successfully"/>
                    }
                })
            }
            return resp;
        })
    }
} 


export const executeQuickAction = ({quickAction, context = {}, showToast = false}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        console.groupCollapsed("QA: executeQuickAction");
        console.log(quickAction, "quickAction");
        console.log(context, "context");
        console.log(showToast, "showToast");

        let data = JSON.stringify({...quickAction.datas, ...context});
        console.log(data, "data 1");
        data = JSON.parse(data, (key, value) => {
            return typeof value === 'string' ? value.replace(/"/g, '\\"') : value;
        });
        console.log(data, "data 2");
        console.groupEnd();

        return rcRPA.executeQuickAction({id: quickAction.id, data: data}).then(resp => {
            if(showToast){
                dispatch({
                    type: "SHOW_EXECUTE_QA_TOAST",
                    successToast: {
                        type: "EXECUTE_QA",
                        message: <FormattedMessage id="quickAction.toast.executionSuccess" defaultMessage="Quick action executed successfully"/>
                    }
                })
            }
            return resp;
        })
    }
}

export const getQuickActionModelById = (id) => {
    return (dispatch, getState, {rcsdk}) => {
        return rcsdk
        .getQuickAction(id)
        .then(quickActionModel => {
            return quickActionModel;
        }).catch(err => {
            console.log(err, "err");
        })
    }
}