import { v4 as uuid } from 'uuid';
import {
    ADD_NOTIFICATION,
    MARK_ALL_AS_READ,
    MARK_AS_READ,
    MARK_AS_PUSHED,
    TOGGLE_CENTER_MODAL,
    TOGGLE_FOOTER_MODAL
} from '../../actions/notifications';
import { NEW_NOTIFICATION } from '../../actions/pusher';


// import cta1 from "../../../components/reecall/contentBuilder/demos/jsons/CTAs/cta1.json"
// import cta4 from "../../../components/reecall/contentBuilder/demos/jsons/CTAs/cta4.json"
// import testimonial2 from "../../../components/reecall/contentBuilder/demos/jsons/testimonials/testimonial2.json"
// import testimonial1 from "../../../components/reecall/contentBuilder/demos/jsons/testimonials/testimonial1.json"

const initialState = {
    pending: false,

    notifications: [
        // {
        //     id: "notif1",
        //     title: "marketing center 1",
        //     data: cta1,
        //     isSeen : false,
        //     seenAt : null,
        //     createdAt : new Date(),
        //     notificationType: "marketing",
        //     notificationContainer: "center"
        // },
        // {
        //     id: "notif2",
        //     title: "marketing center 2",
        //     data: testimonial2,
        //     isSeen : false,
        //     seenAt : null,
        //     createdAt : new Date(),
        //     notificationType: "marketing",
        //     notificationContainer: "center"
        // },
        // {
        //     id: "notif3",
        //     title: "marketing center 3",
        //     data: cta4,
        //     isSeen : false,
        //     seenAt : null,
        //     createdAt : new Date(),
        //     notificationType: "marketing",
        //     notificationContainer: "center"
        // },
        // {
        //     id: "notif3",
        //     title: "marketing footer 1",
        //     data: testimonial1,
        //     isSeen : false,
        //     seenAt : null,
        //     createdAt : new Date(),
        //     notificationType: "marketing",
        //     notificationContainer: "footer",
        //     toPush: false
        // },
        // {
        //     id: "notif4",
        //     title: "marketing footer 2",
        //     data: testimonial2,
        //     isSeen : false,
        //     seenAt : null,
        //     createdAt : new Date(),
        //     notificationType: "marketing",
        //     notificationContainer: "footer",
        //     toPush: true
        // },
    ],
    error: null,
    wizzNotification: false,
    marketingCenterModalAlreadyDisplay: false,
    marketingCenterModalOpen: false,
    marketingFooterModalOpen: false,
}

const notificationReducer = (state = initialState, action) => {
    switch(action.type) {

        case TOGGLE_CENTER_MODAL:
            return {
                ...state,
                marketingCenterModalAlreadyDisplay: true,
                marketingCenterModalOpen: state.marketingCenterModalAlreadyDisplay ? false : !state.marketingCenterModalOpen
            }
    
        case TOGGLE_FOOTER_MODAL:
            return {
                ...state,
                marketingFooterModalOpen: !state.marketingFooterModalOpen
            }

        case ADD_NOTIFICATION: 
            return {
                ...state,
                notifications: [...state.notifications, action.notif]
            }

        case NEW_NOTIFICATION:
            if(action.data.type === "notification"){
                return {
                    ...state,
                    notifications: [...state.notifications, {
                        id: uuid(),
                        isSeen: false,
                        ...action.data.datas,
                        toPush: action.data.datas?.notificationContainer === "footer" ? true : false,
                    }],
                    wizzNotification: true
                }
            } else {
                return {...state}
            }

        case MARK_ALL_AS_READ:
            return {
                ...state,
                notifications: [...state.notifications.map(notif => {
                    return {
                        ...notif,
                        isSeen: true
                    }
                })],
                wizzNotification: false
            }

        case MARK_AS_READ:
            return {
                ...state,
                notifications: [...state.notifications.map(notif => 
                    notif.id === action.id
                        ? {
                            ...notif,
                            isSeen: true
                        }
                        : notif
                )]
            }
        
        case MARK_AS_PUSHED:
            return{
                ...state,
                notifications: [...state.notifications.map(notif => 
                    notif.id === action.id
                        ? {
                            ...notif,
                            toPush: false
                        }
                        : notif
                )]
            }
       
        default: 
            return state;
    }
}
export default notificationReducer;