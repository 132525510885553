import {TOGGLE_ASSISTANT, PUSH_INSIGHT, TOGGLE_INSIGHTS, REMOVE_INSIGHT} from '../../actions/assistant';
import {ASSISTANT_INSIGHT, NEW_NOTIFICATION, NEW_INTERCONNECTION, PUSHER_START_RINGING_CHAT, PUSHER_STOP_RINGING_CHAT} from '../../actions/pusher';
import { v4 as uuid } from 'uuid';

const initState = {
    show: false,
    showInsights: true,
    insights: [
    ],
    ringing: false
};

const assistantReducer = (state = initState, action) => {
  switch (action.type) {
    
    case TOGGLE_ASSISTANT:
        return {
            ...state,
            show: !state.show
        }

    case TOGGLE_INSIGHTS:
        return {
            ...state,
            showInsights: !state.showInsights
        }

    case PUSH_INSIGHT:
        return {
            ...state,
            insights: [
                ...state.insights,
                action.insights
            ]
        }
    
    case ASSISTANT_INSIGHT:
        return {
            ...state,
            show: true,
            insights: [
                ...state.insights,
                {
                    ...action.data,
                    id: uuid()
                }
            ]
        }

    case NEW_NOTIFICATION:
        if(action.data.type === "assistant"){
            return {
                ...state,
                show: true,
                insights: [...state.insights, 
                {
                    ...action.data.datas,
                    id: uuid()
                }]
            }
        } else {
            return {...state}
        }


    case PUSHER_START_RINGING_CHAT:
        return {
            ...state,
            show: true,
            insights: [...state.insights, 
            {
                id: uuid(),
                type: "new-chat",
                config: {
                    conversationId: action.data.call_sid,
                    channelId: action.data.channel_id,
                    from: action.data.from,
                    memberId: action.data.member_id
                }
            }],
            ringing: true
        }

    case PUSHER_STOP_RINGING_CHAT:
        return {
            ...state,
            show: false,
            insights: [],
            ringing: false
        }
    
    case NEW_INTERCONNECTION:
        if(action.data.from === action.additionalParams.loggedUserId) {
            return {...state}
        }
        return {
            ...state,
            show: true,
            insights: [...state.insights, 
            {
                id: uuid(),
                type: "new-internal-chat",
                config: {
                    ...action.data
                }
            }]
        }

    case REMOVE_INSIGHT:
        return {
            ...state,
            insights: [
                ...state.insights.filter(el => el.id !== action.insightId)
            ]
        }

    default: {
    	return state
    }
  }
}

export default assistantReducer;