// import { createBrowserHistory } from "history";

// const getUserConfirmation = (dialogKey, callback) => {
//     // use "message" as Symbol-based key
//     const dialogTrigger = window[Symbol.for(dialogKey)];
//     if (dialogTrigger) {
//       // pass the callback to delegate
//       // to the invoked dialog
//       return dialogTrigger(callback);
//     }
  
//     // Fallback to allowing navigation
//     callback(true);
// };

// export let history = createBrowserHistory({ basename: "", getUserConfirmation: getUserConfirmation });

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import CustomPrompt from "./components/reecall/modal/CustomPrompt"

const userConfirmation = (message, callback) => {
  const node = document.getElementById("custom-prompt");

  const cleanUp = (answer) => {
    ReactDOM.unmountComponentAtNode(node);
    callback(answer);
  };

  ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
}

export let history = createBrowserHistory({ basename: "", getUserConfirmation: userConfirmation });
