import {
    FETCH_TICKETS,
    FLUSH_TICKETS,

    FETCH_TICKET,

    UNSET_CURRENT_TICKET,
    ADD_TICKET,
    UPDATE_TICKET_TODO,
    TOGGLE_QUICK_ACTION,
    ADD_TODO,
    UPDATE_TICKET_STATUS,
    UPDATE_TICKET_PRIMARY_CONTACT,
    UPDATE_TICKET_TAGS,
    UPDATE_TICKET,
    ASSIGN_TICKET,
    ADD_NOTE,

    RELOAD_LIST,
    SET_NAVBAR_TICKET_TOTAL,
    INCREMENT_UNFETCHED_TICKET_TOTAL,

    HIDE_TICKET_DRAWER,
    SHOW_TICKET_DRAWER,

    SET_FILTERS,
    SET_SEARCH_TERM,

    SET_VIEW_TYPE

} from '../../actions/tickets';

import _ from "lodash";

const initialState = {
    newTicketTotal: 0,
    showDrawer: false,
    searchTerm: "",
    filters: [],
    total: 0,
    list: {
        tickets: [],
        status: "pending",
        error: null
    },
    table: {
        tickets: [],
        status: "pending",
        error: null,
    },
    pendingCurrent: "pending",
    current: null,
    totalNavBar: null,
    forceReload: false,
    viewType: "list"
}

const updateTicketInList = (ticketId, list, data) => {
    if(!list || list.filter(el => el).length === 0) return [];
    return list.map(ticket => {
        return ticketId === ticket.id ? { ...data } : { ...ticket }
    })
}

const ticketsReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_VIEW_TYPE:
            return {
                ...state,
                viewType: action.viewType,
                total: 0,
            }

        case FETCH_TICKET: 
            return {
                ...state,
                pendingCurrent: action.status,
                current: action.status === "success" ? action.ticket : null
            }


        case FETCH_TICKETS:
            return Object.assign(
                {
                    ...state,
                    total: action.status === "success" ? action.total : state.total,
                    forceReload: false
                },
                action.view === "list" ? {
                    list: {
                        ...state.list,
                        status: action.status,
                        error: action.status === "error" ? action.error : null,
                        tickets: action.status === "success" ? [...state.list.tickets, ...action.tickets] : [...state.list.tickets],
                    }
                } : null,
                action.view === "table" ? {
                    table: {
                        ...state.table,
                        status: action.status,
                        error: action.status === "error" ? action.error : null,
                        tickets: action.status === "success" ? [...action.tickets] : []
                    }
                } : null
            )

        case FLUSH_TICKETS:
            return Object.assign(
                {
                    ...state,
                },
                !action?.view ? {
                    list: {
                        ...state.list,
                        tickets: []
                    },
                    table: {
                        ...state.table,
                        tickets: []
                    }
                } : null,
                action.view === "list" ? {
                    list: {
                        ...state.list,
                        tickets: []
                    }
                } : null,
                action.view === "table" ? {
                    table: {
                        ...state.table,
                        tickets: []
                    }
                } : null,
            )

        case UNSET_CURRENT_TICKET:
            return {
                ...state,
                pendingCurrent: false,
                current: null
            }

        case ADD_TICKET:
            return { 
                ...state,
                list: {
                    ...state.list,
                    tickets: [
                        {
                            ..._.omit(action.ticket, ["isDelete", "updatedAt", "history", "lastHistoryFields", "viewBy", "assigneeId"])
                        }, 
                        ...state.list.tickets
                    ]
                },
                table: {
                    ...state.table,
                    tickets: [
                        {
                            ..._.omit(action.ticket, ["isDelete", "updatedAt", "history", "lastHistoryFields", "viewBy", "assigneeId"])
                        }, 
                        ...state.table.tickets
                    ]
                }
            }
        
        case UPDATE_TICKET_TODO:
            const updateTodo = (action, tickets) => {
                return tickets.map(ticket => {
                    return ticket.id === action.ticketId ? {
                        ...ticket,
                        todo: [
                            ...action.todos
                        ]
                    } : {...ticket}
                })
            }
            return {
                ...state,
                current: {
                    ...state.current,
                    todo: [ ...action.todos ]
                },
                list: {
                    ...state.list,
                    tickets: [...updateTodo(action, state.list.tickets)]
                },
                table: {
                    ...state.table,
                    tickets: [...updateTodo(action, state.table.tickets)]
                }
            }


        case TOGGLE_QUICK_ACTION:
            const toggleQA = (action, tickets) => {
                return tickets.map(ticket => {
                    return {
                        ...ticket,
                        todo: action.ticketId === ticket.id ? [
                            ...state.current.todo.map(todo => {
                                return {
                                    ...todo,
                                    quickActions: action.todoId === todo.id ? [ ...todo.quickActions.map(quickAction => {
                                        return {
                                            ...quickAction,
                                            done: action.quickActionLibraryId === quickAction.quickActionLibraryId ? !quickAction.done : quickAction.done
                                        }})
                                    ] : todo.quickActions
                                }
                            })
                        ] : ticket?.todo
                    }
                })
            }
            return {
                ...state,
                current: toggleQA(action, [state.current])[0],
                list: {
                    ...state.list,
                    tickets: [...toggleQA(action, state.list.tickets)]
                },
                table: {
                    ...state.table,
                    tickets: [...toggleQA(action, state.table.tickets)]
                }
            }
        
        case ADD_TODO:
            const addTodo = (action, tickets) => {
                return tickets.map(ticket => {
                    return {
                        ...ticket,
                        todo: action.ticketId === ticket.id ? [ ...ticket.todo, action.todo ] : ticket?.todo
                    }
                })
            }
            return {
                ...state,
                current: addTodo(action, [state.current])[0],
                list: {
                    ...state.list,
                    tickets: [...addTodo(action, state.list.tickets)]
                },
                table: {
                    ...state.table,
                    tickets: [...addTodo(action, state.table.tickets)]
                }
            }

        case UPDATE_TICKET_STATUS:
            return {
                ...state,
                current: state?.current ? {
                    ...state.current,
                    status: state.current.id === action.id ? action.status : state.current.status,
                    history: [
                        ...state.current.history,
                        {
                            type: "field_updated",
                            field: "status",
                            oldValue: state.current.status,
                            newValue: action.status,
                            author: {
                                ...action.author
                            },
                            timestamp: new Date(),
                        }
                    ],
                    timeline: [
                        ...state.current.timeline,
                        {
                            type: "field_updated",
                            field: "status",
                            oldValue: state.current.status,
                            newValue: action.status,
                            author: {
                                ...action.author
                            },
                            timestamp: new Date(),
                        }
                    ]
                } : null,
                list:{
                    ...state.list,
                    tickets: [
                        ...state.list.tickets.map(ticket => {
                            return { ...ticket, status: ticket.id === action.id ? action.status : ticket.status }
                        })
                    ]
                },
                table:{
                    ...state.table,
                    tickets: [
                        ...state.table.tickets.map(ticket => {
                            return { ...ticket, status: ticket.id === action.id ? action.status : ticket.status }
                        })
                    ]
                },
            }
    
        case UPDATE_TICKET_PRIMARY_CONTACT:
            return {
                ...state,
                current: {...action.ticket},
                list:{
                    ...state.list,
                    tickets: [...updateTicketInList(action.ticket.id, state.list.tickets, action.ticket)]
                },
                table:{
                    ...state.table,
                    tickets: [...updateTicketInList(action.ticket.id, state.table.tickets, action.ticket)]
                }
            }

    
        case UPDATE_TICKET_TAGS:
            const updateTicketTags = (action, tickets) => {
                return tickets.map(ticket => {
                    return {
                        ...ticket,
                        tagIds: ticket.id === action.id ? action.tagIds : ticket.tagIds
                    }
                })
            }
            return {
                ...state,
                current: updateTicketTags(action, [state.current])?.[0] || null,
                list:{
                    ...state.list,
                    tickets: [...updateTicketTags(action, state.list.tickets)]
                },
                table:{
                    ...state.table,
                    tickets: [...updateTicketTags(action, state.table.tickets)]
                }
            }

        case UPDATE_TICKET:
            return {
                ...state,
                current: updateTicketInList(action.id, [state.current], action.datas)?.[0] || null,
                list:{
                    ...state.list,
                    tickets: [...updateTicketInList(action.id, state.list.tickets, action.datas)]
                },
                table:{
                    ...state.table,
                    tickets: [...updateTicketInList(action.id, state.table.tickets, action.datas)]
                }
            }

        case ASSIGN_TICKET:
            return {
                ...state,
                current: {...action.ticket},
                list:{
                    ...state.list,
                    tickets: [...updateTicketInList(action.ticket.id, state.list.tickets, action.ticket)]
                },
                table:{
                    ...state.table,
                    tickets: [...updateTicketInList(action.ticket.id, state.table.tickets, action.ticket)]
                }
            }

        case ADD_NOTE:
            return {
                ...state,
                current: {
                    ...state.current,
                    notes: [
                        ...state.current.notes,
                        action.note
                    ],
                    timeline: [
                        ...state.current.timeline,
                        {
                            type: "note",
                            ...action.note
                        }
                    ]
                }
            }


        case SET_NAVBAR_TICKET_TOTAL:
            return {
                ...state,
                totalNavBar: action.total
            }

        case INCREMENT_UNFETCHED_TICKET_TOTAL:
            return {
                ...state,
                newTicketTotal: state.newTicketTotal + 1
            }

        case RELOAD_LIST:
            return {
                ...state,
                forceReload: true,
                newTicketTotal: 0
            }

        case HIDE_TICKET_DRAWER:
            return {
                ...state,
                showDrawer: false
            }

        case SHOW_TICKET_DRAWER:
            return {
                ...state,
                showDrawer: true
            }
        
        case SET_FILTERS: 
            return {
                ...state,
                filters: action?.filters
            }

        case SET_SEARCH_TERM: 
            return {
                ...state,
                searchTerm: action?.searchTerm
            }
    
        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/tickets\/\w+$/;
            if(action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)){
                return {
                    ...state,
                    forceReload: true,
                    current: null,
                    searchTerm: "",
                    newTicketTotal: 0
                }
            } else {
                return {
                    ...state,
                    forceReload: false
                }
            }

        default: 
            return state;
    }
}

export default ticketsReducer;