import React, {useCallback} from "react"
import {
  Badge,
  Button,
  Container,
  Navbar,
  Nav,
  NavItem,
  NavbarToggler
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { TOGGLE_MAIN_MENU } from "../../../redux/actions/menu/index"

import NavRight from "./NavRight"

const ThemeNavbar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.rcuser);
  const newConversationTotal = useSelector(state => state.conversations.newConversationTotal);
  const newTicketTotal = useSelector(state => state.tickets.newTicketTotal);

  const onLinkClick = useCallback(() => {
      dispatch({type: TOGGLE_MAIN_MENU, show: false})
  }, [dispatch]);
  
  return (
      <Navbar color="light">
        <Container fluid>
          <Nav className="me-3 align-items-center flex-row nav-icons" navbar>

            <NavbarToggler 
              onClick={() => {
                dispatch({type: TOGGLE_MAIN_MENU })
              }} 
              className="d-block d-lg-none"
            />

            <NavItem>
              <Button
                tag={NavLink}
                to="/"
                exact
                color="inherit-to-flat-primary"
                className="btn-icon"
                onClick={onLinkClick}
              >
                <FontAwesomeIcon icon={["far", "chart-pie-simple"]}/>
              </Button>
            </NavItem>

            <NavItem>
              <Button
                tag={NavLink}
                to="/conversations/all"
                color="inherit-to-flat-primary"
                className="btn-icon"
                isActive={(match, location) => {
                  return location.pathname.startsWith("/conversation")
                }}
                onClick={onLinkClick}
              >
                <FontAwesomeIcon icon={["far", "comment"]} className="mr-0"/>
                {
                  newConversationTotal > 0 && 
                  <Badge color="danger" pill className="badge-sm badge-pill rounded-pill bg-lavender">{newConversationTotal}</Badge>
                }
              </Button>
            </NavItem>

            <NavItem>
              <Button
                tag={NavLink}
                to="/tickets/new"
                color="inherit-to-flat-primary"
                className="btn-icon"
                isActive={(match, location) => {
                  return location.pathname.startsWith("/tickets")
                }}
                onClick={onLinkClick}
              >
                <FontAwesomeIcon icon={["far", "flag"]}/>
                {
                  newTicketTotal > 0 && 
                  <Badge color="danger" pill className="badge-sm badge-pill rounded-pill bg-lavender">{newTicketTotal}</Badge>
                }
              </Button>
            </NavItem>

            <NavItem>
              <Button
                tag={NavLink}
                to="/contacts"
                color="inherit-to-flat-primary"
                className="btn-icon"
                isActive={(match, location) => {
                  return location.pathname.startsWith("/contacts")
                }}
                onClick={onLinkClick}
              >
                <FontAwesomeIcon icon={["far", "users"]}/>
              </Button>
            </NavItem>

            {
              user?.rights?.ADMIN && <>
                <span className="divider nav-item" />

                <NavItem>
                  <Button
                    tag={NavLink}
                    to="/settings/channels"
                    color="inherit-to-flat-primary"
                    className="btn-icon"
                    isActive={(match, location) => {
                      return location.pathname.startsWith("/settings")
                    }}
                    onClick={onLinkClick}
                  >
                    <FontAwesomeIcon icon={["far", "cog"]}/>
                  </Button>
                </NavItem>
              </>
            }

          </Nav>
          <NavRight />
        </Container>
      </Navbar>
  )
}

export default ThemeNavbar
