import {
    FETCH_DASHBOARDS,
    ADD_DASHBOARD,
    UPDATE_DASHBOARD,
    DELETE_DASHBOARD
} from '../../actions/dashboards';

const initialState = {
    dashboards: [
        
    ],
    fetchStatus: "pending",
    addStatus: null,
    updateStatus: null,
    removeStatus: null,
    error: null
}

const dashboardReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_DASHBOARDS: 
            return {
                ...state,
                fetchStatus: action.status,
                dashboards: action.status === "success" ? action.dashboards : [],
                error: action.status === "error" ? action.error : null,
            }

        case ADD_DASHBOARD:
            return {
                ...state,
                addStatus: action.status,
                dashboards: action.status === "success" ? [...state.dashboards, action.dashboard] : state.dashboards
            }
        
        case UPDATE_DASHBOARD:
            return {
                ...state,
                updateStatus: action.status,
                dashboards: action.status === "success" ? [
                    ...state.dashboards.map(elt => {
                        if(elt.id === action.dashboard.id){
                            return {
                                ...elt,
                                ...action.dashboard
                            }
                        } else {
                            return elt
                        }
                    })
                ] : state.dashboards
            }

        case DELETE_DASHBOARD:
            return {
                ...state,
                removeStatus: action.status,
                dashboards: [...state.dashboards.filter(el => el.id !== action.id)]
            }


        default: 
            return state;
    }
}
export default dashboardReducer;