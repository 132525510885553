const HelpersPages = [
    {
        "page_slug": "faqs_form",
        "page_label": "FAQ form",
        "sections": [
            {
                "slug": "question",
                "label": "Field 'Question'",
            },
            {
                "slug": "answer",
                "label": "Field 'Answer'",
            },
            {
                "slug": "url",
                "label": "Field 'Url'",
            }
        ]
    },

    {
        "page_slug": "channel_form",
        "page_label": "Channel form",
        "sections": [
            //Global Fields
            {
                "slug": "name",
                "label": "Field 'Name'",
            },
            {
                "slug": "description",
                "label": "Field 'Description'",
            },
            {
                "slug": "timeBeforeAnswer",
                "label": "Fields 'timeBeforeAnswer'",
            },

            //ACD Fields
            {
                "slug": "acdMember",
                "label": "Fields 'membre ACD'",
            },
            {
                "slug": "agent",
                "label": "Field 'Agent'",
            },

            //Gdpr Fields
            {
                "slug": "gdprConsent",
                "label": "Toggle 'gdprConsent'",
            },
            {
                "slug": "gdprConsentWelcome",
                "label": "Field 'gdprConsent welcome sentence'",
            },
            {
                "slug": "gdprConsentAccept",
                "label": "Field 'gdprConsent accept sentence'",
            },
            {
                "slug": "gdprConsentDecline",
                "label": "Field 'gdprConsent decline sentence'",
            },
            {
                "slug": "textRecording",
                "label": "Fields 'textRecording'",
            },
            {
                "slug": "callRecording",
                "label": "Fields 'callRecording'",
            },
            {
                "slug": "callRecordingFrequency",
                "label": "Fields 'callRecordingFrequency'",
            },
            {
                "slug": "gdprSms",
                "label": "Toggle 'gdprSms'",
            },
            {
                "slug": "gdprSmsWelcome",
                "label": "Field 'gdprSms welcome sentence'",
            },
            {
                "slug": "gdprSmsAccept",
                "label": "Field 'gdprSms accept sentence'",
            },
            {
                "slug": "gdprSmsDecline",
                "label": "Field 'gdprSms decline sentence'",
            },
            {
                "slug": "gdprTracker",
                "label": "Toggle 'gdprTracker'",
            },

            //Token/phone fields
            {
                "slug": "phonevia-token",
                "label": "Field 'Public number (phone)'",
            },
            {
                "slug": "phone-token",
                "label": "Field 'Public number (phonevia)'",
            },
            {
                "slug": "chat-token",
                "label": "Field 'Chat Token'",
            },
            {
                "slug": "clicktocall-token",
                "label": "Field 'Clicktocall token'",
            },
            {
                "slug": "wildix-token",
                "label": "Field 'Wildix token'",
            },
            {
                "slug": "threecx-token",
                "label": "Field 'Threecx token'",
            },
            {
                "slug": "aircall-token",
                "label": "Field 'Aircall token'",
            },

            // channel data fields
            {
                "slug": "reecallPhone-data",
                "label": "Field 'Reecall’s phone via'",
            }
        ]
    },
    {
        "page_slug": "tag_families_list",
        "page_label": "Tag Families List",
        "sections": [{
                "slug": "administratif",
                "label": "Administratif"
            },
            {
                "slug": "commercial",
                "label": "Commercial"
            },
            {
                "slug": "adv",
                "label": "ADV"
            },
            {
                "slug": "sav",
                "label": "SAV"
            },
            {
                "slug": "support",
                "label": "Support"
            },
            {
                "slug": "polluant",
                "label": "Polluant"
            },
            {
                "slug": "rh",
                "label": "RH"
            },
            {
                "slug": "autre",
                "label": "Autre"
            }
        ]
    },
    {
        "page_slug": "tag_edit_form",
        "page_label": "Tag Edit Form",
        "sections": [
            {
                "slug": "tag_appearance",
                "label": "Tag appearance"
            },
            {
                "slug": "tag_category",
                "label": "Tag category"
            },
            {
                "slug": "tag_sentences",
                "label": "Tag sentences"
            },
            {
                "slug": "tag_model",
                "label": "Tag model"
            }
        ]
    },
    {
        "page_slug": "tag_add_form",
        "page_label": "Tag Add Form",
        "sections": [
            {
                "slug": "select_model_card",
                "label": "Card select model"
            },
            {
                "slug": "create_from_scratch_card",
                "label": "Card create from scratch"
            }
        ]
    },
    {
        "page_slug": "agent_form",
        "page_label": "Agent form",
        "sections": [
            {
                "slug": "name",
                "label": "Field 'Name'",
            },
            {
                "slug": "welcomeSentences",
                "label": "Field 'Welcome Sentences'",
            },
            {
                "slug": "conclusionSentences",
                "label": "Field 'Conclusion Sentences'",
            }
        ]
    },
    {
        "page_slug": "collected_datas_form",
        "page_label": "Collected datas form",
        "sections": [
        ]
    },
    {
        "page_slug": "preferences_settings",
        "page_label": "Preferences settings form",
        "sections": [
            {
                "slug": "ticketIsFirst",
                "label": "Field 'Ticket is first'",
            },
            {
                "slug": "showLiveMenu",
                "label": "Field 'showLiveMenu'",
            }
        ]
    }
]

const getSectionsByPageSlug = (pageSlug) =>  HelpersPages.find(helpPage => helpPage.page_slug === pageSlug).sections;
export {HelpersPages, getSectionsByPageSlug};