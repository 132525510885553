import React from 'react';
import { toast } from "react-toastify";
import ExportToastMessage from "../../../views/app/exports/ExportToastMessage";
import axios from 'axios';
import { EXPORT_URL } from '../../../configs/constants';

export const FETCH_COMPANY_EXPORTS_TEMPLATE = 'FETCH_COMPANY_EXPORTS_TEMPLATE';
export const FETCH_COMPANY_EXPORTS_LOGS = 'FETCH_COMPANY_EXPORTS_LOGS';
export const FLUSH_EXPORT_STATUS = 'FLUSH_EXPORT_STATUS';
export const CREATE_EXPORT_CONFIGURATION = 'CREATE_EXPORT_CONFIGURATION';
export const UPDATE_EXPORT_CONFIGURATION = 'UPDATE_EXPORT_CONFIGURATION';
export const FLUSH_EXPORTS_STATUS = 'FLUSH_EXPORTS_STATUS';
export const DELETE_EXPORT_CONFIGURATION = 'DELETE_EXPORT_CONFIGURATION';
export const EXECUTE_INSTANT_EXPORT = 'EXECUTE_INSTANT_EXPORT';

export function fetchExportConfigurations() {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANY_EXPORTS_TEMPLATE, status: "pending" })
        return (
            rcsdk
                .getExportConfigurations()
                .where({ companyId: getState().company.company.id })
                .then(response => {
                    dispatch(
                        {
                            type: FETCH_COMPANY_EXPORTS_TEMPLATE,
                            config: response,
                            status: "success",
                        }
                    )
                    return response
                })
                .catch(err => dispatch({ type: FETCH_COMPANY_EXPORTS_TEMPLATE, status: "error", error: err }))
        )
    }
};


export function createExportConfiguration(data) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: CREATE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .createExportConfiguration({ ...data, companyId: getState().company.company.id })
            .then(response => {
                dispatch(
                    {
                        type: CREATE_EXPORT_CONFIGURATION,
                        exportConfiguration: response,
                        status: "success",
                    }
                )
                toast.success(<ExportToastMessage action="creation" status="success" />)
                return response
            })
            .catch(err => {
                toast.error(<ExportToastMessage action="creation" status="error" />)
                dispatch({ type: CREATE_EXPORT_CONFIGURATION, status: "error", error: err })
            })
    }
};

export function updateExportConfiguration(id, data) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: UPDATE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .updateExportConfiguration(id, data)
            .then(response => {
                dispatch(
                    {
                        type: UPDATE_EXPORT_CONFIGURATION,
                        exportConfiguration: response,
                        status: "success",
                    }
                )
                toast.success(<ExportToastMessage action="update" status="success" />)
                return response
            })
            .catch(err => {
                toast.error(<ExportToastMessage action="update" status="error" />)
                dispatch({ type: UPDATE_EXPORT_CONFIGURATION, status: "error", error: err })
            })
    }
};

export function deleteExportConfiguration(id) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: DELETE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .deleteExportConfiguration(id)
            .then(response => {
                dispatch(
                    {
                        type: DELETE_EXPORT_CONFIGURATION,
                        id: id,
                        status: "success",
                    }
                )
                toast.success(<ExportToastMessage action="deletion" status="success" />)
                return response
            })
            .catch(err => {
                toast.error(<ExportToastMessage action="deletion" status="error" />)
                dispatch({ type: DELETE_EXPORT_CONFIGURATION, status: "error", error: err })
            })
    }
};

export function executeInstantExport(datas) {
    return (dispatch, getState, { rcsdk, rcRPA }) => {

        dispatch({ type: EXECUTE_INSTANT_EXPORT, status: "pending" })
        return axios.post(
            `${EXPORT_URL}/export/manual`,
            {
                ...datas,
                companyId: getState().company.company.id
            },
            {
                headers: {
                    "x-reecall-source": "APP",
                    "x-reecall-sourceId": getState().user.rcuser.rcId,
                    "x-reecall-company": getState().company.company.id
                }
            }
        )
        .then(resp => {
            console.log(resp, "executeInstantExport resp");
            dispatch({ type: EXECUTE_INSTANT_EXPORT, status: "success" })
            toast.success(<ExportToastMessage action="instantExport" status="success" />)
        })
        .catch(err => {
            toast.error(<ExportToastMessage action="instantExport" status="error" />)
            dispatch({ type: EXECUTE_INSTANT_EXPORT, status: "error", error: err  })
        })
    }
}
