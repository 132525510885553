export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const ASSISTANT_INSIGHT = 'ASSISTANT_INSIGHT';
export const NEW_INTERCONNECTION = 'NEW_INTERCONNECTION';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const TICKET_ASSIGN = 'TICKET_ASSIGN';
export const PUSHER_UPDATE_MEMBER = 'PUSHER_UPDATE_MEMBER';
export const MEMBER_ADDED = 'MEMBER_ADDED';


export const PUSHER_START_RINGING = 'PUSHER_START_RINGING';
export const PUSHER_STOP_RINGING = 'PUSHER_STOP_RINGING';


export const PUSHER_START_RINGING_CHAT = 'PUSHER_START_RINGING_CHAT';
export const PUSHER_STOP_RINGING_CHAT = 'PUSHER_STOP_RINGING_CHAT';


export const HOOK_TOAST = "HOOK_TOAST";


export const PUSHER_H2H = 'PUSHER_H2H';