import {
    SET_LIVES_TYPE,
    SET_INPUT_VALUE,
    SET_CURRENT_LIVE_ID,
    NEW_MESSAGE,
    USER_TYPING,
    RESET_TYPING,
    RESET_UNREAD,
    REMOVE_LIVE,
    ACCEPT_CHAT,
    DECLINE_CHAT
} from '../../actions/lives';
import {FETCH_USER_SUCCESS} from '../../actions/user';
import { subscribe } from 'pusher-redux';

import {NEW_INTERCONNECTION} from "../../actions/pusher"

const initialState = {
    pending: false,
    lives: [],
    error: null,
    type: null,
    currentLiveId: null
}

const livesReducer = (state = initialState, action) => {
    let privateChannelName;

    switch(action.type) {

        case ACCEPT_CHAT:
            privateChannelName = "private-" + action.conversationId;
            subscribe(privateChannelName, 'new-message', NEW_MESSAGE, {});
            return {
                ...state,
                lives: state.lives.find(el => el.id === action.conversationId) ?
                    [...state.lives]
                : 
                    [
                        ...state.lives,
                        {
                            id: action.conversationId,
                            name: "",
                            contact: {},
                            // contact: action?.memberId ? { id: action.memberId } : {},
                            inbounds: [],
                            typing: false,
                            typingDate: null,
                            unread: 0,
                            status: "online",
                            internal: false,
                            from: action.from,
                            channelId: action.channelId
                        }
                    ]
            }

        case DECLINE_CHAT:
            return {
                ...state,
                lives: [...state.lives.filter(el => el.id !== action.conversationId)]
            }

        case NEW_INTERCONNECTION:
            privateChannelName = "private-chat-" + action.data.conversationId;
            subscribe(privateChannelName, 'new-message', NEW_MESSAGE, {});
            subscribe(privateChannelName, 'client-typing', USER_TYPING, {});
            
            return {
                ...state,
                lives: state.lives.find(el => el.id === action.data.conversationId) ?
                [...state.lives]
                : 
                [
                    ...state.lives,
                    {
                        id: action.data.conversationId,
                        name: "",
                        contact: action.data?.user ? { ...action.data.user } : {},
                        inbounds: [],
                        typing: false,
                        typingDate: null,
                        unread: 0,
                        status: "online",
                        internal: true,
                        from: action.data.from
                    }
                ]
            }

        case USER_TYPING:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.data.conversationId === live.id ? {...live, typing: true, typingDate: new Date()} : {...live}
                })]
            }

        
        case RESET_TYPING:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.liveId === live.id ? {...live, typing: false, typingDate: null} : {...live}
                })]
            }

        case RESET_UNREAD:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.liveId === live.id ? {...live, unread: 0} : {...live}
                })]
            }

        case SET_LIVES_TYPE:
        	return {
                ...state,
                type: action.payload
            }
            
        case SET_INPUT_VALUE:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return {
                        ...live,
                        inputValue: action.liveId === live.id ? action.value : live?.inputValue
                    }
                })]
            }

        case NEW_MESSAGE:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return {
                        ...live,
                        inbounds: action.channel === `private-${live.id}` || action.channel === `private-chat-${live.id}` ? [
                            ...live.inbounds,
                            {
                                id: live.inbounds.length,
                                time: new Date(),
                                type: "message",
                                ...action.data
                            }
                        ] : live?.inbounds,
                        unread: action.channel === `private-${live.id}` || action.channel === `private-chat-${live.id}` ? live.unread + 1 : live.unread
                    }
                })]
            }

        case SET_CURRENT_LIVE_ID:
            return {
                ...state,
                currentLiveId: action.liveId
            }

        case REMOVE_LIVE:
            return {
                ...state,
                currentLiveId: null,
                lives: [...state.lives.filter(el => el.id !== action.liveId)]
            }


        case FETCH_USER_SUCCESS: {
            // Au fetch de l'utilisateur, on récupere les "lives" stockés dans le localStorage et on souscrit aux evenements pusher correspondants
            let localStorageLives = localStorage.getItem(`rcLivesState-${action.rcuser.rcId}`) ? JSON.parse(localStorage.getItem(`rcLivesState-${action.rcuser.rcId}`)) : [];
            localStorageLives.map(storedLive => {
                privateChannelName = "private-" + storedLive.id;
                subscribe(privateChannelName, 'new-message', NEW_MESSAGE, {});
                return null;
            })

            return { 
                ...state,
                lives: [
                    ...state.lives,
                    ...localStorageLives
                ]
            }
        }

        default: 
            return state;
    }
}
export default livesReducer;