import {
    FETCH_HELPTEXT,
} from '../../actions/helpTexts';

const initialState = {
    helpTexts: {}
}

const helpTextsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_HELPTEXT:
            return {
                ...state,
                helpTexts: {
                    ...state.helpTexts,
                    [action.pageSlug]: {
                        ...state.helpTexts?.[action.pageSlug],
                        [action.sectionSlug]: action.helpText
                    }
                }
            }
            
        default: 
            return state;
    }
}
export default helpTextsReducer;